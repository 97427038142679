import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Chat = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M653.356 668.546c130.664-157.044 110.8-389.817-46.244-522.032s-390.127-110.8-520.48 46.244c-104.282 125.387-117.318 308.812-23.278 441.027 34.761 49.658 31.347 92.488 13.346 140.594-6.517 16.449-13.346 31.347-23.278 51.21v0c56.176 13.346 115.766 0 163.562-32.898 13.346-8.38 28.244-9.932 42.83-6.517 57.727 16.449 117.318 19.863 176.908 9.932 84.419-16.759 162.010-61.452 216.634-127.56zM182.534 485.121v0c-29.795 0-54.624-24.829-54.624-54.624s24.829-54.624 54.624-54.624 54.624 24.829 54.624 54.624-25.14 54.624-54.624 54.624zM423.687 433.911c-1.552 29.795-26.381 52.762-56.176 51.21v0c-29.795-1.552-52.762-26.381-52.762-56.176 1.552-29.795 26.381-54.624 56.176-52.762 31.347 0 54.314 26.381 52.762 57.727 0-1.552 0 0 0 0zM613.629 428.945c0 29.795-23.278 54.624-52.762 56.176v0c-29.795 1.552-54.624-23.278-56.176-52.762 0-29.795 23.278-54.624 52.762-56.176 29.795 0 54.624 22.966 56.176 52.762v0z"></path>
      <path d="M783.708 286.798c37.864 122.283 31.347 238.049-28.244 350.401s-153.63 183.425-277.465 219.738v0c84.419 74.488 199.874 99.006 307.26 66.108 16.449-6.517 36.312-3.414 49.658 8.38 39.727 29.795 92.488 39.727 140.594 28.244-3.414-8.38-4.966-11.483-6.517-16.449-28.244-57.727-34.761-107.386 6.517-171.942 115.766-180.321 13.346-436.371-191.805-484.478z"></path>
    </svg>
  );
};
