import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Whatsapp2 = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M1026.695 509.32c0-284.012-230.425-509.078-509.078-509.078 0 0 0 0 0 0h-10.718c-5.359 0-10.718 0-10.718 0s-5.359 0-5.359 0c-128.609 5.359-246.501 58.946-337.599 150.044-160.761 160.761-198.272 407.262-85.74 605.535 5.359 10.718 5.359 26.793 5.359 37.511l-64.304 230.425c80.381-21.434 160.761-42.87 241.142-64.304 10.718-5.359 26.793 0 37.511 5.359 91.098 48.229 198.272 64.304 300.088 53.587 246.501-32.152 439.415-251.86 439.415-509.078zM560.486 964.811c-16.077 0-26.793 0-42.87 0-85.74 0-166.12-21.434-235.783-64.304-5.359 0-5.359-5.359-10.718 0-32.152 10.718-69.663 16.077-101.816 26.793l-37.511 10.718c-10.718 0-21.434 5.359-26.793-5.359-10.718-10.718-5.359-21.434-5.359-26.793l10.718-37.511c10.718-32.152 16.077-64.304 26.793-96.457 0-5.359 0-16.077 0-16.077-32.152-53.587-58.946-112.533-64.304-176.838-26.793-123.25 0-241.142 75.022-342.958 75.022-96.457 182.197-160.761 300.088-182.197 123.25-16.077 241.142 10.718 342.958 85.74 96.457 75.022 160.761 182.197 182.197 300.088 0 26.793 0 48.229 0 69.663 0 241.142-176.838 434.056-412.621 455.491z"></path>
      <path d="M260.398 386.070c0 16.077 0 26.793 5.359 37.511 10.718 42.87 32.152 80.381 58.946 112.533 69.663 107.174 171.479 187.556 294.729 219.708 32.152 10.718 64.304 5.359 91.098-5.359 32.152-10.718 58.946-42.87 58.946-80.381 0-5.359 0-10.718-5.359-10.718-10.718-5.359-16.077-10.718-26.793-10.718-5.359 0-10.718-5.359-16.077-5.359l-16.077-5.359c-16.077-10.718-32.152-16.077-53.587-26.793 0 0-5.359 0-5.359 0s0 0-5.359 5.359c-10.718 10.718-21.434 26.793-32.152 37.511-21.434 26.793-42.87 32.152-75.022 16.077-75.022-37.511-133.968-91.098-171.479-160.761-5.359-37.511-5.359-53.587 16.077-75.022l10.718-10.718c5.359-5.359 10.718-10.718 16.077-21.434 5.359-5.359 5.359-10.718 0-16.077-10.718-26.793-21.434-53.587-32.152-85.74l-5.359-16.077c0-5.359-5.359-10.718-10.718-10.718-5.359-5.359-10.718-5.359-21.434-5.359s-21.434 5.359-32.152 10.718c-32.152 26.793-48.229 64.304-42.87 107.174v0z"></path>
    </svg>
  );
};
