import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const DashboardIcon = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M511.126 120.054c239.197 0 433.072 193.036 433.072 431.393 0 239.197-193.036 433.072-431.393 433.072-237.518 0-430.554-190.518-433.072-428.037-1.678-239.197 191.357-434.75 431.393-436.429 0 0 0 0 0 0zM511.126 950.947c219.893 0 398.661-177.929 399.501-398.661 0-219.893-177.929-398.661-398.661-399.501-219.893 0-398.661 177.929-399.501 397.822 0.84 221.571 178.768 400.34 398.661 400.34z"></path>
      <path d="M511.965 39.482c245.911 0.84 457.411 176.25 502.733 418.804 5.036 29.375 7.553 59.589 9.232 89.804 0.84 14.268-5.875 21.822-16.786 21.822s-16.786-7.553-16.786-21.822c0-104.071-34.411-204.786-99.036-287.036-78.893-105.75-199.75-172.893-330.679-184.643-245.071-25.178-469.161 141-517.001 382.715-5.875 29.375-5.875 58.75-9.232 88.964 0 4.196-0.84 9.232-3.357 13.429-3.357 6.714-10.911 9.232-18.464 7.553s-12.589-9.232-12.589-16.786c0-35.25 3.357-69.66 10.911-104.071 40.286-194.715 190.518-348.304 383.554-394.464 16.786-4.196 33.571-6.714 51.196-9.232 21.822-2.518 44.482-3.357 66.304-5.036z"></path>
      <path d="M528.751 412.125c0 18.464 0 37.768 0 56.233-0.84 5.036 2.518 9.232 6.714 10.071 0 0 0 0 0 0 41.125 13.429 63.786 57.071 50.357 98.197s-57.071 63.786-98.197 50.357-63.786-57.071-50.357-98.197c7.553-23.5 26.018-42.804 50.357-50.357 5.036-0.84 8.393-5.036 7.553-10.071 0 0 0 0 0 0 0-36.089 0-72.178 0-108.268 0-2.518 0-4.196 0-6.714-0.84-9.232 6.714-17.625 15.947-17.625 0 0 0.84 0 0.84 0 9.232 0 16.786 6.714 16.786 15.947 0 0.84 0 0.84 0 1.678 0 15.107 0 30.214 0 44.482 0.84 5.036 0.84 10.071 0 14.268zM468.323 552.287c0 24.34 19.304 44.482 43.643 44.482s44.482-19.304 44.482-43.643-19.304-44.482-43.643-44.482c0 0 0 0 0 0-24.34 0-43.643 19.304-44.482 43.643z"></path>
      <path d="M825.858 569.912c-5.875 0-11.75 0-17.625 0-9.232 0.84-17.625-6.714-17.625-15.947 0 0 0-0.84 0-0.84 0-9.232 7.553-16.786 16.786-16.786 12.589 0 25.178 0 37.768 0 9.232-1.678 17.625 5.036 19.304 14.268s-5.036 17.625-14.268 19.304c-1.678 0-3.357 0-5.036 0-5.875-0.84-12.589-0.84-19.304 0z"></path>
      <path d="M197.233 569.912c-5.875 0-12.589 0-18.464 0-9.232 0-16.786-7.553-16.786-16.786s7.553-16.786 16.786-16.786c0 0 0 0 0 0 12.589 0 26.018 0 38.607 0 9.232 0 16.786 6.714 16.786 15.947 0 0 0 0.84 0 0.84 0 9.232-8.393 16.786-17.625 16.786-6.714 0-13.429 0-19.304 0z"></path>
      <path d="M660.519 315.608c-10.071 0-17.625-8.393-17.625-18.464 0-2.518 0.84-5.036 1.678-6.714 5.875-12.589 13.429-24.34 20.982-36.089 5.036-7.553 15.947-10.071 23.5-4.196 6.714 5.036 9.232 13.429 5.875 20.982-6.714 12.589-13.429 24.34-20.982 36.089-3.357 3.357-9.232 5.036-13.429 8.393z"></path>
      <path d="M381.037 296.304c0 7.553-4.196 13.429-10.911 15.947-6.714 3.357-14.268 0.84-18.464-4.196-8.393-12.589-15.947-26.018-22.66-39.447-3.357-8.393 1.678-18.464 10.071-20.982 6.714-2.518 13.429-0.84 17.625 4.196 8.393 12.589 15.107 26.018 22.66 39.447 1.678 1.678 1.678 3.357 1.678 5.036z"></path>
      <path d="M819.983 386.947c-3.357 5.036-6.714 9.232-10.911 13.429-10.071 6.714-20.982 12.589-31.053 18.464-7.553 5.875-18.464 4.196-24.34-3.357s-4.196-18.464 3.357-24.34c0.84-0.84 2.518-1.678 3.357-2.518 10.911-6.714 20.982-12.589 32.732-18.464 9.232-4.196 20.143-0.84 25.178 8.393 0.84 3.357 1.678 5.875 1.678 8.393z"></path>
      <path d="M819.144 718.465c0 10.071-8.393 17.625-18.464 17.625-2.518 0-5.036-0.84-7.553-1.678-12.589-5.875-24.34-13.429-36.089-20.982-7.553-5.036-10.071-15.107-4.196-23.5 5.036-6.714 13.429-9.232 20.982-5.875 12.589 6.714 24.34 13.429 36.089 20.982 5.036 3.357 6.714 9.232 9.232 13.429z"></path>
      <path d="M274.447 404.572c-0.84 10.071-9.232 17.625-18.464 16.786-1.678 0-4.196-0.84-5.875-0.84-12.589-6.714-25.178-13.429-36.929-20.982-6.714-5.036-9.232-14.268-5.036-21.822s13.429-10.911 20.982-7.553c12.589 6.714 25.178 14.268 37.768 21.822 3.357 2.518 5.036 8.393 7.553 12.589z"></path>
      <path d="M204.786 718.465c2.518-5.036 5.875-9.232 10.071-13.429 10.911-6.714 21.822-13.429 33.571-19.304 8.393-4.196 18.464-0.84 22.66 7.553 4.196 7.553 1.678 17.625-5.875 21.822-10.911 6.714-22.66 13.429-33.571 19.304-9.232 5.036-20.143 0.84-24.34-7.553-1.678-3.357-2.518-5.875-2.518-8.393z"></path>
      <path d="M528.751 238.393c0 6.714 0 12.589 0 19.304 0 9.232-6.714 15.947-15.947 15.947 0 0-0.84 0-0.84 0-8.393 0.84-16.786-5.875-16.786-15.107 0-0.84 0-0.84 0-1.678-0.84-13.429-0.84-26.857 0-40.286 0.84-9.232 8.393-15.947 17.625-15.947 8.393 0 15.107 6.714 15.947 15.947 0 7.553-0.84 15.107 0 21.822v0z"></path>
    </svg>
  );
};
