import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Close = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={className}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M512 0c-282.947 0-512 229.053-512 512s229.053 512 512 512c282.947 0 512-229.053 512-512s-229.053-512-512-512zM512 983.58c0 0-1.123 0-1.123 0-261.614-2.245-472.702-214.456-471.579-476.071 1.123-262.737 213.334-473.825 476.071-472.702 0 0 1.123 0 1.123 0 260.491 1.123 471.579 213.334 471.579 474.947-1.123 262.737-214.456 474.947-476.071 473.825z"></path>
      <path d="M596.211 512l141.474-141.474c22.457-22.457 22.457-59.509 0-81.965s-59.509-22.457-81.965 0l-142.596 141.474-141.474-141.474c-22.457-22.457-59.509-22.457-81.965 0s-22.457 59.509 0 81.965l141.474 141.474-141.474 142.596c-22.457 22.457-22.457 59.509 0 81.965 11.228 11.228 25.824 16.842 41.544 16.842 14.597 0 29.193-5.614 41.544-16.842l141.474-141.474 141.474 141.474c11.228 11.228 25.824 16.842 41.544 16.842 14.597 0 29.193-5.614 41.544-16.842 22.457-22.457 22.457-59.509 0-81.965l-142.596-142.596z"></path>
    </svg>
  );
};
