import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Download = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M475.428 707.048c0 0 6.096 6.096 6.096 6.096l6.096 6.096c0 0 6.096 0 6.096 0h6.096c6.096 0 6.096 0 12.19 0 0 0 6.096 0 6.096 0l18.286-6.096c0 0 6.096-6.096 6.096-6.096l262.096-262.096c18.286-18.286 24.381-48.762 6.096-67.048s-48.762-24.381-67.048-6.096c0 0 0 0-6.096 6.096l-182.857 182.857v-512c0-12.19-6.096-24.381-12.19-36.571-6.096-6.096-18.286-12.19-30.477-12.19s-24.381 0-36.571 12.19c-6.096 6.096-12.19 18.286-12.19 30.477v518.096l-182.857-182.857c-6.096-6.096-18.286-12.19-30.477-12.19 0 0 0 0 0 0-12.19 0-24.381 6.096-36.571 12.19-6.096 12.19-12.19 24.381-12.19 36.571s6.096 24.381 12.19 30.477l262.096 262.096zM530.285 694.857v0 0 0z"></path>
      <path d="M956.952 658.286c-24.381 0-48.762 18.286-48.762 48.762v225.524h-798.477v-225.524c0-24.381-18.286-48.762-48.762-48.762s-42.667 24.381-42.667 48.762v268.19c0 24.381 18.286 48.762 48.762 48.762h889.905c24.381 0 48.762-18.286 48.762-48.762v-268.19c-6.096-24.381-24.381-48.762-48.762-48.762z"></path>
    </svg>
  );
};
