import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Check = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M512 0c-281.6 0-512 225.28-512 512s230.4 512 512 512 512-230.4 512-512-225.28-512-512-512zM747.52 419.84l-302.080 302.080c0 5.12-10.24 10.24-20.48 10.24s-20.48-5.12-30.72-10.24l-128-128c-15.36-15.36-15.36-40.96 0-56.32s40.96-15.36 56.32 0l97.28 97.28 271.36-271.36c15.36-15.36 40.96-15.36 56.32 0s15.36 40.96 0 56.32z"></path>
    </svg>
  );
};
