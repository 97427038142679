import { Middleware, configureStore } from "@reduxjs/toolkit";
import reduxLogger from "redux-logger";
import rootReducer from "./rootReducer";
import { isClientSideRendered } from "../shared/helper";
import { ENVIRONMENTS } from "../shared/constant";
import { Config } from "../config";

const addLoggerMiddleware = (getDefaultMiddleware: typeof configureStore.prototype.middleware): Middleware[] => {
  // if (Config.environment === ENVIRONMENTS.LOCAL && isClientSideRendered()) {
  return getDefaultMiddleware().concat(reduxLogger);
  // }

  // return getDefaultMiddleware();
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: addLoggerMiddleware
});

// eslint-disable-next-line import/no-unused-modules
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
