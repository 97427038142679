import React, { ReactElement, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

interface Props {
  placeholder?: string;
  value: string;
  className?: string;
  floatingLabel?: boolean;
  required?: boolean;
  onChange?: (value?: string | undefined) => void;
  dataTestID?: string;
}

export default function InputPhone({
  placeholder = "",
  value = "",
  className,
  floatingLabel = false,
  required = false,
  onChange,
  dataTestID
}: Props): ReactElement {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const hasValue = value.trim() !== "";

  return (
    <div className={`input-phone-control form-control ${className} ${isFocused ? "focused" : ""} ${hasValue ? "active" : ""}`}>
      {floatingLabel && (
        <label className="ipc-label">
          {placeholder}
          {required && <span>*</span>}
        </label>
      )}
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        placeholder={floatingLabel ? "" : placeholder}
        value={value}
        className="ipc-input"
        defaultCountry="PK"
        onChange={onChange as (value?: string | undefined) => void}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-testid={dataTestID}
      />
    </div>
  );
}
