import { Container } from "react-bootstrap";
import React, { ReactElement } from "react";
// eslint-disable-next-line import/extensions
import animationData from "../../../../../lotties/data-website.json";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import Platinum from "../platinumPackage/index.tsx";
import { Accordion } from "react-bootstrap";
const faqData = [
  {
    question: "Can I get health insurance for my employees and their dependents?",
    answer:
      "Absolutely! The prices shown are based on the number of lives that you intend to get covered. This means that employees, their spouses and their children can all get covered."
  },
  {
    question: "Do my employees need a medical check-up to get health insurance?",
    answer: "No, medical checkup is not required before getting group health insurance from Takaful Bazaar."
  },
  {
    question: "Can I get multiple plans for my employees?",
    answer:
      "Takaful Bazaar’s plans are fully customizable. You can purchase multiple plans under the same policy. For example, if you have 40 employees you can have 10 employees be covered under Plan A, and 30 under Plan B."
  },
  {
    question: "Is the price shared on Takaful Bazaar’s website the final purchase price for group health insurance?",
    answer: "The price shown is an estimated quotation. Final price will be shared once you share your employees’ data with us."
  },
  {
    question: "Is maternity included in these plans?",
    answer:
      "The prices shown on our website do not include the price for maternity. However, if you require maternity Takaful Bazaar can get maternity benefit added to your plan at an additional cost. This cost depends on the number of individuals eligible for maternity."
  },
  {
    question: "Can my employees get treatment at any hospital?",
    answer:
      "Employees covered can get treated at any hospital. If they go to a panel hospital, they can get cashless treatment. For hospitals that are not on the panel list, your employees can submit a claim to the Takaful Provider later and get your expenses reimbursed. "
  },
  {
    question: "Will past medical illnesses such as Heart Disease or Hepatitis-C be covered?",
    answer:
      "We refer to past medical illnesses as ‘pre-existing conditions.’ Only a certain percentage of past medical illnesses are covered under health insurance. However, this percentage is dependent on the number of people you are buying your group health policy for. For example, if you have 6 individuals covered in your policy 15% of pre-existing medical conditions will be covered but if you have 51 individuals covered in your policy 35% of pre-existing conditions will be covered."
  },
  {
    question: "How can employees make a claim under their health insurance policy?",
    answer:
      "If the hospital your employees are visiting is on your Takaful Provider’s panel list, they can just submit their health card at the hospital desk and they will not have to pay out of their pocket. If it is not on the panel list, they can get the amount reimbursed later"
  }
];

export default function Faqs(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="landing-page-wrapper faq-pa">
      <section className="my-health-takaful-bnr pb-5">
        <Container className="text-center">
          <h1 className="mhtb-title">
            Frequently Asked <span className="text-primary">Questions</span>
          </h1>

          {/* Use mapping to dynamically create the accordion */}
          <Accordion
            defaultActiveKey="0"
            className="w-100 sehatZamin-accordion"
            id="basicAccordion"
          >
            {faqData.map((faq, index) => (
              <Accordion.Item
                eventKey={index.toString()}
                key={index}
              >
                <Accordion.Header className="candb-title">{faq.question}</Accordion.Header>
                <Accordion.Body className="text-start">
                  <strong className="fw-normal candb-caption">{faq.answer}</strong>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Container>
      </section>
    </div>
  );
}
