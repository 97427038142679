import React, { ReactElement } from "react";

export const Whatsapp = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M512.001 1021.538c-88.615 0-177.231 0-265.847 0-98.462 0-192-64-226.462-157.538-14.769-24.615-19.692-59.077-19.692-88.615 0-172.308 0-344.616 0-516.924 0-73.846 29.538-142.769 83.692-192 44.308-39.385 98.462-64 157.538-64 152.615 0 300.309 0 452.924 0 29.538 0 54.154 0 83.692 0 93.538 0 182.154 54.154 221.538 137.846 19.692 34.462 24.615 68.923 24.615 108.308 0 172.308 0 349.539 0 521.847 0 88.615-44.308 172.308-128 216.615-34.462 19.692-73.846 34.462-118.154 34.462-88.615 0-177.231 0-265.847 0v0zM512.001 36.921v0c-73.846 0-147.692 0-221.538 0-24.615 0-49.231 0-73.846 4.923-103.385 19.692-182.154 108.308-182.154 211.692 0 24.615 0 54.154 0 78.769 0 147.692 0 295.386 0 438.155 0 59.077 24.615 118.154 64 157.538 44.308 39.385 93.538 59.077 147.692 59.077s108.308 0 162.462 0c123.077 0 251.078 0 374.155 0 118.154 0 211.692-93.538 211.692-211.692 0-177.231 0-349.539 0-526.77 0-73.846-39.385-147.692-108.308-182.154-34.462-19.692-73.846-29.538-108.308-29.538-93.538 0-182.154 0-265.847 0v0z"></path>
      <path d="M177.231 849.23c4.923-14.769 4.923-19.692 9.846-29.538 9.846-44.308 24.615-83.692 34.462-128 4.923-9.846 0-19.692-4.923-29.538-49.231-93.538-49.231-201.846-9.846-295.386 64-128 187.077-206.769 324.924-201.846 152.615 4.923 280.616 108.308 320.001 251.078 29.538 103.385 4.923 216.615-64 300.309-39.385 54.154-98.462 93.538-162.462 113.231-83.692 24.615-172.308 19.692-251.078-19.692-9.846-4.923-19.692-4.923-29.538-4.923-49.231 14.769-98.462 24.615-147.692 39.385-4.923 0-9.846 0-19.692 4.923zM260.923 770.461c29.538-9.846 59.077-14.769 83.692-19.692 14.769-4.923 29.538-4.923 44.308 4.923 88.615 49.231 196.923 49.231 285.539-4.923 132.923-78.769 177.231-256.001 93.538-388.924-14.769-24.615-39.385-49.231-59.077-68.923-108.308-93.538-260.924-93.538-369.232-4.923s-132.923 246.155-59.077 364.309c4.923 9.846 4.923 14.769 4.923 24.615-4.923 24.615-14.769 44.308-19.692 68.923 0 4.923-4.923 14.769-4.923 24.615v0z"></path>
      <path d="M610.463 667.075c-9.846 0-19.692-4.923-29.538-4.923-64-14.769-118.154-49.231-157.538-98.462-19.692-24.615-34.462-44.308-54.154-68.923-9.846-19.692-19.692-39.385-19.692-64 0-29.538 9.846-54.154 29.538-73.846 14.769-9.846 29.538-14.769 44.308-9.846 4.923 0 9.846 4.923 9.846 9.846 9.846 24.615 19.692 49.231 29.538 68.923 0 4.923 0 9.846 0 14.769-4.923 9.846-9.846 19.692-19.692 24.615-4.923 9.846-9.846 14.769 0 24.615 24.615 44.308 64 78.769 113.231 98.462 9.846 4.923 14.769 0 19.692-4.923 9.846-9.846 14.769-19.692 24.615-29.538s9.846-9.846 19.692-4.923c19.692 9.846 44.308 19.692 64 29.538 4.923 4.923 9.846 9.846 9.846 14.769 0 19.692-9.846 44.308-29.538 54.154-14.769 14.769-34.462 19.692-54.154 19.692z"></path>
    </svg>
  );
};
