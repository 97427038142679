import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Renewal = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M1.035 476.556c2.589-18.122 4.66-36.244 7.766-54.366 19.675-114.428 78.702-218.502 166.724-294.615 74.042-66.793 167.242-109.251 266.138-122.196 138.764-20.193 280.117 18.64 388.85 107.18 22.782 17.605 42.975 38.834 64.723 58.509 0.518-2.071 0.518-4.143 0.518-6.213 0-18.64 0-37.28 0-55.92-1.036-11.391 7.248-21.746 18.64-22.782 0.518 0 1.036 0 1.036 0 11.391-1.036 21.229 6.731 22.264 18.122 0 1.036 0 2.071 0 3.107 0.518 42.457 0.518 85.433 0 127.891 0.518 11.391-8.284 20.711-19.157 21.229-1.036 0-2.071 0-3.107 0-41.423 0-83.362 0-124.785 0-11.391 1.036-21.746-7.766-22.782-19.157 0 0 0-0.518 0-0.518-1.036-11.391 7.248-21.229 18.64-22.264 1.036 0 2.071 0 3.107 0 23.3-0.518 46.6 0 69.9-0.518h13.462c-5.696-6.213-9.32-10.873-12.945-15.016-73.007-82.326-173.973-135.658-283.224-150.155-218.502-32.102-429.237 94.235-503.797 301.864-62.651 159.994-29.514 341.215 85.951 468.588 71.453 85.433 171.902 140.835 281.67 156.369 239.213 36.762 467.035-113.91 526.062-348.464 13.98-56.437 17.605-115.464 10.873-173.455-3.107-11.391 4.143-23.3 15.534-26.407s23.3 4.143 26.407 15.534c0.518 2.071 0.518 3.625 0.518 5.696 32.102 257.335-133.069 497.583-384.709 560.235-25.889 6.213-52.814 8.802-79.737 12.945-2.589 0.518-5.178 1.036-7.766 2.071h-71.971c-3.107-0.518-5.696-1.553-8.802-2.071-21.229-3.107-42.457-5.178-63.169-9.32-202.969-44.011-359.337-206.076-396.1-410.080-3.107-18.122-5.178-36.244-7.766-53.848l1.036-71.971z"></path>
      <path d="M256.817 455.845c0-31.584 0-63.169 0-94.753-2.071-11.909 5.696-23.3 17.605-25.371 76.631-25.889 152.744-51.778 229.375-77.149 5.696-1.553 11.909-1.553 17.087 0 76.631 25.371 153.78 51.26 230.411 77.149 11.391 2.589 19.157 13.462 17.087 24.853 0 56.437-0.518 112.358 0 168.795 1.036 68.346-22.782 134.622-66.793 186.399-47.635 57.473-108.215 103.555-176.562 134.104-6.731 3.107-14.498 3.625-21.746 1.036-78.702-33.655-146.531-88.54-196.755-157.922-33.138-47.118-50.742-103.555-50.225-161.028 1.036-25.371 0.518-50.742 0.518-76.114zM724.887 468.272h0.518c0-28.478-0.518-56.437 0-84.916 1.036-7.248-3.625-13.462-10.873-14.498 0 0 0 0 0 0-64.723-21.229-128.926-43.493-193.649-64.723-5.696-1.553-11.391-2.071-17.087 0-65.757 21.746-130.998 44.011-196.238 65.757-5.696 1.036-9.32 6.213-8.802 11.909 0.518 49.707 0.518 99.931 0 149.637-0.518 47.118 13.462 92.682 39.351 132.033 42.975 61.616 101.484 111.322 169.831 143.424 3.625 1.036 7.248 0.518 10.873-1.036 52.814-25.889 100.449-62.133 139.8-106.144 39.869-41.939 63.169-96.824 65.757-154.815 1.036-25.889 0.518-51.26 0.518-76.631v0z"></path>
      <path d="M493.44 587.361c9.32-9.838 18.64-19.675 27.442-28.478 33.655-34.173 67.828-67.828 101.484-101.484 7.248-9.838 21.229-11.391 30.548-4.143 0.518 0.518 1.036 1.036 2.071 1.553 8.284 8.284 8.802 21.746 0.518 30.031-1.036 1.036-1.553 1.553-2.589 2.589-48.153 48.153-96.307 96.307-144.46 144.46-7.248 9.838-20.711 11.909-30.548 4.66-2.589-2.071-4.66-4.143-6.213-6.731-27.442-33.655-53.848-67.311-81.291-101.484-5.696-6.213-7.766-15.016-5.178-23.3 3.625-10.873 16.051-17.087 26.925-13.462 4.143 1.553 8.284 4.143 10.873 8.284 21.229 25.889 41.939 51.778 62.133 77.666 3.107 3.625 5.178 6.213 8.284 9.838z"></path>
    </svg>
  );
};
