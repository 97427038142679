import React, { ReactElement } from "react";

export const Instagram = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20.8 20.8"
    >
      <path
        d="M10.4,20.7c-1.7,0-3.5,0-5.2,0c-2,0.1-3.9-1.1-4.7-3C0.2,17.1,0,16.4,0,15.7c0-2.5,0-4.9,0-7.4
	c0-1,0-2,0-3.1c0-1.3,0.4-2.6,1.3-3.5C2,1,2.8,0.5,3.7,0.2c0.5-0.1,1-0.2,1.5-0.2c3.5,0,6.9,0,10.4,0c1.3,0,2.6,0.4,3.5,1.3
	C20,2.1,20.5,3,20.7,4.1c0.1,0.4,0.1,0.9,0.1,1.3c0,3.4,0,6.8,0,10.3c0,1.4-0.5,2.7-1.5,3.7c-1,1-2.3,1.5-3.6,1.5
	C14,20.7,12.2,20.7,10.4,20.7L10.4,20.7z M20.1,10.4L20.1,10.4c0-0.9,0-1.8,0-2.7c0-0.9,0-1.8,0-2.7c0-0.2,0-0.4-0.1-0.6
	c-0.3-2.1-2.1-3.6-4.2-3.6c-1.1,0-2.2,0-3.4,0c-2.5,0-4.9,0-7.4,0C4.2,0.8,3.4,1,2.7,1.5c-1.2,0.8-2,2.1-1.9,3.6c0,1.5,0,2.9,0,4.4
	c0,2,0,4.1,0,6.1C0.7,18,2.6,19.9,4.9,20c0,0,0.1,0,0.1,0c0.5,0,0.9,0,1.4,0c3.1,0,6.2,0,9.4,0c1.1,0,2.2-0.5,3-1.3
	c0.8-0.8,1.3-1.9,1.2-3C20.1,13.9,20.1,12.2,20.1,10.4z"
      />
      <path
        d="M10.4,17.6c-1,0-2.1,0-3.1-0.1c-0.4,0-0.9-0.1-1.3-0.2c-1.3-0.4-2.3-1.5-2.5-2.9c-0.1-0.6-0.2-1.2-0.2-1.8
	c0-1.7,0-3.4,0-5.1c0-0.7,0.2-1.5,0.5-2.1c0.6-1.1,1.8-1.8,3-1.9c0.9-0.1,1.8-0.1,2.7-0.1c1.3,0,2.5,0,3.8,0c0.7,0,1.3,0.1,1.9,0.4
	c1.2,0.5,2.1,1.7,2.2,3.1c0.1,0.9,0.1,1.8,0.1,2.8c0,1.2,0,2.5,0,3.8c0,0.6-0.1,1.2-0.3,1.8c-0.4,1.1-1.4,1.9-2.6,2.2
	c-0.8,0.2-1.7,0.2-2.5,0.2C11.6,17.6,11,17.6,10.4,17.6L10.4,17.6z M16.3,10.5L16.3,10.5c0-0.6,0-1.2,0-1.8c0-0.6,0-1.3-0.1-1.9
	c-0.1-1-0.8-1.8-1.8-2.1c-0.6-0.1-1.2-0.2-1.8-0.2c-1.5,0-2.9,0-4.4,0c-0.5,0-1,0-1.5,0.1c-1.1,0.1-1.9,1-2.1,2.1
	c-0.1,0.5-0.1,1-0.1,1.5c0,1.4,0,2.8,0,4.2c0,0.5,0,1,0.1,1.5c0.1,1.1,1,2,2.1,2.1c0.5,0.1,1.1,0.1,1.6,0.1c1.4,0,2.7,0,4.1,0
	c0.5,0,1,0,1.5-0.1c0.7-0.1,1.3-0.4,1.7-1c0.3-0.4,0.5-1,0.5-1.5C16.3,12.6,16.3,11.5,16.3,10.5L16.3,10.5z"
      />
      <path
        d="M10.4,14.1c-2,0-3.7-1.6-3.7-3.7c0,0,0,0,0,0c0-2,1.7-3.7,3.7-3.7c0,0,0,0,0,0c2,0,3.7,1.6,3.7,3.7
	C14.1,12.4,12.5,14.1,10.4,14.1z M8.1,10.4c0,1.3,1.1,2.4,2.4,2.4h0c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c0,0,0,0,0,0
	C9.1,8,8.1,9.1,8.1,10.4C8.1,10.4,8.1,10.4,8.1,10.4L8.1,10.4z"
      />
      <path
        d="M15.1,6.6c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.8-0.4-0.8-0.9c0-0.5,0.4-0.9,0.8-0.9c0,0,0,0,0,0
	C14.7,5.8,15.1,6.1,15.1,6.6C15.1,6.6,15.1,6.6,15.1,6.6z"
      />
    </svg>
  );
};
