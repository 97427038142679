import React, { lazy } from "react";

const PersonalAccident = lazy(() => import("../../modules/personalAccident/container/home"));
const PersonalAccidentPlans = lazy(() => import("../../modules/personalAccident/container/planListing"));

const PersonalAccidentPlansDetail = lazy(() => import("../../modules/personalAccident/container/planDetails"));
const PersonalAccidentPlansCompare = lazy(() => import("../../modules/personalAccident/container/compare"));

const PersonalAccidentOrderDetailsDocumentUpload = lazy(() => import("../../modules/personalAccident/container/orderDetails/document-upload"));
const PersonalAccidentOrderDetailsPaymentDetails = lazy(() => import("../../modules/personalAccident/container/orderDetails/payment-details"));
const PersonalAccidentOrderDetailsPersonalDetails = lazy(() => import("../../modules/personalAccident/container/orderDetails/personal-details"));
const PersonalAccidentOrderDetailsReviewDetails = lazy(() => import("../../modules/personalAccident/container/orderDetails/review-details"));

export const personalAccidentRoutes = [
  {
    path: "/personal-accident",
    element: <PersonalAccident />
  },
  {
    path: "personal-accident/plans/:quotationID",
    element: <PersonalAccidentPlans />
  },
  {
    path: "/personal-accident/plans/:quotationID/detail/:id",
    element: <PersonalAccidentPlansDetail />
  },
  {
    path: "/personal-accident/plans/:quotationID/compare/:id",
    element: <PersonalAccidentPlansCompare />
  },
  {
    path: "/personal-accident/order-details/:quotationID/:cartID/document-upload",
    element: <PersonalAccidentOrderDetailsDocumentUpload />
  },
  {
    path: "/personal-accident/order-details/:quotationID/:cartID/payment-details",
    element: <PersonalAccidentOrderDetailsPaymentDetails />
  },
  {
    path: "/personal-accident/order-details/:quotationID/:cartID/personal-details",
    element: <PersonalAccidentOrderDetailsPersonalDetails />
  },
  {
    path: "/personal-accident/order-details/:quotationID/:cartID/review-details",
    element: <PersonalAccidentOrderDetailsReviewDetails />
  }
];
