import { IOTP, ISignup, ILogin } from "../utils/interface";
import Api from "../../../shared/network/axiosClients";
import { AxiosResponse } from "axios";

export const getUserInfo = async (): Promise<AxiosResponse> => {
  return Api.get("users/me");
};

export const getLoginOtp = async (data: IOTP): Promise<AxiosResponse> => {
  return Api.post("users/web/login/otp", data);
};
export const getSignupOtp = async (data: IOTP): Promise<AxiosResponse> => {
  return Api.post("users/web/sign-up/otp", data);
};
export const login = async (data: ILogin): Promise<AxiosResponse> => {
  return Api.post("users/web/login", data);
};
export const signUp = async (data: ISignup): Promise<AxiosResponse> => {
  return Api.post("users/web/sign-up", data);
};
