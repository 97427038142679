import Api from "../../../network/axiosClients";
import { AxiosResponse } from "axios";

interface ICreateCartBody {
  quotationId: string | undefined;
  currencyCode: string;
  rateModeType: string | undefined;
  cartPlans: {
    planId: number;
    cartPlanServices: {
      planServiceId: number;
    }[];
  }[];
}

interface IRemoveCartBody {
  planIds: number[];
  cartId: string;
}

export const createCart = (body: ICreateCartBody): Promise<AxiosResponse> => {
  return Api.post("cart", {
    ...body,
    lineOfBusiness: "houseHold"
  });
};
export const removeCart = (body: IRemoveCartBody): Promise<AxiosResponse> => {
  // In order to pass body in DELETE method, we have to pass it in "data" key, direct body no supported in axios
  return Api.delete("cart", { data: body });
};
export const getCartPlansByID = (cartID: string): Promise<AxiosResponse> => {
  return Api.get(`cart/${cartID}/houseHold`);
};
