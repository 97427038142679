/* eslint-disable import/no-unused-modules */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface CounterState {
  value: number;
}

const initialState: CounterState = { value: 0 };

// eslint-disable-next-line import/no-unused-modules
export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: state => {
      state.value += 1;
    },
    decrement: state => {
      state.value -= 1;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    // eslint-disable-next-line no-param-reassign, no-unused-vars, @typescript-eslint/no-unused-vars
    ResetCounter: state => (state = initialState)
  }
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, ResetCounter } = counterSlice.actions;

export default counterSlice.reducer;
