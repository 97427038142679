import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const InfoCircle = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M512 1024c-282.086 0-511.64-230.274-511.64-512.36s229.554-511.64 511.64-511.64 511.64 229.554 511.64 511.64-229.554 512.36-511.64 512.36zM512 64.045c-246.825 0-447.595 200.77-447.595 447.595s200.77 447.595 447.595 447.595c246.825 0 447.595-200.77 447.595-447.595 0.72-246.825-200.77-447.595-447.595-447.595z"></path>
      <path d="M509.122 788.689c-17.99 0-35.261-7.196-47.494-20.149-12.953-12.953-20.149-30.943-20.149-48.933-1.439-18.71 6.476-37.42 20.869-49.653 13.673-11.514 30.943-17.99 48.933-17.271 17.99 0 35.98 6.476 49.653 17.99 14.392 12.233 22.308 30.943 21.588 50.372 0 17.99-7.916 35.98-22.308 47.494-13.673 12.953-31.663 20.149-50.372 20.149h-0.72zM514.159 605.909h-2.878c-12.233 1.439-24.467-2.159-33.822-10.794-5.757-8.635-9.355-19.429-9.355-30.943-5.037-96.427-10.794-180.621-18.71-251.143-1.439-10.794-2.159-22.308-2.159-33.102-2.159-13.673 4.318-28.065 15.831-35.98 15.831-6.476 33.102-10.074 51.092-9.355 17.99-1.439 35.98 2.159 51.812 10.794 7.916 1.439 11.514 12.233 11.514 33.102 0 7.916 0 15.112-1.439 23.027-7.196 117.296-14.392 205.088-20.149 262.656 0 11.514-3.598 23.747-9.355 33.102-9.355 7.196-20.869 10.074-32.382 8.635v0z"></path>
    </svg>
  );
};
