import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Email2 = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M962.573 901.129h-901.146c-33.909-0.040-61.387-27.518-61.427-61.423v-655.408c0.040-33.909 27.518-61.387 61.423-61.427h901.15c33.909 0.040 61.387 27.518 61.427 61.423v655.408c-0.040 33.909-27.518 61.387-61.423 61.427h-0.004zM61.427 163.834c-11.306 0.020-20.464 9.19-20.464 20.498 0 0 0 0 0 0v0 655.404c0.020 11.294 9.17 20.444 20.462 20.464h901.148c0 0 0 0 0 0 11.309 0 20.479-9.157 20.498-20.462v-655.406c0-11.321-9.178-20.498-20.498-20.498v0z"></path>
      <path d="M511.948 570.543c-0.172 0.001-0.375 0.001-0.578 0.001-62.033 0-118.173-25.236-158.711-66.003l-264.792-264.792c-3.704-3.704-5.994-8.819-5.994-14.471 0-11.302 9.162-20.465 20.465-20.465 5.651 0 10.767 2.291 14.471 5.994l264.782 264.782c33.331 33.372 79.399 54.015 130.289 54.015s96.958-20.644 130.287-54.013l254.569-254.569c3.705-3.701 8.821-5.989 14.471-5.989 11.309 0 20.477 9.168 20.477 20.477 0 5.659-2.295 10.782-6.006 14.488v0l-254.567 254.567c-40.539 40.756-96.661 65.979-158.672 65.979-0.172 0-0.344 0-0.515-0.001h0.027z"></path>
      <path d="M102.425 819.204c-0.011 0-0.024 0-0.036 0-11.302 0-20.464-9.162-20.464-20.464 0-5.66 2.298-10.783 6.011-14.487l225.278-225.278c3.704-3.704 8.819-5.994 14.471-5.994 11.302 0 20.465 9.162 20.465 20.465 0 5.651-2.291 10.767-5.994 14.471l-225.278 225.278c-3.695 3.709-8.806 6.005-14.452 6.011h-0.001z"></path>
      <path d="M921.75 819.204c-0.009 0-0.019 0-0.030 0-5.65 0-10.764-2.298-14.458-6.010l-225.278-225.278c-3.701-3.705-5.989-8.821-5.989-14.471 0-11.309 9.168-20.477 20.477-20.477 5.659 0 10.782 2.295 14.488 6.006v0l225.278 225.278c3.714 3.705 6.012 8.828 6.012 14.488 0 11.302-9.162 20.464-20.464 20.464-0.013 0-0.025 0-0.038 0h0.002z"></path>
    </svg>
  );
};
