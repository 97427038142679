import { ArrowRight } from "../../icons";
import { useTranslation } from "react-i18next";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
interface IProps {
  remainingPlans: string;
  cartPayable: string;
  shouldProceedToCheckout: boolean;
  onCheckout: Function;
  onClickViewPlan: Function;
}
const CartFooterLife = ({ remainingPlans, cartPayable, shouldProceedToCheckout, onCheckout, onClickViewPlan }: IProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      {!shouldProceedToCheckout && remainingPlans && (
        <div className="cmw-foot">
          <div>
            <div className="fs-12px fw-semibold">{t("health_plan_cart_next_step")}</div>
            <div>{`${t("health_plan_cart_select_plan_for")} ${remainingPlans}`}</div>
          </div>
          <a
            className="ms-auto d-flex align-items-center text-decoration-none"
            onClick={() => onCheckout()}
          >
            {t("health_plan_cart_skip")}
            <ArrowRight className="fs-12px ms-2" />
          </a>
        </div>
      )}
      <div className="cmw-foot">
        <div>
          <div className="fs-12px fw-semibold">{t("health_plan_cart_currently_payable")}</div>
          <div>{cartPayable}</div>
        </div>
        {shouldProceedToCheckout || !remainingPlans ? (
          <Button
            size="lg"
            className="ms-auto"
            onClick={() => onCheckout()}
          >
            {t("health_plan_cart_proceed_to_checkout")}
          </Button>
        ) : (
          <Button
            size="lg"
            className="ms-auto"
            onClick={() => onClickViewPlan()}
          >
            {t("health_plan_cart_view_plans")}
          </Button>
        )}
      </div>
    </>
  );
};

export default CartFooterLife;
