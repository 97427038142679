"use client";

import { updateCart } from "../../helpers/cart";
import { getAuthenticatedUser } from "../../../../redux/features/auth";
import { isUserCustomer } from "../../../../shared/helper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface IProps {
  quotationID: string;
  asPath: string;
}
export const useUpdateCart = ({ quotationID, asPath }: IProps): void => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authenticatedUser } = useSelector(getAuthenticatedUser) ?? {};
  const userType = authenticatedUser?.userType;
  const isCustomer = isUserCustomer(userType?.key);

  useEffect(() => {
    if (quotationID && asPath) updateCart({ quotationID, isCustomer, dispatch, navigate });
  }, []);
};
