export const DEBOUNCE_DELAY_IN_MS = 500;
export const DEBOUNCE_LONG_DELAY_IN_MS = 800;
export const DEBOUNCE_SHORT_DELAY_IN_MS = 300;

export const baseUrlV1 = "https://takafulbazaar.pk";
export const Status = {
  CREATED: 201,
  SUCCESS: 200,
  NOTACCEPTABLEEXCEPTION: 406
};

export const RateModeTypes = {
  ANNUAL: "annual",
  MONTHLY: "monthly"
};

export const ENVIRONMENTS = {
  LOCAL: "local",
  PRODUCTION: "production"
};

export const LineOfBusiness = {
  HEALTH: "health",
  AUTO: "auto",
  PERSONALACCIDENT: "personalAccident",
  HOUSEHOLD: "houseHold"
};

export const Members = {
  SELF: "self",
  SPOUSE: "spouse",
  FATHER: "father",
  MOTHER: "mother",
  CHILD: "child"
};

export const PlanTypes = {
  FAMILY: "family",
  SELF: "self",
  PARENT: "parents"
};

export const CurrenyCode = {
  PKR: "PKR"
};

export const NumberToStringCounts = {
  1: "First",
  2: "Second",
  3: "Third",
  4: "Fourth"
};
export const yesNoConstants = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" }
];

export const UserTypes = {
  CUSTOMER: "customer"
};

export const Routes = {
  LANDING_PAGE: "health",
  HEALTH_PLAN_LISTING_PAGE: "plans",
  PERSONAL_ACCIDENT_PAGE: "personal-accident"
};

export const Environment = {
  Local: "local"
};

export const SocialUrls = {
  FACEBOOK: "https://www.facebook.com/takafulbazaarpk/",
  INSTAGRAM: "https://www.instagram.com/takafulbazaar.pk/?igshid=NTdlMDg3MTY%3D",
  LINKEDIN: "https://www.linkedin.com/company/takafulbazaar/",
  TWITTER: "https://twitter.com/takafulbazaar?s=11&t=h5V5Z51PfkvUAi06kZ39Ag",
  WHATSAPP: "https://wa.me/+923272055033",
  YOUTUBE: "https://www.youtube.com/channel/UChgsZk_nvaP2AGcB0n79QUg"
};

export const StatusCode = {
  NOT_FOUND: 404,
  CREATED: 201,
  SUCCESS: 200,
  UNAUTHORIZED: 401
};

export const ErrorMessages = {
  QUOTATION_NOT_FOUND: "Quotation not found"
};

export const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const LineOfBusinessEnum = {
  HEALTH: "Health",
  AUTO: "Auto",
  FAMILY: "Family"
};
