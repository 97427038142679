import React, { ReactElement } from "react";

export const Youtube = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M514.499 1019.078c-88.615 0-177.231 0-265.846 0-103.385 0-192.001-64-231.384-157.539-9.846-29.538-14.77-59.077-14.77-88.615 0-177.231 0-349.539 0-526.77 0-113.231 78.77-216.616 192.001-236.308 19.692-4.923 39.385-9.846 59.077-9.846 177.231 0 354.462 0 531.693 0 73.846 0 142.769 34.462 187.077 93.538 34.462 44.308 54.153 103.385 54.153 162.461 0 172.308 0 344.615 0 516.923 0 103.385-64 201.846-162.461 236.308-29.538 9.846-59.077 14.77-93.538 14.77-83.693-4.923-172.308-4.923-256-4.923v0zM992.038 507.078v0c0-14.77 0-29.538 0-44.308 0-68.923 0-142.769 0-211.693 0-49.231-14.77-98.461-49.231-137.846-39.385-49.231-98.461-78.77-162.461-73.846-39.385 0-78.77 0-118.154 0h-403.693c-39.385 0-78.77 9.846-113.231 29.538-68.923 34.462-108.308 103.385-108.308 182.154 0 49.231 0 98.461 0 152.616 0 123.077 0 246.154 0 374.154 0 68.923 34.462 132.923 88.615 172.308 44.308 19.692 88.615 34.462 132.923 34.462 172.308 0 349.539 0 521.846 0 49.231 0 98.461-14.77 132.923-49.231 49.231-39.385 73.846-98.461 73.846-157.539 4.923-93.538 4.923-182.154 4.923-270.769v0z"></path>
      <path d="M514.499 733.539c-68.923 0-137.846-4.923-206.769-4.923-9.846 0-24.615 0-34.462-4.923-24.615-4.923-49.231-19.692-59.077-44.308-4.923-19.692-9.846-39.385-14.77-59.077-4.923-78.77 0-157.539 4.923-231.384 0-14.77 4.923-24.615 9.846-39.385 9.846-29.538 39.385-54.153 68.923-54.153 59.077-4.923 113.231-4.923 172.308-4.923 83.693 0 167.385 0 251.077 4.923 14.77 0 29.538 0 44.308 4.923 34.462 4.923 59.077 24.615 68.923 54.153 4.923 19.692 9.846 44.308 9.846 68.923 4.923 68.923 4.923 142.769-4.923 211.693 0 14.77-4.923 24.615-9.846 34.462-9.846 34.462-39.385 59.077-73.846 59.077-49.231 4.923-98.461 4.923-142.769 4.923-29.538 0-54.153-4.923-83.693 0v0zM450.499 590.77c54.153-24.615 108.308-54.153 162.461-88.615-54.153-29.538-108.308-59.077-162.461-83.693v172.308z"></path>
    </svg>
  );
};
