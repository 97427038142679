/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice } from "@reduxjs/toolkit";
import { ISelfUser, IUser } from "./auth.interface";
import { RootState } from "../../store";

const authenticatedUserInitialState: ISelfUser = {
  id: "",
  contactNumber: "",
  name: "",
  isActive: false,
  email: "",
  profileImage: "",
  userType: {
    id: 0,
    key: ""
  },
  roles: [],
  dateOfBirth: "",
  address: ""
};
const initialState: IUser = {
  token: "",
  authenticatedUser: authenticatedUserInitialState,
  initialLoginCheck: false
};

const user = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    Authenticate: (state, auth) => {
      state.token = auth.payload.token;
    },
    AddUserDetails: (state, user) => {
      state.authenticatedUser = { ...state.authenticatedUser, ...user.payload };
    },
    updateInitialLoginStatus: (state, data) => {
      state.initialLoginCheck = data.payload.initialLoginCheck;
    },
    ResetUserDetails: state => {
      state.token = "";
      state.authenticatedUser = { ...authenticatedUserInitialState };
    }
  }
});

export const { Authenticate, AddUserDetails, updateInitialLoginStatus, ResetUserDetails } = user.actions;
export const getAuthenticatedUser = (state: RootState) => state.auth;
export const getAuthenticatedUserType = (state: RootState) => state.auth?.authenticatedUser?.userType;
export const getInitialLoginStatus = (state: RootState) => state.auth.initialLoginCheck;

export default user.reducer;
