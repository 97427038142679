import React, { lazy } from "react";

const CartInvoice = lazy(() => import("../../modules/health/container/invoiceDetails"));
const PersonalAccidentCartInvoice = lazy(() => import("../../modules/personalAccident/container/invoiceDetails"));
const LifeCartInvoice = lazy(() => import("../../modules/life/container/invoiceDetails"));
const HouseHoldCartInvoice = lazy(() => import("../../modules/houseHold/container/invoiceDetails"));
const AutoCartInvoice = lazy(() => import("../../modules/auto/container/invoiceDetails"));

export const cartRoutes = [
  {
    path: "/cart/:cartID/invoice/",
    element: <CartInvoice />
  },
  {
    path: "/auto-cart/:cartID/invoice/",
    element: <AutoCartInvoice />
  },
  {
    path: "/personal-accident-cart/:cartID/invoice/",
    element: <PersonalAccidentCartInvoice />
  },
  {
    path: "/house-hold-cart/:cartID/invoice/",
    element: <HouseHoldCartInvoice />
  },
  {
    path: "/life-cart/:cartID/invoice/",
    element: <LifeCartInvoice />
  }
];
