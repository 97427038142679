import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Tick = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M337.717 896.661v0c-21.876 0-43.023-8.75-58.337-24.064l-255.225-255.225c-32.085-32.085-32.085-84.588-0.73-116.674 0 0 0 0 0.73-0.73 32.085-32.085 84.588-32.085 116.674 0 0 0 0 0 0 0l196.887 196.887 545.452-545.452c32.085-32.085 84.588-32.085 116.674 0v0c32.085 32.085 32.085 84.588 0.73 116.674 0 0 0 0-0.73 0.73l-603.789 603.789c-15.314 15.314-36.461 24.064-58.337 24.064z"></path>
    </svg>
  );
};
