import React, { ReactElement, useState, useEffect } from "react";
import CustomDatePicker from "../../../../shared/components/form-elements/custom-datepicker";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Api from "../../../../shared/network/axiosClients";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  phoneNumber: string; // Pass the phone number as a prop
  email: string;
  name: string;
}

const Sidebar = ({ isOpen, toggleSidebar, phoneNumber: propPhoneNumber , email:propEmail , name: propName }: SidebarProps): ReactElement => {
  const [isCustomDateSelected, setIsCustomDateSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string | null>(propPhoneNumber);
  const [email, setEmail] = useState<string | null>(propEmail);
  const [name, setName] = useState<string | null>(propName);
  const { search: queryParams } = useLocation();

  const timeSlots = [
    { label: "11 AM-12 PM", hour: 11 },
    { label: "12 PM-1 PM", hour: 12 },
    { label: "1 PM-2 PM", hour: 13 },
    { label: "2 PM-3 PM", hour: 14 },
    { label: "3 PM-4 PM", hour: 15 },
    { label: "4 PM-5 PM", hour: 16 },
  ];

  const isToday = selectedDate?.toDateString() === new Date().toDateString();
  const currentHour = new Date().getHours();

  const filteredTimeSlots = isToday
    ? timeSlots.filter((slot) => slot.hour > currentHour) // Exclude past time slots if "Today" is selected
    : timeSlots;

  const handleTimeSelection = (timeLabel: string) => {
    setSelectedTime(timeLabel);
  };

  // Function to get the phone number, name, email from the query string
  // const getContactNumberFromQueryParams = (queryString: string): string | null => {
  //   const params = new URLSearchParams(queryString);
  //   return params.get("contactNumber") || null;
  // };

  //   // Function to get the email from the query string
  //   const getEmailFromQueryParams = (queryString: string): string | null => {
  //     const params = new URLSearchParams(queryString);
  //     return params.get("email") || null;
  //   };

  //     // Function to get the name from the query string
  //     const getNameFromQueryParams = (queryString: string): string | null => {
  //       const params = new URLSearchParams(queryString);
  //       return params.get("name") || null;
  //     };
  

      useEffect(() => {
        const params = new URLSearchParams(queryParams);
        const contactNumber = params.get("contactNumber");
        const email = params.get("email");
        const name = params.get("name");
      
        if (contactNumber) {
          setPhoneNumber(contactNumber);
        }
        if (email) {
          setEmail(email);
        }
        if (name) {
          setName(name);
        }
      
        // For debugging, log all the values being used
        console.log("Phone number:", contactNumber);
        console.log("Email:", email);
        console.log("Name:", name);
      }, [queryParams]);

  const handleConfirm = async () => {
    if (!selectedDate) {
      setErrorMessage("Please select a date.");
      return; // Don't proceed if no date is selected
    }
  
    if (!selectedTime || ["Today", "Tomorrow", "Custom Date"].includes(selectedTime)) {
      setErrorMessage("Please select a valid time.");
      return; // Don't proceed if time is not selected or is one of the invalid values
    }
  
    setIsLoading(true);
    setErrorMessage(""); // Reset error message on successful selection
  
    const formattedDate = selectedDate.toLocaleDateString("en-GB"); // Format the date as 'dd/MM/yyyy'
  
    try {
      await Api.post("web/quick-call", {
        date: formattedDate,
        time: selectedTime, // Only pass time if it's a valid selection
        phoneNumber,
        email,
        name
      });
  
      setFormSubmitted(true); // Display the thank you message after success
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  
  

  if (formSubmitted) {
    return (
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="sidebarContent">
          {/* <button onClick={toggleSidebar}>x</button> */}
          <div className="container agentForm">
            <img
              src="/assets/img/thankyouAgent.svg"
              alt="Thankyou Agent"
            />
            <h2 className="mt-3">Your call is scheduled successfully!</h2>
            <p>Our takaful specialist will contact you</p>
            <div className="d-flex justify-content-center">
              <p className='mx-1'> {selectedDate ? selectedDate.toLocaleDateString("en-GB") : "N/A"}</p>
              <p className='mx-1'> {selectedTime || "N/A"}</p>

              <button
                onClick={toggleSidebar}
                className="confirmbut"
              >
                Okay,Got it!
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const oneYearFutureDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebarContent">
        <button
          className="crossIconForm"
          onClick={toggleSidebar}
        >
          x
        </button>
        <div className="container-fluid ps-0 callForm">
          <div className="row">
            <div className="col-md-6 pt-5 advisorSchedule">
              <h5 className="sidebarHeading">Ask our advisor for right plans and discounts</h5>
              <p className="sidebarCaption">Pick your preferred time</p>
            </div>
            <div className="col-md-6 scheduleHeaderImg">
              <img
                src="/assets/img/scheduleacallimg.svg"
                alt="Schedule"
              />
            </div>
          </div>
        </div>
        <div className="padding-pa">
          <div className="container">
            <div className="row">
              <h3 className="sidebarHeading mt-5 mb-3">Select Day</h3>
              <div className="col-4">
                <button
                  className={`w-100 btn-scheduleaCall ${
                    selectedDate && selectedDate.toDateString() === new Date().toDateString() && !isCustomDateSelected ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedDate(new Date());
                    setSelectedTime("Today");
                    setIsCustomDateSelected(false);
                  }}
                >
                  Today
                </button>
              </div>
              <div className="col-4">
                <button
                  className={`w-100 btn-scheduleaCall ${
                    selectedDate && selectedDate.toDateString() === new Date(Date.now() + 86400000).toDateString() && !isCustomDateSelected
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setSelectedDate(new Date(Date.now() + 86400000));
                    setSelectedTime("Tomorrow");
                    setIsCustomDateSelected(false);
                  }}
                >
                  Tomorrow
                </button>
              </div>
              <div className="col-4">
                <button
                  className={`w-100 btn-scheduleaCall ${isCustomDateSelected ? "active" : ""}`}
                  onClick={() => {
                    setIsCustomDateSelected(true);
                    setSelectedTime("Custom Date");
                  }}
                >
                  Select Date
                </button>
              </div>
              <div className="col-md-12 mt-3">
                {isCustomDateSelected && (
                  <CustomDatePicker
                    showIcon={true}
                    label="Select Date"
                    onChange={(date: Date) => {
                      setSelectedDate(date);
                      setIsCustomDateSelected(true);
                      setSelectedTime("Custom Date");
                    }}
                    value={selectedDate}
                    minDate={`${new Date()}`}
                    maxDate={oneYearFutureDate}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <h3 className="sidebarHeading mt-5 mb-3">Select Time</h3>
              {filteredTimeSlots.map((slot) => (
  <div className="col-6 col-md-4 mb-2" key={slot.label}>
    <button
      className={`w-100 btn-scheduleaCall ${selectedTime === slot.label ? "active" : ""}`}
      onClick={() => handleTimeSelection(slot.label)} // Ensure this is calling handleTimeSelection
    >
      {slot.label}
    </button>
  </div>
))}

    

              {errorMessage && <div className="error-message">{errorMessage}</div>}

              <div className="col-md-12 mt-3">
              <button
  className="confirmbut"
  onClick={handleConfirm}
  disabled={isLoading || !selectedDate || !selectedTime} // Button is disabled if either date or time is not selected
>
  {isLoading ? "Submitting..." : "Confirm"}
</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
