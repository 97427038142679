import { Button, Form } from "react-bootstrap";
import React, { MouseEventHandler, ReactElement } from "react";
import { emailPattern, noSpecialCharacter, otpPattern, phonePattern } from "../../../../shared/regex";
import { useTranslation } from "react-i18next";
import { ISignup } from "../../utils/interface";
import { SubmitHandler, UseFormReturn } from "react-hook-form";

interface IProps {
  onSubmit: SubmitHandler<ISignup>;
  onClickSendOtp: MouseEventHandler<HTMLButtonElement>;
  loading: boolean;
  form: UseFormReturn<ISignup>;
}

export default function SignupComponent({ onSubmit, onClickSendOtp, loading, form }: IProps): ReactElement {
  const { t } = useTranslation();
  return (
    <div>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Form.Group className="floating-input mb-4">
          <Form.Control
            id="full_name"
            data-testid="full_name"
            type="text"
            placeholder=""
            {...form.register("name", {
              required: t("auth_signup_name_required_error"),
              maxLength: {
                value: 35,
                message: t("auth_signup_name_max_length_error")
              },
              pattern: {
                value: noSpecialCharacter,
                message: t("health_landing_user_details_lead_form_name_special_character_error")
              }
            })}
          />
          <Form.Label htmlFor="full_name">
            {t("auth_signup_name_label")}
            <span>*</span>
          </Form.Label>
          <div className={"text-primary fs-12px hide-empty mt-1"}>
            {form.formState.errors.name && <span>{form.formState.errors.name.message}</span>}
          </div>
        </Form.Group>
        <Form.Group className="floating-input mb-3">
          <Form.Control
            id="email"
            type="text"
            data-testid="email"
            placeholder=""
            {...form.register("email", {
              required: t("auth_signup_email_required_error"),
              pattern: {
                value: emailPattern,
                message: t("auth_signup_email_valid_error")
              }
            })}
          />

          <Form.Label htmlFor="email">
            {t("auth_signup_email_label")}
            <span>*</span>
          </Form.Label>
          <div className={"text-primary fs-12px hide-empty mt-1"}>
            {form.formState.errors.email && <span>{form.formState.errors.email.message}</span>}
          </div>
        </Form.Group>
        <Form.Group className="floating-input mb-4">
          <Form.Control
            id="contact_number"
            type="text"
            data-testid="contact_number"
            placeholder=""
            {...form.register("contactNumber", {
              required: t("auth_signup_contact_number_required_error"),
              pattern: {
                value: phonePattern,
                message: t("auth_signup_contact_number_valid_error")
              }
            })}
          />
          <div className={"text-primary fs-12px hide-empty mt-1"}>
            {form.formState.errors.contactNumber && <span>{form.formState.errors.contactNumber.message}</span>}
          </div>
          <Form.Label htmlFor="contact_number">
            {t("auth_signup_contact_number_label")}
            <span>*</span>
          </Form.Label>
          <Button
            variant="light"
            className="otp-btn"
            data-testid="otp-button"
            onClick={onClickSendOtp}
            disabled={
              loading ||
              (form.formState.errors?.email && form.formState.dirtyFields?.email) ||
              (form.formState.errors?.contactNumber && form.formState.dirtyFields.contactNumber)
            }
          >
            {t("auth_login_send_otp_button")}
          </Button>
        </Form.Group>
        <Form.Group className="floating-input">
          <Form.Control
            id="otp_code"
            type="text"
            data-testid="otp_code"
            placeholder=""
            {...form.register("otpCode", {
              required: t("auth_login_otp_required_error"),
              maxLength: {
                value: 4,
                message: t("auth_login_otp_max_length_error")
              },
              minLength: {
                value: 4,
                message: t("auth_login_otp_min_length_error")
              },
              pattern: {
                value: otpPattern,
                message: t("auth_login_otp_format_error")
              }
            })}
            onChange={e => {
              form.setValue("otpCode", e.target.value);
            }}
          />
          <div className={"text-primary fs-12px hide-empty mt-1"}>
            {form.formState.errors.otpCode && <span>{form.formState.errors.otpCode.message}</span>}
          </div>
          <Form.Label htmlFor="otp_code">
            {t("auth_login_enter_otp_label")}
            <span>*</span>
          </Form.Label>
          <Form.Check
            data-testid="term_and_condition"
            className="hwb-accept-terms mt-2"
            type={"checkbox"}
            {...(form.register &&
              form.register("termAndCondition", {
                required: t("health_landing_user_details_lead_form_terms_required_error")
              }))}
            label={
              <>
                {t("health_landing_user_details_form_terms_agree")}
                <a href=" https://takafulbazaar.pk/terms-and-conditions">{t("health_landing_user_details_form_terms_and_condition_agree")}</a>
              </>
            }
          />
          <div className={"text-primary fs-12px hide-empty mt-1"}>
            {form.formState.errors.termAndCondition && <span>{form.formState.errors.termAndCondition.message}</span>}
          </div>
        </Form.Group>
        <Button
          size="lg"
          type={"submit"}
          className="w-100 d-flex mt-4"
          data-testid="signup-button"
        >
          {t("auth_signup_btn_text")}
        </Button>
      </form>
    </div>
  );
}
