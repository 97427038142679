import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// eslint-disable-next-line import/extensions
import enTranslations from "./locales/en.json";

const resources = {
  en: {
    translation: enTranslations
  }
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
