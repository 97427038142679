import React, { useState } from "react";
import { Button, Container, Row, Nav } from "react-bootstrap";
import AboutTakaful from "../aboutTakaful";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const SmeBanner = ({ onNext }: { onNext: Function }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeTab, setActiveTab] = useState("standard");

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    centerMode: true, // Ensures the center slide is in focus
    beforeChange: (_, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 768, // Define breakpoint for mobile (768px or smaller)
        settings: {
          slidesToShow: 1, // Show only 1 slide
          centerMode: true // Optional: Adjust as needed for mobile layout
        }
      }
    ]
  };

  // Plans data for both tabs
  const standardPlans = [
    {
      title: "Plan B",
      limit: "Annual Hospitalization Limit",
      limitRs: "400,000",
      roomLimit: "Rs. 12,000",
      ppHospitalization: "30 Days",
      dayCare: "Covered",
      deliveryNormal: "Rs. 100,000",
      ComplicatedDelivery: "Rs. 150,000",
      indivisual: 4687
    },
    {
      title: "Plan C",
      limit: "Annual Hospitalization Limit",
      limitRs: "300,000",
      roomLimit: "Rs. 10,000",
      ppHospitalization: "30 Days",
      dayCare: "Covered",
      deliveryNormal: "Rs. 100,000",
      ComplicatedDelivery: "Rs. 150,000",
      indivisual: 3719
    },
    {
      title: "Plan D",
      limit: "Annual Hospitalization Limit",
      limitRs: "200,000",
      roomLimit: "Rs. 7,000",
      ppHospitalization: "30 Days",
      dayCare: "Covered",
      deliveryNormal: "Rs. 100,000",
      ComplicatedDelivery: "Rs. 150,000",
      indivisual: 2876
    },
    {
      title: "Plan E",
      limit: "Annual Hospitalization Limit",
      limitRs: "100,000",
      roomLimit: "Rs. 5,000",
      ppHospitalization: "30 Days",
      dayCare: "Covered",
      deliveryNormal: "Rs. 100,000",
      ComplicatedDelivery: "Rs. 150,000",
      indivisual: 2594
    },
    {
      title: "Plan A",
      limit: "Annual Hospitalization Limit",
      limitRs: "500,000",
      roomLimit: "Rs. 15,000",
      ppHospitalization: "30 Days",
      dayCare: "Covered",
      deliveryNormal: "Rs. 100,000",
      ComplicatedDelivery: "Rs. 150,000",
      indivisual: 7062
    },
  ];

  const exclusivePlans = [
    {
      title: "Plan G",
      limit: "Annual Hospitalization Limit",
      limitRs: "125,000",
      roomLimit: "Rs. 4,000",
      ppHospitalization: "30 Days",
      dayCare: "Covered",
      deliveryNormal: "Rs. 150,000",
      ComplicatedDelivery: "Rs. 200,000",
      indivisual: 2510
    },
    {
      title: "Plan H",
      limit: "Annual Hospitalization Limit",
      limitRs: "75,000",
      roomLimit: "Rs. 2,500",
      ppHospitalization: "30 Days",
      dayCare: "Covered",
      deliveryNormal: "Rs. 150,000",
      ComplicatedDelivery: "Rs. 200,000",
      indivisual: 2198
    },
    {
      title: "Plan I",
      limit: "Annual Hospitalization Limit",
      limitRs: "10,00,000",
      roomLimit: "Rs. 25,000",
      ppHospitalization: "30 Days",
      dayCare: "Covered",
      deliveryNormal: "Rs. 150,000",
      ComplicatedDelivery: "Rs. 200,000",
      indivisual: 18903
    },
    {
      title: "Plan J",
      limit: "Annual Hospitalization Limit",
      limitRs: "700,000",
      roomLimit: "Rs. 20,000",
      ppHospitalization: "30 Days",
      dayCare: "Covered",
      deliveryNormal: "Rs. 150,000",
      ComplicatedDelivery: "Rs. 200,000",
      indivisual: 12089
    },
    {
      title: "Plan F",
      limit: "Annual Hospitalization Limit",
      limitRs: "200,000",
      roomLimit: "Rs. 5,000",
      ppHospitalization: "30 Days",
      dayCare: "Covered",
      deliveryNormal: "Rs. 100,000",
      ComplicatedDelivery: "Rs. 150,000",
      indivisual: 2659
    }
  ];

  const navigate = useNavigate();

  const handleGetStarted = (planIndivisual, planLimitRs) => {
    // Navigate to /sme with the hospitalizationLimit query parameter
    navigate(`/sme?hospitalizationLimit=${planLimitRs}`);

    // Call onNext as well to trigger whatever functionality it needs
    onNext(planIndivisual, planLimitRs);
  };
  return (
    <>
      <section className="heroBanner-sme">
        <Container className="heroBanner-smeContainer">
          <Row>
            <div className="col-md-6">
              <h1 className="sme-h1 pt-100-small-none">
                All Employee Benefits,​ <span className="text-primary">Under One Roof</span>
              </h1>
              <p className="sehatZamin-caption SZ-caption-color pe-5">Secure your team’s health with plans that fit within your budget.</p>
              {/* <Button
                className="getQuoteSme mt-4"
                onClick={() => onNext(1)}
              >
                Get Quote
              </Button> */}
            </div>
          </Row>
        </Container>
      </section>
      <AboutTakaful />
      <div className="coverageSelection pt-5">
        <Container className="text-center">
          <h1 className="cyc-title">
            Choose Your <span className="text-primary">Coverage</span>
          </h1>
          <p className="cyc-caption">Secure your team’s health with plans that fit within your budget.</p>
          <Nav
            justify
            variant="tabs"
            defaultActiveKey="standard"
            className="customNavTabs my-5"
          >
            <Nav.Item className="navItems">
              <Nav.Link
                eventKey="standard"
                onClick={() => setActiveTab("standard")}
                className={`mycustomTabs ${activeTab === "standard" ? "active-tab" : ""}`}
              >
                Standard
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="navItems">
              <Nav.Link
                eventKey="exclusive"
                onClick={() => setActiveTab("exclusive")}
                className={`mycustomTabs ${activeTab === "exclusive" ? "active-tab" : ""}`}
              >
                Exclusive
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="slider-container text-start">
            {activeTab === "standard" && (
              <Slider {...settings}>
                {standardPlans.map((plan, index) => (
                  <div
                    key={index}
                    className={`plan-slide planBoxes ${currentSlide === index ? "active-slide" : ""}`}
                  >
                    <h3 className="plansHead mb-3">{plan.title}</h3>
                    <h3 className="planCoverages">{plan.limit}</h3>
                    <h3 className="limitRs">
                      {plan.limitRs} <span>Rs.</span>
                    </h3>
                    <h3 className="planCoverages mt-4">Room & Board (per day)</h3>
                    <h4 className="planCoveragesPrice">{plan.roomLimit}</h4>
                    <h3 className="planCoverages">Pre & Post Hospitalization Sub Limit</h3>
                    <h4 className="planCoveragesPrice">{plan.ppHospitalization}</h4>
                    <h3 className="planCoverages">Day Care Surgeries and Specialized Investigations</h3>
                    <h4 className="planCoveragesPrice">{plan.dayCare}</h4>
                    <Button
                      className={`getStartedBtn ${currentSlide !== index ? "inactive-btn" : ""}`}
                      onClick={() => handleGetStarted(plan.indivisual, plan.limitRs)}
                      // disabled={currentSlide !== index}
                    >
                      Get Started
                    </Button>
                  </div>
                ))}
              </Slider>
            )}
            {activeTab === "exclusive" && (
              <Slider {...settings}>
                {exclusivePlans.map((plan, index) => (
                  <div
                    key={index}
                    className={`plan-slide planBoxes ${currentSlide === index ? "active-slide" : ""}`}
                  >
                    <h3 className="plansHead mb-3">{plan.title}</h3>
                    <h3 className="planCoverages">{plan.limit}</h3>
                    <h3 className="limitRs">
                      {plan.limitRs} <span>Rs.</span>
                    </h3>
                    <h3 className="planCoverages mt-4">Room & Board (per day)</h3>
                    <h4 className="planCoveragesPrice">{plan.roomLimit}</h4>
                    <h3 className="planCoverages">Pre & Post Hospitalization Sub Limit</h3>
                    <h4 className="planCoveragesPrice">{plan.ppHospitalization}</h4>
                    <h3 className="planCoverages">Day Care Surgeries and Specialized Investigations</h3>
                    <h4 className="planCoveragesPrice">{plan.dayCare}</h4>
                    <Button
                      className={`getStartedBtn ${currentSlide !== index ? "inactive-btn" : ""}`}
                      onClick={() => handleGetStarted(plan.indivisual, plan.limitRs)}
                      // disabled={currentSlide !== index}
                    >
                      Get Started
                    </Button>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default SmeBanner;
