import React, { ReactElement } from "react";

export const Search = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M1006.641 932.357l-176.992-182.523c60.841-77.434 99.558-176.992 99.558-287.612 0-254.426-210.178-459.072-464.604-459.072s-464.604 204.647-464.604 459.072 210.178 459.072 464.604 459.072c105.089 0 204.647-38.717 287.612-99.558l182.523 182.523c11.062 11.062 27.655 16.593 38.717 16.593 16.593 0 27.655-5.531 38.717-16.593 16.593-16.593 16.593-49.779-5.531-71.903zM110.62 462.221c0-193.585 160.399-348.453 353.983-348.453s353.983 154.868 353.983 348.453c0 94.027-38.717 182.523-105.089 248.894 0 0 0 0 0 0s0 0 0 0c-66.372 60.841-149.337 99.558-248.894 99.558-193.585 5.531-353.983-154.868-353.983-348.453z"></path>
    </svg>
  );
};
