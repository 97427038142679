import React, { lazy } from "react";

const HouseHold = lazy(() => import("../../modules/houseHold/container/home"));
const HouseHoldPlans = lazy(() => import("../../modules/houseHold/container/planListing"));

const HouseHoldPlansDetail = lazy(() => import("../../modules/houseHold/container/planDetails"));
const HouseHoldPlansCompare = lazy(() => import("../../modules/houseHold/container/compare"));

const HouseHoldOrderDetailsDocumentUpload = lazy(() => import("../../modules/houseHold/container/orderDetails/document-upload"));
const HouseHoldOrderDetailsPaymentDetails = lazy(() => import("../../modules/houseHold/container/orderDetails/payment-details"));
const HouseHoldOrderDetailsPersonalDetails = lazy(() => import("../../modules/houseHold/container/orderDetails/personal-details"));
const HouseHoldOrderDetailsReviewDetails = lazy(() => import("../../modules/houseHold/container/orderDetails/review-details"));

export const houseHoldRoutes = [
  {
    path: "/house-hold",
    element: <HouseHold />
  },
  {
    path: "house-hold/plans/:quotationID",
    element: <HouseHoldPlans />
  },
  {
    path: "/house-hold/plans/:quotationID/detail/:id",
    element: <HouseHoldPlansDetail />
  },
  {
    path: "/house-hold/plans/:quotationID/compare/:id",
    element: <HouseHoldPlansCompare />
  },
  {
    path: "/house-hold/order-details/:quotationID/:cartID/document-upload",
    element: <HouseHoldOrderDetailsDocumentUpload />
  },
  {
    path: "/house-hold/order-details/:quotationID/:cartID/payment-details",
    element: <HouseHoldOrderDetailsPaymentDetails />
  },
  {
    path: "/house-hold/order-details/:quotationID/:cartID/personal-details",
    element: <HouseHoldOrderDetailsPersonalDetails />
  },
  {
    path: "/house-hold/order-details/:quotationID/:cartID/review-details",
    element: <HouseHoldOrderDetailsReviewDetails />
  }
];
