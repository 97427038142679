import React, { ReactElement } from "react";

export const Twitter = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M514.095 1023.624c-88.978 0-173.013 0-257.048 0-69.205 0-133.467-24.716-177.956-69.205-29.659-29.659-54.375-64.262-64.262-103.808-9.887-29.659-14.83-59.318-14.83-84.035 0-173.013 0-346.026 0-519.038 0-98.864 59.318-192.786 153.24-227.389 34.603-14.83 64.262-19.772 98.864-19.772 113.694 0 232.331 0 346.026 0 59.318 0 123.58 0 182.9 0 74.148 0 148.297 34.603 192.786 98.864 34.603 44.489 54.375 98.864 49.433 153.24 0 173.013 0 350.969 0 523.982 0 113.694-79.092 212.559-192.786 237.275-19.772 4.943-44.489 4.943-64.262 4.943-44.489 0-84.035 0-128.523 0l-123.58 4.943zM39.546 509.529v0c0 14.83 0 29.659 0 44.489 0 79.092 0 158.184 0 237.275 0 54.375 24.716 103.808 64.262 138.41 39.546 39.546 98.864 59.318 153.24 59.318 158.184 0 311.423 0 469.607 0 29.659 0 54.375 0 84.035-4.943 54.375-4.943 98.864-34.603 133.467-74.148s49.433-88.978 49.433-138.41c0-64.262 0-123.58 0-187.843 0-108.751 0-222.445 0-331.196 0-69.205-34.603-138.41-93.921-173.013-34.603-24.716-79.092-39.546-123.58-34.603-108.751 0-212.559 0-321.31 0-69.205 0-138.41 0-207.615 0s-133.467 34.603-173.013 88.978c-24.716 24.716-34.603 69.205-34.603 108.751 0 88.978 0 177.956 0 266.934z"></path>
      <path d="M212.559 727.030c69.205 0 133.467-19.772 182.9-59.318-54.375-4.943-98.864-39.546-118.638-88.978 14.83 0 34.603 0 49.433-4.943-39.546-9.887-69.205-34.603-84.035-69.205-4.943-19.772-9.887-39.546-9.887-59.318l49.433 14.83v-4.943c-29.659-24.716-49.433-64.262-49.433-108.751 0-24.716 4.943-44.489 19.772-64.262 49.433 64.262 118.638 108.751 192.786 128.523 24.716 4.943 49.433 9.887 74.148 9.887 0-4.943 0-9.887 0-14.83-4.943-54.375 19.772-103.808 64.262-128.523 44.489-29.659 103.808-24.716 143.354 9.887 4.943 0 4.943 4.943 9.887 4.943 4.943 9.887 14.83 9.887 19.772 4.943 24.716-9.887 44.489-14.83 69.205-24.716-9.887 24.716-24.716 49.433-49.433 64.262 19.772 0 44.489-4.943 64.262-14.83 0 4.943-4.943 9.887-4.943 9.887-14.83 14.83-29.659 29.659-44.489 44.489-4.943 4.943-9.887 14.83-9.887 19.772 0 69.205-19.772 138.41-54.375 197.729-59.318 103.808-163.126 168.069-281.764 177.956-79.092 9.887-158.184-9.887-227.389-49.433 0 4.943 0 4.943-4.943 4.943z"></path>
    </svg>
  );
};
