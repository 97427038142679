import React, { ReactElement, Suspense, useEffect } from "react";
import { AddUserDetails, getInitialLoginStatus, updateInitialLoginStatus } from "../../../redux/features/auth";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoader from "./layout/Loaders/SpinnerLoader";
import { getLoading } from "../../../redux/features/loader";
import { Toaster } from "react-hot-toast";
import { getUserInfo } from "../../authentication/services";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { PublicRoutes } from "../../../routes/publicRoute";
import { PrivateRoutes } from "../../../routes/privateRoute";
import MainContainer from "./layout";
import StaticPageWrapper from "../component/static-page-wrapper";
import RemoveTrailingSlash from "../../../shared/components/remove-trailing-slash";

export default function Container(): ReactElement {
  const initialLoginStatus = useSelector(getInitialLoginStatus);
  const { isLoading } = useSelector(getLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const { data } = await getUserInfo();
        dispatch(updateInitialLoginStatus({ initialLoginCheck: true }));
        if (data.success) {
          dispatch(AddUserDetails(data.data));
        }
      } catch (err) {
        console.warn(err);
      }
    };
    if (!initialLoginStatus) fetchUserInfo();
  }, []);

  return (
    <div className="main">
      {/*
        These anchor tags below are defined just for static pages, on build time react-snap scan all the files and check if it found any anchor tag with specified route then it will create a static file agains that specific route,. though it doesn't necessary to show the anchor tag to user
      */}
      <StaticPageWrapper />

      <Router>
        {isLoading && <SpinnerLoader />}
        <Toaster
          position="top-right"
          toastOptions={{
            style: {
              marginTop: "50px",
              minWidth: "15vw",
              minHeight: "8vh"
            },
            success: {
              className: "success-toast"
            },
            error: {
              className: "error-toast"
            }
          }}
        />

        <Suspense fallback={<SpinnerLoader />}>
          <RemoveTrailingSlash />
          <Routes>
            <Route
              path="/"
              element={<MainContainer />}
            >
              {/* Health related routes are defined here */}
              {[...PublicRoutes, ...PrivateRoutes].map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}
