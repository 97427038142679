import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Reset = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M803.274 93.618l-5.036-17.298c-1.872-5.439-2.952-11.707-2.952-18.228 0-25.344 16.329-46.873 39.041-54.642l0.406-0.121c5.706-2.11 12.297-3.329 19.173-3.329 24.834 0 45.949 15.92 53.696 38.112l0.121 0.399c18.175 51.822 35.4 103.937 52.333 156.197 4.522 11.447 7.192 24.702 7.299 38.567v0.044c-1.589 28.995-25.488 51.905-54.738 51.905-0.361 0-0.721-0.003-1.080-0.010l0.054 0.001q-87.149 1.241-174.299 0c-27.203-0.009-49.852-19.496-54.76-45.27l-0.055-0.348c-0.583-3.047-0.917-6.553-0.917-10.136 0-22.097 12.687-41.232 31.175-50.516l0.325-0.148 7.299-3.868c-24.652-16.336-53.046-30.047-83.257-39.692l-2.434-0.671c-36.886-12.481-79.369-19.684-123.536-19.684-192.776 0-353.5 137.227-389.816 319.326l-0.422 2.535c-4.512 22.489-7.095 48.343-7.095 74.799 0 196.381 142.278 359.531 329.377 391.993l2.374 0.341c19.888 3.533 42.783 5.554 66.151 5.554 198.643 0 363.185-146.004 392.167-336.552l0.275-2.2c3.065-19.635 2.919-39.706 4.379-59.633 0.941-26.503 19.675-48.375 44.575-54.084l0.387-0.075c3.631-0.845 7.801-1.329 12.083-1.329 21.935 0 40.905 12.699 49.958 31.146l0.145 0.327c3.923 8.912 6.206 19.303 6.206 30.227 0 0.202-0.001 0.404-0.002 0.606v-0.031c-2.102 242.684-173.272 444.793-401.358 494.4l-3.368 0.615c-31.912 7.090-68.565 11.152-106.168 11.152-248.928 0-456.278-178.018-501.685-413.692l-0.515-3.208c-5.506-28.158-8.657-60.538-8.657-93.652 0-250.567 180.37-459.006 418.354-502.561l3.131-0.476c27.5-5.338 59.125-8.393 91.463-8.393 102.347 0 197.549 30.592 276.961 83.13l-1.872-1.164z"></path>
    </svg>
  );
};
