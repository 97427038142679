import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Credit = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M0 367.302c3.412-17.063 13.65-34.124 23.887-47.775 13.65-13.65 34.124-20.475 54.599-20.475 23.887 0 51.187 0 75.074 0h685.907c37.538-3.412 68.25 20.475 81.899 54.599 3.412 10.237 3.412 20.475 3.412 30.712 0 75.074 0 153.561 0 228.635 0 3.412 0 6.825 0 10.237-3.412 3.412-6.825 10.237-13.65 10.237s-13.65-3.412-13.65-10.237c0 0 0 0 0 0 0-3.412 0-10.237 0-13.65 0-75.074 0-150.149 0-221.811 3.412-27.3-13.65-54.599-40.95-58.012-3.412 0-10.237 0-13.65 0h-757.569c-27.3-3.412-47.775 13.65-54.599 37.538 0 3.412 0 10.237 0 13.65 0 146.736 0 293.473 0 440.209-3.412 27.3 17.063 51.187 44.362 54.599 3.412 0 6.825 0 6.825 0 255.936 0 508.459 0 764.394 0 27.3 3.412 51.187-17.063 54.599-44.362 0-3.412 0-6.825 0-6.825 0-44.362 0-88.725 0-133.086 0-3.412 0-6.825 0-10.237 0-6.825 6.825-13.65 13.65-13.65s13.65 3.412 13.65 13.65c0 0 0 0 0 0 0 6.825 0 10.237 0 17.063 27.3 6.825 58.012-10.237 64.837-40.95 0-6.825 3.412-13.65 0-20.475-10.237-71.662-17.063-143.324-23.887-214.986-10.237-75.074-17.063-150.149-27.3-225.223-3.412-27.3-27.3-47.775-54.599-44.362 0 0-3.412 0-3.412 0-30.712 3.412-58.012 6.825-88.725 10.237l-197.923 23.887c-85.312 10.237-167.211 20.475-252.523 30.712-71.662 6.825-143.324 13.65-214.986 23.887-13.65 0-30.712 6.825-40.95 17.063-3.412 6.825-13.65 6.825-20.475 3.412 0 0 0 0-3.412 0-3.412-6.825-3.412-17.063 3.412-23.887 0 0 0 0 0 0 17.063-17.063 37.538-27.3 61.425-27.3 85.312-10.237 170.624-20.475 252.523-30.712s167.211-20.475 249.11-27.3c81.899-10.237 163.799-20.475 249.11-30.712 44.362-6.825 85.312 23.887 92.137 68.25 0 3.412 0 3.412 0 6.825 6.825 78.487 17.063 153.561 27.3 232.049 6.825 68.25 17.063 139.912 23.887 208.161 3.412 44.362-27.3 81.899-68.25 85.312 0 0 0 0 0 0-23.887 3.412-23.887 3.412-23.887 27.3s0 44.362 0 68.25c3.412 44.362-30.712 81.899-75.074 85.312-3.412 0-6.825 0-10.237 0h-760.981c-40.95 3.412-78.487-23.887-85.312-64.837 0 0 0 0 0 0v-470.921z"></path>
      <path d="M191.099 473.089c13.65 0 30.712 0 44.362 0 27.3 0 47.775 17.063 51.187 44.362 0 3.412 0 3.412 0 6.825 0 20.475 0 40.95 0 61.425 0 27.3-17.063 47.775-44.362 47.775-3.412 0-3.412 0-3.412 0-30.712 0-61.425 0-92.137 0-27.3 0-47.775-20.475-47.775-44.362 0 0 0-3.412 0-3.412 0-20.475 0-40.95 0-58.012 0-27.3 17.063-47.775 44.362-47.775 0 0 3.412 0 3.412 0 13.65-6.825 27.3-6.825 44.362-6.825zM191.099 602.763c13.65 0 30.712 0 44.362 0s20.475-6.825 20.475-20.475c0-20.475 0-40.95 0-58.012 0-13.65-6.825-20.475-20.475-20.475-30.712 0-61.425 0-92.137 0-13.65 0-20.475 6.825-20.475 20.475 0 20.475 0 37.538 0 58.012 0 13.65 6.825 20.475 20.475 20.475 17.063 0 34.124 0 47.775 0v0z"></path>
      <path d="M272.998 763.149c51.187 0 102.374 0 153.561 0 6.825 0 10.237 3.412 17.063 6.825 6.825 6.825 3.412 13.65 0 20.475-3.412 3.412-3.412 3.412-6.825 3.412s-6.825 0-10.237 0h-307.123c0 0-3.412 0-3.412 0-6.825 0-17.063-6.825-17.063-13.65 0 0 0 0 0 0 0-6.825 6.825-13.65 17.063-13.65 0 0 0 0 0 0 34.124 0 68.25 0 102.374 0l54.599-3.412z"></path>
      <path d="M798.519 496.976c0 27.3-6.825 51.187-17.063 75.074-3.412 10.237-13.65 13.65-20.475 10.237s-10.237-13.65-6.825-23.887c20.475-40.95 20.475-85.312 0-126.261-3.412-10.237-3.412-20.475 6.825-23.887s17.063 0 20.475 10.237c13.65 27.3 20.475 54.599 17.063 78.487z"></path>
      <path d="M754.157 711.962c-20.475 0-37.538 0-58.012 0-13.65 0-20.475-6.825-20.475-13.65s6.825-13.65 20.475-13.65c40.95 0 78.487 0 119.437 0 13.65 0 20.475 6.825 20.475 13.65s-6.825 13.65-20.475 13.65c-23.887 0-44.362 0-61.425 0z"></path>
      <path d="M174.036 711.962c-20.475 0-40.95 0-61.425 0-13.65 0-20.475-13.65-13.65-23.887 3.412-3.412 10.237-6.825 13.65-6.825 40.95 0 85.312 0 126.261 0 6.825 0 13.65 3.412 17.063 13.65 0 0 0 0 0 0 0 6.825-6.825 13.65-13.65 17.063 0 0 0 0-3.412 0-20.475 0-44.362 0-64.837 0z"></path>
      <path d="M559.646 684.663c20.475 0 40.95 0 61.425 0 10.237 0 17.063 6.825 17.063 13.65s-6.825 17.063-17.063 17.063c-40.95 0-81.899 0-122.849 0-10.237 0-17.063-6.825-17.063-13.65s6.825-13.65 17.063-13.65c23.887-3.412 40.95-3.412 61.425-3.412z"></path>
      <path d="M368.547 711.962c-20.475 0-40.95 0-61.425 0-10.237 0-17.063-6.825-20.475-13.65 0-10.237 6.825-13.65 20.475-13.65 40.95 0 78.487 0 119.437 0 13.65 0 20.475 6.825 20.475 13.65s-6.825 13.65-20.475 13.65c-20.475 0-37.538 0-58.012 0z"></path>
      <path d="M740.506 496.976c-3.412 17.063-6.825 30.712-13.65 47.775-3.412 6.825-10.237 13.65-17.063 10.237 0 0 0 0-3.412 0-6.825-3.412-10.237-10.237-6.825-20.475 0 0 0 0 0-3.412 10.237-23.887 10.237-47.775 0-71.662-3.412-6.825-3.412-17.063 6.825-20.475 0 0 0 0 0 0 6.825-3.412 17.063 0 20.475 6.825 0 0 0 0 0 3.412 3.412 17.063 6.825 30.712 13.65 47.775z"></path>
      <path d="M672.258 496.976c-3.412 6.825-3.412 13.65-6.825 20.475s-10.237 10.237-17.063 6.825c0 0 0 0 0 0-6.825-3.412-10.237-10.237-6.825-17.063 0-6.825 0-13.65 0-20.475-3.412-6.825 0-17.063 10.237-17.063 6.825-3.412 17.063 0 17.063 6.825 0 0 0 0 0 0 0 6.825 3.412 13.65 3.412 20.475z"></path>
      <path d="M194.511 537.925c6.825 0 10.237 0 17.063 0s13.65 6.825 13.65 13.65c0 0 0 0 0 0 0 6.825-3.412 13.65-10.237 13.65 0 0 0 0 0 0-13.65 0-23.887 0-37.538 0-6.825 0-13.65-6.825-13.65-13.65 0 0 0 0 0 0 0-6.825 3.412-13.65 13.65-13.65 0 0 0 0 0 0 3.412 0 10.237 0 17.063 0v0z"></path>
    </svg>
  );
};
