import { RootState } from "../../store";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false
};

const loaderSlice = createSlice({
  name: "loading",
  initialState: initialState,
  reducers: {
    showLoader: (state, { payload }) => {
      state.isLoading = payload.isLoading;
    }
  }
});

export const { showLoader } = loaderSlice.actions;

export const getLoading = (
  state: RootState
): {
  isLoading: boolean;
} => state.loading;

export default loaderSlice.reducer;
