import { userRepresentableDetails } from "../../../helper";
import { useTranslation } from "react-i18next";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import PlanCard from "./PlanCard";
import { getCartPlans, getRateModeType } from "../../../../redux/features/health/cart";
import { PlanTypes } from "../../../constant";
import { Button } from "react-bootstrap";
import { IHealthFamilyDetails } from "../../../../modules/health/utils/interfaces";

interface IProps {
  onCartRemove: (planID: number, cartID: string, planType: string) => void;
  onClose: Function;
}

const CartBody = ({ onCartRemove, onClose }: IProps): ReactElement => {
  const { t } = useTranslation();
  const plans = useSelector(getCartPlans);
  const rateModeType = useSelector(getRateModeType);

  const nonParentPlanType = Object.keys(plans).filter(planType => planType !== PlanTypes.PARENT)?.[0];

  const nonParentPlan = plans?.[nonParentPlanType];
  const nonParentDetails = userRepresentableDetails(nonParentPlan?.memberDetails as IHealthFamilyDetails, false, t);

  const parentPlan = plans?.[PlanTypes.PARENT];
  const parentsDetails = userRepresentableDetails(parentPlan?.memberDetails as IHealthFamilyDetails, true, t);

  return (
    <>
      {nonParentPlan || parentPlan ? (
        <div className="cmw-listing">
          {nonParentDetails?.value && nonParentPlan && (
            <div className="cmw-item">
              <div className="fs-12px fw-medium">{t("health_plan_cart_plan_selected_for")}</div>
              <div>{nonParentDetails.value}</div>
              <PlanCard
                data={nonParentPlan}
                rateModeType={rateModeType}
                onRemove={() => onCartRemove(nonParentPlan.plan.id as number, nonParentPlan.cartID.toString(), nonParentDetails.planType)}
              />
            </div>
          )}

          {parentsDetails.value && parentPlan && (
            <div className="cmw-item">
              <div className="fs-12px fw-medium">{t("health_plan_cart_plan_selected_for")}</div>
              <div>{parentsDetails.value}</div>
              <PlanCard
                data={parentPlan}
                rateModeType={rateModeType}
                onRemove={() => onCartRemove(parentPlan.plan.id as number, parentPlan.cartID.toString(), parentsDetails.planType)}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="cmw-empty">
          <img
            src="/assets/img/empty-cart.svg"
            alt="empty cart"
          />
          {t("empty_cart_body_message")}
          <Button
            size="lg"
            onClick={() => onClose()}
          >
            {t("close")}
          </Button>
        </div>
      )}
    </>
  );
};

export default CartBody;
