import React, { lazy } from "react";

const PaymentProcess = lazy(() => import("../../modules/payment/process"));

export const paymentRoutes = [
  {
    path: "/payment/process",
    element: <PaymentProcess />
  }
];
