import React, { lazy } from "react";

const Life = lazy(() => import("../../modules/life/container/home"));
const LifePlans = lazy(() => import("../../modules/life/container/planListing"));

const LifePlansDetail = lazy(() => import("../../modules/life/container/planDetails"));
const LifePlansCompare = lazy(() => import("../../modules/life/container/compare"));

const LifeOrderDetailsDocumentUpload = lazy(() => import("../../modules/life/container/orderDetails/document-upload"));
const LifeOrderDetailsPaymentDetails = lazy(() => import("../../modules/life/container/orderDetails/payment-details"));
const LifeOrderDetailsPersonalDetails = lazy(() => import("../../modules/life/container/orderDetails/personal-details"));
const LifeOrderDetailsReviewDetails = lazy(() => import("../../modules/life/container/orderDetails/review-details"));

export const lifeRoutes = [
  {
    path: "/life",
    element: <Life />
  },
  {
    path: "life/plans/:quotationID",
    element: <LifePlans />
  },
  {
    path: "/life/plans/:quotationID/detail/:id",
    element: <LifePlansDetail />
  },
  {
    path: "/life/plans/:quotationID/compare/:id",
    element: <LifePlansCompare />
  },
  {
    path: "/life/order-details/:quotationID/:cartID/document-upload",
    element: <LifeOrderDetailsDocumentUpload />
  },
  {
    path: "/life/order-details/:quotationID/:cartID/payment-details",
    element: <LifeOrderDetailsPaymentDetails />
  },
  {
    path: "/life/order-details/:quotationID/:cartID/personal-details",
    element: <LifeOrderDetailsPersonalDetails />
  },
  {
    path: "/life/order-details/:quotationID/:cartID/review-details",
    element: <LifeOrderDetailsReviewDetails />
  }
];
