import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Minus = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path
        d="M610,610h316c53.9,0,98-44.1,98-98l0,0c0-53.9-44.1-98-98-98H610H414H98c-53.9,0-98,44.1-98,98l0,0c0,53.9,44.1,98,98,98
	h316H610z"
      />
    </svg>
  );
};
