import React, { lazy } from "react";

const CustomerDashboard = lazy(() => import("../../modules/customer/container/dashboard/dashboard"));
const CustomerPolicy = lazy(() => import("../../modules/customer/container/policy/policy"));
const CustomerProfile = lazy(() => import("../../modules/customer/container/profile/profile"));
const CustomerPolicyDetails = lazy(() => import("../../modules/customer/container/policyDetails/policyDetails"));

export const customerRoutes = [
  {
    path: "customer/dashboard",
    element: <CustomerDashboard />
  },
  {
    path: "customer/policy/details/:orderID",
    element: <CustomerPolicyDetails />
  },
  {
    path: "customer/policy",
    element: <CustomerPolicy />
  },
  {
    path: "customer/profile",
    element: <CustomerProfile />
  },
  {
    path: "customer",
    element: <CustomerDashboard />
  }
];
