import { useMemo } from "react";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";

interface Params {
  [key: string]: string;
}

const useQuery = (): { params: Params; setSearchParams: (params: URLSearchParamsInit) => void } => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params: Params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return useMemo(() => ({ params, setSearchParams }), [searchParams]);
};

export default useQuery;
