import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Email = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M185.584 728.605l252.903-216.78-253.142-216.924-3.301 1.292c0 12.009 0 23.922 0 36.074 0.006 0.324 0.009 0.705 0.009 1.088 0 5.441-0.684 10.722-1.97 15.763l0.095-0.44c-2.934 8.94-11.204 15.282-20.955 15.282-0.792 0-1.575-0.042-2.345-0.124l0.096 0.008c-10.909-0.516-19.667-9.060-20.52-19.828l-0.005-0.075c-0.296-4.997-0.464-10.84-0.464-16.723 0-13.587 0.899-26.963 2.64-40.074l-0.167 1.537c7.82-41.195 43.296-72.004 86.063-72.531l0.056-0.001c2.966 0 5.933 0 8.899 0h693.353c1.867-0.138 4.044-0.216 6.24-0.216 39.909 0 73.773 25.881 85.723 61.775l0.184 0.638c3.035 9.405 4.785 20.225 4.785 31.454 0 0.043 0 0.087 0 0.13v-0.006q0.479 201.614 0 403.276c0.043 1.048 0.068 2.277 0.068 3.512 0 44.478-31.944 81.496-74.142 89.361l-0.562 0.087c-5.506 0.954-11.847 1.499-18.314 1.499-1.333 0-2.66-0.023-3.981-0.069l0.192 0.005c-231.469 0-462.985-0.383-694.454 0.383-1.204 0.056-2.615 0.088-4.034 0.088-45.654 0-83.605-32.979-91.332-76.412l-0.083-0.561c-1.031-9.527-1.62-20.577-1.62-31.765 0-7.156 0.241-14.255 0.715-21.29l-0.051 0.953c1.191-10.365 9.576-18.437 19.981-19.134l0.065-0.003c0.794-0.101 1.712-0.158 2.643-0.158 9.599 0 17.772 6.105 20.847 14.644l0.049 0.154c1.249 4.363 1.967 9.375 1.967 14.554 0 0.266-0.002 0.531-0.006 0.797l0.001-0.040c0.287 12.439 0 24.927 0 37.414zM218.644 262.558c4.449 4.067 6.842 6.316 9.282 8.421l333.424 285.149c4.415 5.166 10.937 8.42 18.219 8.42 5.27 0 10.142-1.704 14.095-4.592l-0.068 0.047c4.443-3.315 8.341-6.56 12.061-9.994l-0.099 0.091 271.801-232.473c20.717-17.75 41.385-35.548 64.158-55.068zM218.404 760.995h723.112l-255.677-219.268-31.146 27.032c-10.334 8.995-20.477 18.228-31.050 26.936-11.567 10.304-26.901 16.601-43.705 16.601s-32.138-6.296-43.771-16.658l0.066 0.058c-5.406-4.497-10.621-9.234-15.932-13.827l-46.265-40.045zM976.73 293.034l-255.343 218.838 255.343 218.79z"></path>
      <path d="M124.104 591.723c-33.491 0-66.647 0-99.994 0-0.313 0.015-0.681 0.024-1.050 0.024-12.736 0-23.061-10.325-23.061-23.061s10.325-23.061 23.061-23.061c0.369 0 0.737 0.009 1.102 0.026l-0.051-0.002q100.472 0 200.944 0c0.354-0.019 0.768-0.030 1.184-0.030 12.763 0 23.108 10.346 23.108 23.108s-10.346 23.108-23.108 23.108c-0.804 0-1.599-0.041-2.383-0.121l0.098 0.008c-33.203 0-66.551 0-99.85 0z"></path>
      <path d="M158.361 478.19c-21.099 0-42.246 0-63.346 0-16.841 0-28.037-9.952-27.558-23.922s11.339-22.295 27.749-22.343q63.92 0 127.791 0c16.745 0 26.697 8.755 26.649 23.061s-10.334 23.013-26.84 23.108c-21.434 0.191-42.964 0.096-64.446 0.096z"></path>
    </svg>
  );
};
