import React, { ReactElement } from "react";

export const Linkedin = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M512 1019.078c-88.615 0-172.308 0-260.923 0-64 0-128-24.615-172.308-68.923-49.231-44.308-78.769-108.308-78.769-177.231 0-88.615 0-172.308 0-260.923 0-83.693 0-172.308 0-256 0-64 19.692-128 64-177.231 39.385-39.385 88.615-68.923 142.769-73.846 19.692-4.923 39.385-4.923 59.077-4.923 167.384 0 334.769 0 502.154 0 64 0 132.923 24.615 177.231 68.923 39.385 34.462 64 78.769 73.846 132.923 4.923 19.692 4.923 39.385 4.923 59.077 0 172.308 0 344.615 0 511.999 0 113.231-73.846 206.769-177.231 241.231-24.615 4.923-49.231 9.846-78.769 9.846-83.693-4.923-172.308-4.923-256-4.923v0zM989.539 512.001v0c0-88.615 0-177.231 0-270.769 0-78.769-49.231-152.616-123.076-182.154-34.462-14.77-73.846-24.615-113.231-19.692-147.692 0-300.307 0-448 0-29.538 0-64 0-93.538 4.923-103.385 14.77-177.231 103.385-172.308 206.769 0 172.308 0 349.538 0 521.846-4.923 113.231 88.615 206.769 206.769 211.692 0 0 4.923 0 4.923 0 172.308 0 349.538 0 521.846 0 24.615 0 49.231-4.923 73.846-9.846 83.693-29.538 142.769-113.231 142.769-201.846-4.923-88.615 0-177.231 0-260.923v0z"></path>
      <path d="M408.615 388.925h132.923c4.923 19.692 0 39.385 4.923 59.077 0-4.923 4.923-4.923 4.923-4.923 19.692-29.538 49.231-49.231 83.693-59.077 39.385-9.846 78.769-4.923 113.231 4.923 39.385 14.77 68.923 44.308 83.693 88.615 9.846 29.538 14.77 59.077 14.77 88.615 0 88.615 0 177.231 0 265.846 0 4.923 0 9.846 0 9.846-44.308 0-93.538 0-137.846 0 0-9.846 0-14.77 0-19.692 0-68.923 0-132.923 0-201.846 0-19.692 0-39.385-4.923-59.077 0-9.846-4.923-19.692-9.846-29.538-4.923-14.77-19.692-24.615-34.462-29.538-19.692-4.923-39.385-4.923-59.077 4.923-19.692 4.923-29.538 19.692-39.385 39.385s-9.846 44.308-9.846 64c0 64 0 128 0 196.923v34.462c-49.231 0-93.538 0-137.846 0-4.923-14.77-9.846-418.461-4.923-452.923z"></path>
      <path d="M315.077 841.847h-132.923c-4.923-14.77-4.923-433.231 0-448h137.846c0 9.846 0 428.308-4.923 448z"></path>
      <path d="M329.847 246.155c0 44.308-34.462 78.769-78.769 78.769 0 0 0 0 0 0-49.231 0-83.693-34.462-83.693-78.769s39.385-83.693 83.693-83.693c44.308 4.923 78.769 39.385 78.769 83.693z"></path>
    </svg>
  );
};
