import { Trash } from "../../icons";
import { ILifePlanCardService, ILifePlanListingCard } from "../../../../modules/life/utils/interfaces";
import { RateModeTypes } from "../../../constant";
import { useTranslation } from "react-i18next";
import React, { MouseEventHandler, ReactElement } from "react";

interface IProps {
  data: {
    plan: ILifePlanListingCard;
    cartPlanServices: ILifePlanCardService[];
  };
  rateModeType: string;
  onRemove: MouseEventHandler;
}

const PlanCard = ({ data, onRemove, rateModeType }: IProps): ReactElement => {
  const { planName, pricing, takafulProvider } = data?.plan ?? {};
  const selectedServices = data?.cartPlanServices;
  const { t } = useTranslation();

  return (
    <>
      <div className="cmw-head">
        <figure className="cmw-fig">
          <img
            src={takafulProvider?.logo}
            alt={takafulProvider?.name}
          />
        </figure>
        {/* <a
          className="cmw-remove"
          data-testid="remove-button"
          onClick={onRemove}
        >
          <Trash />
        </a> */}
      </div>
      <div className="cmw-content">
        <div className="row g-0 justify-content-between">
          <div className="col-auto">{t("health_plan_cart_plan_name")}</div>
          <div className="col-auto text-end">{planName}</div>
        </div>
        <div className="row g-0 justify-content-between fw-semibold">
          <div className="col-auto">
            {rateModeType === RateModeTypes.MONTHLY
              ? t("health_plan_detail_pricing_monthly_contribution_heading")
              : t("health_plan_detail_pricing_annual_contribution_heading")}
          </div>
          <div className="col-auto text-end">
            {rateModeType === RateModeTypes.MONTHLY ? pricing.remainingMonthInstallment : pricing?.listingPrice}
            <small className="fs-12px d-block fw-normal">{t("health_plan_cart_inclusive_of_taxes")}</small>
          </div>
        </div>

        {rateModeType === RateModeTypes.MONTHLY && (
          <>
            <div className="row g-0 justify-content-between">
              <div className="col-auto">{t("health_plan_cart_details_remaining_first_month")}</div>
              <div className="col-auto text-end">{`1 X ${pricing?.firstMonthInstallment}`}</div>
            </div>
            <div className="row g-0 justify-content-between">
              <div className="col-auto">{t("health_plan_listing_card_twelve_months")}</div>
              <div className="col-auto text-end">{`11 X ${pricing?.remainingMonthInstallment}`}</div>
            </div>
          </>
        )}

        {!!selectedServices?.length && (
          <>
            <div className="fs-18px fw-semibold mt-3 mb-2">{t("services")}</div>
            {selectedServices?.map(({ name, pricing }) => (
              <div
                key={name}
                className="row g-0 justify-content-between"
              >
                <div className="col-auto">{name}</div>
                <div className="col-auto text-end">{pricing?.listingPrice}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default PlanCard;
