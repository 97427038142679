import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Jpeg = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path
        fill="#e1e4e6"
        d="M900.824 1023.998h-647.71c-3.084-3.084-6.169 0-12.337 0-30.843-6.169-52.434-30.843-52.434-61.687 0-18.506 0-107.952 0-126.457 0-3.084 0-9.253 3.084-12.337 6.169-3.084 12.337-3.084 18.506-3.084 188.145 0 376.289 0 567.518 0 6.169 0 9.253 0 15.422 0 15.422 3.084 30.843-9.253 33.927-24.674 0-3.084 0-6.169 0-9.253 0-6.169 0-15.422 0-21.59 0-101.783 0-135.71 0-237.493 0-6.169 0-12.337 0-15.422 0-15.422-12.337-27.759-27.759-27.759 0 0 0 0 0 0-6.169 0-9.253 0-15.422 0-191.229 0-379.373 0-570.602 0-6.169 0-15.422 0-21.59-3.084-3.084-3.084-3.084-9.253-3.084-12.337 0-132.626 0-265.253 0-400.964 0-6.169 0-9.253 3.084-15.422 3.084-30.843 33.927-52.434 64.771-52.434 77.109 0 154.217 0 231.325 0 70.94 0 138.795 0 209.735 0 3.084 0 6.169 0 9.253 0 0 6.169 3.084 9.253 3.084 15.422 0 52.434 0 107.952 0 160.386-6.169 37.012 18.506 70.94 55.518 74.024 0 0 0 0 3.084 0 6.169 3.084 9.253 6.169 12.337 9.253 58.603 58.603 117.205 117.205 172.723 172.723 6.169 3.084 9.253 9.253 9.253 15.422 0 3.084 0 9.253 0 12.337 0 163.47 0 326.939 0 487.325 0 21.59-6.169 40.096-21.59 55.518-9.253 9.253-18.506 12.337-27.759 12.337-6.169 9.253-9.253 6.169-12.337 9.253z"
      ></path>
      <path
        fill="#edeff0"
        d="M910.077 1020.913c0 0 0 3.084 0 0l-9.253 3.084c3.084-3.084 6.169-3.084 9.253-3.084z"
      ></path>
      <path
        fill="#e51833"
        d="M191.427 863.612c-30.843 0-61.687 0-92.53 0-18.506 3.084-33.927-9.253-37.012-27.759 0-3.084 0-6.169 0-6.169 0-104.867 0-206.65 0-311.518-3.084-18.506 12.337-33.927 27.759-37.012 3.084 0 6.169 0 6.169 0 30.843 0 61.687 0 92.53 0 3.084 0 9.253 0 12.337 0h586.024c27.759 0 40.096 12.337 40.096 40.096 0 101.783 0 200.482 0 302.265 0 6.169 0 9.253 0 15.422 0 18.506-15.422 30.843-33.927 30.843 0 0 0 0 0 0-3.084 0-9.253 0-12.337 0-191.229 0-382.458 0-573.687 0 0 0-9.253-3.084-15.422-6.169z"
      ></path>
      <path
        fill="#afb6bc"
        d="M706.511 3.083l252.915 252.915c-3.084 6.169-9.253 6.169-12.337 6.169-18.506 0-37.012 0-55.518 0-33.927 0-67.856 0-104.867 0-6.169 0-12.337 0-18.506-3.084-37.012 0-64.771-30.843-64.771-64.771 0 0 0 0 0 0 0-58.603 0-117.205 0-175.807 0-9.253 0-12.337 3.084-15.422z"
      ></path>
      <path
        fill="#c9d0d7"
        d="M768.198 255.999c58.603 0 120.289 0 178.892 0 3.084 0 9.253 0 12.337-3.084 0 3.084 0 6.169 0 9.253 0 61.687 0 123.373 0 185.060-3.084 0-3.084-3.084-6.169-3.084-61.687-61.687-120.289-120.289-181.976-181.976 0 0-3.084-3.084-3.084-6.169z"
      ></path>
      <path
        fill="#fff"
        d="M345.643 576.769v135.71c0 18.506-6.169 33.927-15.422 43.18s-24.674 15.422-43.18 15.422c-18.506 0-33.927-6.169-46.265-15.422s-15.422-24.674-15.422-46.265h40.096c0 9.253 3.084 15.422 6.169 18.506 3.084 6.169 9.253 6.169 15.422 6.169s12.337-3.084 15.422-6.169c3.084-3.084 6.169-9.253 6.169-18.506v-135.71h37.012z"
      ></path>
      <path
        fill="#fff"
        d="M515.282 666.215c-6.169 9.253-12.337 15.422-21.59 21.59s-24.674 9.253-40.096 9.253h-33.927v74.024h-40.096v-194.313h70.94c15.422 0 27.759 3.084 37.012 9.253s18.506 12.337 24.674 21.59c6.169 9.253 9.253 18.506 9.253 30.843 0 9.253-3.084 18.506-6.169 27.759zM475.186 656.961c6.169-6.169 9.253-12.337 9.253-21.59 0-18.506-9.253-27.759-30.843-27.759h-30.843v55.518h30.843c6.169 0 15.422 0 21.59-6.169z"
      ></path>
      <path
        fill="#fff"
        d="M672.584 635.371c-3.084-9.253-9.253-15.422-18.506-18.506s-15.422-6.169-27.759-6.169c-12.337 0-21.59 3.084-30.843 9.253s-15.422 12.337-21.59 21.59c-6.169 9.253-6.169 21.59-6.169 33.927s3.084 24.674 6.169 33.927c6.169 9.253 12.337 18.506 21.59 21.59 9.253 6.169 18.506 9.253 30.843 9.253 15.422 0 27.759-3.084 37.012-12.337s15.422-18.506 18.506-33.927h-67.856v-30.843h104.867v33.927c-3.084 12.337-9.253 24.674-15.422 37.012-9.253 12.337-18.506 21.59-33.927 27.759-12.337 6.169-27.759 9.253-46.265 9.253s-37.012-3.084-49.349-12.337c-15.422-9.253-27.759-21.59-37.012-33.927-9.253-15.422-12.337-30.843-12.337-52.434 0-18.506 3.084-37.012 12.337-52.434s21.59-27.759 37.012-33.927c15.422-9.253 30.843-12.337 49.349-12.337 21.59 0 40.096 6.169 55.518 15.422s27.759 24.674 33.927 46.265h-40.096z"
      ></path>
    </svg>
  );
};
