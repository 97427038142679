import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Claims = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M722.693 0c62.903 62.903 125.807 125.807 188.282 188.71 4.28 4.707 6.846 11.126 7.275 17.545 0.856 33.805 0.427 67.182 0.427 100.988 0 11.553-5.563 18.4-14.549 18.828-8.986 0-15.405-6.846-15.405-19.256 0-26.103 0-51.778 0-77.88v-10.698h-11.553c-37.228 0-74.457 0-112.113 0-33.805 1.712-62.475-24.391-64.187-57.768 0-2.139 0-3.851 0-5.99 0-37.228 0-74.457 0-112.113v-11.553h-432.621c-26.103 0-37.657 11.553-37.657 37.657v793.352c0 26.958 11.126 38.084 38.084 38.084h581.963c26.531 0 37.657-11.126 37.657-37.657 0-158.756 0-317.94 0-476.695 0-13.693 5.135-20.112 15.405-19.684 7.275 0 13.693 5.563 14.121 12.838 0 2.139 0 4.707 0 6.846 0 160.467 0 321.363 0 481.831 1.283 30.81-20.968 57.341-51.349 61.619-11.982 0.856-23.963 1.283-35.945 0.856-2.139 0-3.851 0-6.846 0.427 0 11.126 0 21.396 0 32.094 0.856 32.95-25.247 60.336-58.196 61.619-0.856 0-1.712 0-2.995 0-130.941 0-261.884 0-392.825 0-11.982 0-18.4-5.135-18.828-14.549s6.419-15.405 18.828-15.405h385.979c26.958 0 38.084-11.126 38.084-38.513v-25.675h-12.838c-171.593 0-343.186-0.427-514.78 0.427-35.945 0.856-65.471-26.958-66.754-62.903 0-1.283 0-2.568 0-3.851 0.856-241.771 0.427-483.114 0.427-724.885v-13.265c-13.693-0.856-26.958-0.427-40.652 0.427-13.265 2.139-23.107 13.693-23.107 27.387 0 2.995 0 5.99 0 8.986v793.78c0 26.531 11.126 37.657 37.228 37.657h118.96c11.553 0 18.828 5.563 18.828 14.549s-6.846 15.405-19.256 15.405c-41.935 0-83.872 0-126.234 0-32.521 0.427-59.908-25.675-60.336-58.196 0-0.427 0-1.283 0-1.712 0-270.013 0-540.027 0-809.613 0-32.95 26.531-59.48 59.052-59.908 0 0 0.427 0 0.427 0 11.126 0 21.824 0 35.089 0-4.707-41.508 0-79.164 47.498-94.141h475.412zM862.194 186.57l-130.085-129.658c0 33.805-0.856 69.75 0.427 105.695 1.283 14.977 14.549 25.675 29.098 24.391 32.521 0.427 65.043 0 97.992 0 0.856 0 1.712 0 2.568-0.427v0z"></path>
      <path d="M824.964 538.315c0.427 94.141-75.741 170.31-169.881 170.737s-170.31-75.741-170.737-169.881c-0.427-94.141 75.741-170.31 169.881-170.737 93.713 0 170.31 76.168 170.737 169.881zM655.083 679.099c77.024-0.427 139.072-63.331 138.644-140.355s-63.331-139.072-140.355-138.644c-77.024 0.427-139.072 62.903-138.644 139.928 0 77.024 63.331 139.5 140.355 139.072v0z"></path>
      <path d="M579.342 780.087c-75.313 0-150.626 0-225.939-0.427-5.99 0-11.982-2.139-16.689-6.419-5.135-5.99-3.851-15.405 2.139-20.112 2.139-1.712 4.707-2.995 7.702-2.995 2.139 0 3.851 0 5.99 0 151.054 0 302.107 0 452.733 0 8.986 0 16.261 2.139 18.4 11.126 2.139 7.702-2.139 15.833-10.27 18.4-1.712 0.427-3.851 0.856-5.99 0.427-27.387 0-54.773 0-82.16 0h-145.918z"></path>
      <path d="M579.77 298.256h222.943c3.424-0.427 6.846 0 9.842 0.427 7.702 0.856 13.265 8.131 12.409 15.833 0 7.275-5.563 13.693-13.265 13.693-3.851 0.427-7.275 0.427-11.126 0.427h-442.891c-3.424 0-6.846 0-9.842-0.427-7.702 0-14.121-6.419-13.693-14.121 0 0 0 0 0 0-0.427-8.131 5.563-14.977 13.693-15.405 2.995 0 5.99 0 8.986 0l222.943-0.427z"></path>
      <path d="M395.34 441.607c-14.121 0-28.67 0-42.791 0-11.553 0-18.4-5.99-18.4-14.977s6.419-15.405 17.972-15.405c29.098-0.427 58.624-0.427 87.723 0 11.553 0 17.972 6.419 17.545 15.833s-6.846 14.549-17.972 14.549c-14.977 0-29.526 0-44.076 0z"></path>
      <path d="M395.34 636.735c14.121 0 28.67 0 42.791 0 12.409 0 18.828 5.99 18.828 15.833-0.427 9.842-6.846 14.977-18.828 14.977-28.67 0-57.341 0-86.011 0-11.982 0-19.256-6.419-18.828-15.833s6.846-14.549 18.828-14.977c14.549-0.427 29.098 0 43.22 0z"></path>
      <path d="M395.34 554.148c-14.977 0-29.954 0-44.93 0-8.131 0.856-15.833-5.135-16.689-13.265s5.135-15.833 13.265-16.689c0.856 0 1.712 0 2.995 0 30.382 0 60.336 0 90.718 0 8.131-0.856 15.405 5.135 16.261 13.265 0 0.427 0 1.283 0 1.712 0 9.414-6.419 14.977-17.116 14.977-14.977 0-29.526 0-44.503 0z"></path>
      <path d="M670.488 504.938c0 20.112 0 40.652 0 60.764 0 11.982-5.99 19.256-15.405 19.256s-15.405-7.275-15.405-19.256c0-41.079 0-81.731 0-122.812 0-11.982 6.419-19.256 15.833-18.828s14.977 7.275 14.977 18.828c0 20.54 0 41.508 0 62.048z"></path>
      <path d="M654.227 608.921c8.558 0 14.977 6.846 14.977 15.405s-6.846 14.977-15.405 14.977c-8.558 0-14.977-6.846-14.977-15.405 0 0 0 0 0 0 0-8.558 6.846-15.405 15.405-14.977 0 0 0 0 0 0z"></path>
    </svg>
  );
};
