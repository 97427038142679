import React, { ReactElement } from "react";

export const Share = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M808.66 0c7.547 2.516 16.354 3.774 23.902 5.032 89.317 23.902 142.152 116.993 118.251 206.31-17.612 64.157-71.705 111.96-137.12 122.024-50.319 7.547-101.897-8.806-139.636-44.030l-7.547-7.547c0 0-1.258 0-1.258-1.258l-275.499 171.086c15.096 38.998 15.096 83.027 0 122.024l275.499 171.086c6.29-5.032 11.322-10.064 16.354-15.096 70.448-60.383 176.118-52.835 236.502 17.612s52.835 176.118-17.612 236.502c-25.16 21.385-55.352 35.223-86.801 38.998-2.516 0-3.774 1.258-5.032 1.258h-36.481c-17.612-3.774-35.223-8.806-52.835-16.354-76.737-35.223-115.735-122.024-90.575-202.535 3.774-6.29 1.258-13.838-5.032-16.354 0 0-1.258 0-1.258-1.258-86.801-52.835-173.601-106.929-259.145-161.022l-6.29-3.774c-37.739 41.514-94.349 61.641-150.958 52.835-40.256-3.774-76.737-25.16-103.155-57.867-55.352-67.931-49.061-167.312 13.838-226.437 67.931-64.157 174.859-61.641 239.017 6.29 0 1.258 1.258 1.258 1.258 2.516l33.965-21.385c77.995-49.061 155.99-96.864 235.243-144.668 5.032-1.258 7.547-6.29 5.032-11.322 0 0 0-1.258 0-1.258-28.934-88.059 17.612-182.408 105.671-212.599 10.064-3.774 20.127-6.29 31.45-7.547 1.258 0 2.516-1.258 5.032-1.258h35.223zM791.048 963.617c59.125 0 106.929-47.803 106.929-105.671 0-59.125-47.803-106.929-105.671-106.929-59.125 0-106.929 46.545-106.929 105.671-1.258 59.125 46.545 106.929 105.671 106.929-1.258 0 0 0 0 0zM791.048 60.383c-59.125 0-106.929 47.803-106.929 106.929s47.803 106.929 106.929 106.929c59.125 0 105.671-47.803 106.929-105.671 0-59.125-47.803-106.929-106.929-108.187 0 1.258 0 1.258 0 0v0zM126.832 512c0 59.125 47.803 106.929 106.929 106.929s106.929-47.803 106.929-106.929c0-59.125-47.803-106.929-106.929-106.929 0 0 0 0 0 0-60.383 1.258-106.929 49.061-106.929 106.929v0z"></path>
    </svg>
  );
};
