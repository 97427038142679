import { IHouseHoldCartPlans } from "../../../../modules/houseHold/utils/interfaces";
import { RootState } from "../../../store";
import { createSlice } from "@reduxjs/toolkit";

interface IHouseHoldCart {
  plans: IHouseHoldCartPlans;
  totalContribution: string;
  currentPayable: string;
  isCartbarOpen: boolean;
  rateModeType: string;
}

const initialState: IHouseHoldCart = {
  plans: {},
  totalContribution: "",
  currentPayable: "",
  rateModeType: "",
  isCartbarOpen: false
};

// eslint-disable-next-line import/no-unused-modules
export const houseHoldCart = createSlice({
  name: "houseHoldCart",
  initialState,
  reducers: {
    AddPlanInCart: (state, { payload }) => {
      state.plans[payload.planType] = payload.planDetails;
      state.totalContribution = payload.totalContribution;
      state.currentPayable = payload.currentPayable;
      state.rateModeType = payload.rateModeType;
    },
    RemovePlanCart: (state, { payload }) => {
      const addedPlanTypes = Object.keys(state.plans);

      const newCart: IHouseHoldCartPlans = {};
      addedPlanTypes.forEach(planType => {
        const isRemovingPlan = planType === payload;
        if (!isRemovingPlan) {
          newCart[planType as keyof typeof newCart] = state.plans[planType];
        }
      });

      state.plans = newCart;
    },
    updateCartBarStatus: (state, { payload }) => {
      state.isCartbarOpen = payload;
    },
    clearCartPlans: state => {
      state.plans = {};
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars, no-param-reassign
    ResetHouseHoldCart: state => (state = initialState)
  }
});

export const { AddPlanInCart, RemovePlanCart, updateCartBarStatus, clearCartPlans, ResetHouseHoldCart } = houseHoldCart.actions;

export const getCartPlans = (state: RootState): IHouseHoldCartPlans => state.houseHoldCart.plans;

export const getRateModeType = (state: RootState): string => state.houseHoldCart.rateModeType;

export const getCurrentPayable = (state: RootState): string => state.houseHoldCart.currentPayable;

export const getCartBarStatus = (state: RootState): boolean => state.houseHoldCart.isCartbarOpen;

export default houseHoldCart.reducer;
