// /api/v2/web-leads/sme
import React, { ReactElement, useState } from "react";
import { Container, Row } from "react-bootstrap";
import NewLeadDetails from "../newLeadForm";
import CoverageLives from "../coverageLives";
import SmeBanner from "../smeBanner";
import Thankyou from "../thankyou";

export default function Banner(): ReactElement {
  // Step state to control navigation
  const [step, setStep] = useState<"SME_BANNER" | "COVERAGE_LIVES" | "LEAD_DETAILS" | "THANK_YOU">("SME_BANNER");
  const [indivisual, setIndivisual] = useState(0);
  const [limitRs, setlimitRs] = useState(0);
  const [total, setTotal] = useState(0);

  const handleNextStep = res => {
    setIndivisual(isNaN(res)?indivisual:res);
    setlimitRs(res);
    console.log("TRRRRRRR", res);

    if (step === "SME_BANNER") {
      setStep("LEAD_DETAILS");
    } else if (step === "LEAD_DETAILS") {
      setStep("COVERAGE_LIVES");
    } else if (step === "COVERAGE_LIVES") {
      setStep("THANK_YOU");
    }
  };

  const handlePreviousStep = () => {
    if (step === "COVERAGE_LIVES") {
      setStep("LEAD_DETAILS");
    } else if (step === "LEAD_DETAILS") {
      setStep("SME_BANNER");
    } 
  };

  return (
    <div>
      <Row>
        {/* Render components based on the current step */}
        {step === "SME_BANNER" ? (
          <SmeBanner onNext={(res) => {
            window.scrollTo(0, 0);
            handleNextStep(res);
          }} />
        ) : step === "LEAD_DETAILS" ? (
          <NewLeadDetails
            onNext={(res) => {
              window.scrollTo(0, 0);
              handleNextStep(res);
            }}
            onBack={() => {
              window.scrollTo(0, 0);
              handlePreviousStep();
            }}
          />
        ) : step === "COVERAGE_LIVES" ? (
          <CoverageLives
            indivisual={indivisual}
            limitRs={limitRs}
            setTotal={setTotal}
            total={total}
            onBack={() => {
              window.scrollTo(0, 0);
              handlePreviousStep();
            }}
            onNext={(res) => {
              window.scrollTo(0, 0);
              handleNextStep(res);
            }}
          />
        ) : (
          <Thankyou
            onNext={(res) => {
              window.scrollTo(0, 0);
              handleNextStep(res);
            }}
            onBack={() => {
              window.scrollTo(0, 0);
              handlePreviousStep();
            }}
          />
        )}
      </Row>
    </div>
  );
}
