import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const ArrowBottom = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M895.82 238.815l-383.392 368.514-383.392-367.37c-14.878-14.878-33.189-21.745-53.789-21.745s-38.911 8.011-53.789 21.745c-28.611 28.611-28.611 78.967 0 107.579l437.181 436.037c3.433 3.433 6.867 6.867 11.445 9.156l5.722 3.433c2.289 1.144 4.578 2.289 8.011 4.578 2.289 1.144 5.722 2.289 8.011 2.289l5.722 1.144c10.3 2.289 19.456 2.289 29.756 0l5.722-1.144c2.289-1.144 5.722-1.144 9.156-3.433 2.289-1.144 4.578-2.289 6.867-3.433l4.578-2.289c4.578-2.289 8.011-5.722 11.445-9.156l437.181-437.181c14.878-14.878 21.745-34.334 21.745-53.789s-6.867-38.911-21.745-53.789c-14.878-14.878-33.189-22.889-53.789-21.745-19.456-1.144-38.911 6.867-52.645 20.6z"></path>
    </svg>
  );
};
