import React, { ReactElement } from "react";

export const Facebook = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M514.473 1024c-89.043 0-178.087 0-262.184 0-98.937 0-183.034-54.415-227.556-143.459-14.841-29.681-24.734-69.256-24.734-103.884 0-178.087 0-351.227 0-529.314 0-98.937 59.362-192.928 153.353-227.556 29.681-9.894 59.362-19.787 84.097-19.787 89.043 0 183.034 0 272.077 0s178.087 0 267.13 0c93.99 0 178.087 49.469 222.609 128.618 19.787 34.628 29.681 74.203 29.681 118.725 0 178.087 0 356.174 0 529.314 0 93.99-54.415 183.034-143.459 222.609-34.628 14.841-69.256 24.734-108.831 24.734h-262.184zM697.507 623.304v366.068c4.947 0 4.947 0 9.894 0 29.681 0 54.415 0 84.097 0 103.884-4.947 187.981-84.097 197.874-183.034 4.947-29.681 4.947-64.309 0-93.99 0-153.353 0-306.705 0-460.058 0-64.309-29.681-123.671-79.15-163.246-39.575-34.628-93.99-49.469-148.406-49.469-173.14 0-346.28 0-519.42 0-108.831-4.947-202.821 89.043-202.821 207.768 0 0 0 0 0 4.947 0 173.14 0 351.227 0 524.367 0 24.734 4.947 49.469 14.841 74.203 29.681 84.097 108.831 138.512 197.874 138.512 93.99 0 192.928 0 286.918 0 4.947 0 9.894 0 9.894 0 0-118.725 0-242.396 0-361.121-44.522 0-84.097 0-123.671 0v-153.353h123.671c0-9.894 0-14.841 0-19.787 0-29.681 0-59.362 0-93.99 0-14.841 0-29.681 4.947-44.522 9.894-69.256 64.309-123.671 133.565-138.512 29.681-4.947 64.309-4.947 93.99-4.947 19.787 0 39.575 4.947 59.362 4.947 4.947 44.522 4.947 84.097 0 128.618-24.734 0-49.469 0-74.203 0-9.894 0-24.734 0-34.628 4.947-14.841 4.947-29.681 14.841-29.681 29.681 0 9.894-4.947 14.841-4.947 24.734 0 29.681 0 59.362 0 89.043 0 4.947 0 9.894 0 9.894h44.522c14.841 0 29.681 0 44.522 0s29.681 0 44.522 0l-19.787 143.459-113.778 14.841z"></path>
    </svg>
  );
};
