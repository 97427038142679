"use Strict";
import React, { ReactElement, useEffect } from "react";
// import WhatisPersonalAccident from "../../component/home/whatIsHealth";
import PersonalAccidentHeroBanner from "../../component/home/bannerForm";
import WhyChooseUs from "../../component/home/whyChooseUs";
// import Covered from "../../component/home/covered";
// import Partner from "../../component/home/partner";
import { useDispatch } from "react-redux";
import { ResetCounter } from "../../../../redux/features/counter/counterSlice";
import { ResetPersonalAccidentCart } from "../../../../redux/features/personalAccident/cart";
import { ResetDocuments } from "../../../../redux/features/personalAccident/order";
import { ResetUser } from "../../../../redux/features/personalAccident/userDetails";
import { showLoader } from "../../../../redux/features/loader";
import { ResetCompare } from "../../../../redux/features/personalAccident/compare";
import { Helmet } from "react-helmet-async";
import Exclusions from "../../component/home/exclusions/index.tsx";
import Faqs from "../../component/home/faqs/index.tsx";
import KeyFeatures from "../../component/home/keyFeatures/index.tsx";
import Banner from "../../component/home/banner/index.tsx";
import OurApp from "../../component/home/ourApp";
import AboutTakaful from "../../component/home/aboutTakaful/index.tsx";

export default function SMEHome(): ReactElement {
  const dispatch = useDispatch();

  const resetLocalState = () => {
    dispatch(ResetCounter());
    dispatch(ResetPersonalAccidentCart());
    dispatch(showLoader({ isLoading: false }));
    dispatch(ResetUser());
    dispatch(ResetDocuments());
    dispatch(ResetCompare());
  };

  useEffect(() => {
    resetLocalState();
  }, []);

  return (
    <div style={{overflowX:"hidden"}} className="landing-page-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Group Health Takaful | Takaful Bazaar</title>
        <link
          rel="canonical"
          href="https://health.takafulbazaar.pk/sme"
        />
        <meta
          name="description"
          content="SME"
        />
      </Helmet>
      {/* <PersonalAccidentHeroBanner /> */}
      <Banner />
      <WhyChooseUs />
      <KeyFeatures />
      <Exclusions />
      <Faqs />
      {/* <Covered /> */}
    </div>
  );
}
