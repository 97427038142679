import React, { ReactElement } from "react";

export const Telephone = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M700.056 1024c-90.164-6.44-180.327-32.201-251.17-90.164-161.006-109.484-289.811-257.61-373.535-431.497-45.082-90.164-57.962-193.208-32.201-289.811 12.881-51.522 38.642-96.604 77.283-135.245 19.321-19.321 45.082-45.082 70.843-57.962 25.761-25.761 70.843-25.761 96.604 0 0 0 0 0 6.44 6.44 51.522 51.522 90.164 115.925 115.925 186.767 12.881 38.642 0 83.723-32.201 109.484-19.321 0-38.642 19.321-57.962 32.201-38.642 19.321-51.522 64.403-32.201 103.044 12.881 32.201 25.761 64.403 45.082 90.164 45.082 51.522 90.164 109.484 141.686 154.566 19.321 19.321 45.082 32.201 70.843 45.082 32.201 19.321 70.843 6.44 96.604-19.321 19.321-19.321 38.642-38.642 57.962-51.522 25.761-25.761 70.843-32.201 109.484-12.881 64.403 32.201 122.365 83.723 167.447 141.686 25.761 25.761 19.321 64.403 0 83.723-51.522 64.403-122.365 109.484-199.648 128.805-25.761 0-51.522 0-77.283 6.44z"></path>
    </svg>
  );
};
