import React, { ReactElement } from "react";
export const PanelHospital = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.544"
    height="18.777"
    viewBox="0 0 21.544 18.777"
  >
    <g
      id="Group_44672"
      data-name="Group 44672"
      transform="translate(-256.738 -422)"
    >
      <g
        id="Group_44671"
        data-name="Group 44671"
        transform="translate(-392.78 -629.46)"
      >
        <path
          id="Path_47050"
          data-name="Path 47050"
          d="M649.518,1056.01a3.286,3.286,0,0,1,.261-.47,1.123,1.123,0,0,1,.951-.4q2.493,0,4.986,0h.263v-1.476c-.066-.006-.133-.011-.2-.02a1.1,1.1,0,0,1-.977-1.148,1.08,1.08,0,0,1,1.11-1.035q4.313,0,8.625,0a1.089,1.089,0,0,1,1.123,1,1.1,1.1,0,0,1-.9,1.166l-.169.036v1.462c.093,0,.173.013.254.013q2.482,0,4.965,0a1.65,1.65,0,0,1,.477.055,1.084,1.084,0,0,1,.19,2,.292.292,0,0,0-.187.321q.006,2.419,0,4.839c0,.337-.1.5-.318.5s-.312-.156-.312-.5q0-2.366,0-4.733v-.269H664.6v12.234h5.058v-.241q0-2.588,0-5.175c0-.336.089-.475.3-.482s.327.144.327.483q0,2.788,0,5.575c0,.413-.075.485-.494.485H650.776c-.411,0-.484-.075-.484-.5q0-6.09,0-12.18c0-.173-.018-.293-.208-.372a1.176,1.176,0,0,1-.568-.717Zm7.121-2.352v15.93h1.021v-.269q0-2.02,0-4.039c0-.4.081-.475.484-.476h4.165c.587,0,.611.024.611.622v4.165h1.021v-15.933Zm-.658,15.938v-12.238h-5.045V1069.6Zm4.246-17.5H656.4c-.175,0-.351,0-.526,0a.446.446,0,0,0-.436.405.455.455,0,0,0,.352.5.885.885,0,0,0,.208.017q4.238,0,8.475,0a.8.8,0,0,0,.286-.048.449.449,0,0,0,.267-.478.456.456,0,0,0-.4-.4,1.975,1.975,0,0,0-.21,0Zm2.046,17.5v-4.15h-1.657v4.15Zm-3.964-4.152v4.149h1.648v-4.149Zm-2.321-9.658c-.069-.005-.116-.012-.164-.013h-5.107a.98.98,0,0,0-.167.013.459.459,0,0,0,.005.91,1.408,1.408,0,0,0,.209.012h5c.073,0,.146-.009.222-.014Zm8.605.91c.082,0,.144.012.206.012h5.044a1.1,1.1,0,0,0,.229-.02.457.457,0,0,0,.016-.887,1.16,1.16,0,0,0-.271-.026q-2.5,0-5,0c-.073,0-.147.009-.223.013Z"
          transform="translate(0)"
          fill="#515151"
        />
        <path
          id="Path_47051"
          data-name="Path 47051"
          d="M933.545,1243.9c.448,0,.9,0,1.344,0,.276,0,.388.105.39.376q.007.84,0,1.68c0,.26-.114.372-.375.373q-1.355.005-2.71,0c-.256,0-.371-.121-.373-.379q-.006-.84,0-1.681c0-.255.121-.367.379-.369C932.648,1243.9,933.1,1243.9,933.545,1243.9Zm-1.085,1.8c.708,0,1.393,0,2.078,0,.037,0,.1-.058.105-.09.008-.353,0-.706,0-1.058h-2.188Z"
          transform="translate(-266.432 -181.622)"
          fill="#515151"
        />
        <path
          id="Path_47052"
          data-name="Path 47052"
          d="M933.549,1179.048c-.441,0-.882,0-1.323,0-.281,0-.4-.115-.4-.4q0-.819,0-1.639c0-.281.117-.4.4-.4q1.334,0,2.668,0c.282,0,.393.111.395.4q.005.819,0,1.639c0,.294-.112.4-.414.4C934.432,1179.049,933.99,1179.048,933.549,1179.048Zm-1.083-.647h2.174v-1.14h-2.174Z"
          transform="translate(-266.439 -118.118)"
          fill="#515151"
        />
        <path
          id="Path_47053"
          data-name="Path 47053"
          d="M933.566,1311.2c.449,0,.9,0,1.346,0,.252,0,.363.1.366.355q.008.852,0,1.7c0,.252-.109.359-.363.36q-1.367.006-2.735,0c-.245,0-.357-.119-.359-.368q-.007-.841,0-1.683c0-.258.117-.367.378-.368C932.654,1311.2,933.11,1311.2,933.566,1311.2Zm1.075.643h-2.174v1.13h2.174Z"
          transform="translate(-266.431 -245.142)"
          fill="#515151"
        />
        <path
          id="Path_47054"
          data-name="Path 47054"
          d="M799.75,1119.043c0-.287,0-.545,0-.8,0-.354.159-.512.514-.516.385,0,.771-.005,1.156,0,.339,0,.5.167.5.51,0,.259,0,.517,0,.809.269,0,.526,0,.782,0,.387,0,.531.145.532.533q0,.546,0,1.093c0,.384-.148.528-.538.529h-.776c0,.277,0,.534,0,.791,0,.373-.152.521-.526.522s-.757,0-1.135,0c-.358,0-.506-.153-.51-.516,0-.258,0-.515,0-.8-.284,0-.548,0-.812,0-.344,0-.5-.157-.5-.5q0-.578,0-1.156c0-.343.156-.494.5-.5C799.2,1119.042,799.458,1119.043,799.75,1119.043Zm.631-.678c0,.3,0,.569,0,.842,0,.38-.088.466-.472.467h-.827v.893h.832c.381,0,.466.086.467.47,0,.276,0,.553,0,.829h.909c0-.3,0-.585,0-.87,0-.328.1-.427.423-.429.292,0,.583,0,.878,0v-.893c-.3,0-.584,0-.87,0-.329,0-.429-.1-.431-.423,0-.292,0-.583,0-.886Z"
          transform="translate(-140.547 -62.536)"
          fill="#515151"
        />
        <path
          id="Path_47055"
          data-name="Path 47055"
          d="M813.531,1221.379c-.42,0-.839,0-1.259,0-.244,0-.383-.121-.379-.32s.139-.309.37-.309q1.269,0,2.539,0c.243,0,.383.124.379.322s-.14.307-.391.308C814.37,1221.38,813.95,1221.379,813.531,1221.379Z"
          transform="translate(-153.248 -159.771)"
          fill="#515151"
        />
        <path
          id="Path_47056"
          data-name="Path 47056"
          d="M813.52,1247.875c-.414,0-.827,0-1.241,0-.254,0-.385-.115-.382-.318s.131-.309.371-.31q1.272-.006,2.545,0c.24,0,.368.117.372.31s-.128.316-.382.318C814.375,1247.877,813.947,1247.875,813.52,1247.875Z"
          transform="translate(-153.252 -184.778)"
          fill="#515151"
        />
        <path
          id="Path_47057"
          data-name="Path 47057"
          d="M690.561,1243.9c.448,0,.9,0,1.344,0,.272,0,.389.111.391.379q.006.83,0,1.659c0,.272-.115.39-.382.391q-1.344.005-2.688,0c-.274,0-.385-.11-.387-.383q-.006-.84,0-1.68c0-.259.116-.365.378-.367C689.665,1243.9,690.113,1243.9,690.561,1243.9Zm-1.079,1.786h2.172v-1.144h-2.172Z"
          transform="translate(-37.108 -181.623)"
          fill="#515151"
        />
        <path
          id="Path_47058"
          data-name="Path 47058"
          d="M690.582,1179.048c-.448,0-.9,0-1.344,0-.284,0-.395-.109-.4-.393q-.005-.83,0-1.659c0-.27.116-.379.389-.38q1.334,0,2.667,0c.283,0,.4.114.4.394q.005.819,0,1.638c0,.291-.117.4-.415.4C691.45,1179.049,691.016,1179.048,690.582,1179.048Zm-1.1-1.791v1.147h2.174v-1.147Z"
          transform="translate(-37.11 -118.118)"
          fill="#515151"
        />
        <path
          id="Path_47059"
          data-name="Path 47059"
          d="M690.559,1311.2c.449,0,.9,0,1.346,0,.275,0,.388.107.39.377q.006.831,0,1.661c0,.27-.115.38-.39.381q-1.346,0-2.692,0c-.267,0-.373-.105-.375-.37q-.007-.841,0-1.682c0-.264.111-.366.375-.368C689.662,1311.2,690.111,1311.2,690.559,1311.2Zm1.089,1.776v-1.128h-2.166v1.128Z"
          transform="translate(-37.107 -245.143)"
          fill="#515151"
        />
      </g>
    </g>
  </svg>
);
