import { Container, Row, Col , Form} from "react-bootstrap";
import React, { ReactElement, useEffect, useState } from "react";
// eslint-disable-next-line import/extensions
import animationData from "../../../../../lotties/data-website.json";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ArrowLeft } from "../../../../../shared/components/icons";
import Api from "../../../../../shared/network/axiosClients";


interface CoverageLivesProps {
  onNext: () => void;
  onBack: () => void;
  indivisual: number;
  limitRs: number;
  setTotal: (total: number) => void;
  total: number;
}

export default function CoverageLives({ onNext, onBack, indivisual, limitRs, setTotal, total }: CoverageLivesProps): ReactElement {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { t } = useTranslation();
  const [temp, setTemp] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const form = useForm();
  const [hospitalizationLimit, setHospitalizationLimit] = useState<number | null>(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const coverageOptions = [
    { id: "EMPLOYEE", label: "Employee", imgSrc: "../assets/img/employeeCoverage.png" },
    { id: "EMPLOYEE_SPOUSE", label: "Employee + Spouse", imgSrc: "../assets/img/employeeSpouseCoverage.png" },
    { id: "EMPLOYEE_SPOUSE_CHILDREN", label: "Employee + Spouse and Child", imgSrc: "../assets/img/employeeSpouseKids.png" },
  ];

  const handleOptionChange = (value: string) => setSelectedOption(value);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const limit = queryParams.get("hospitalizationLimit");
    setHospitalizationLimit(limit ? limit : null); // Update the state for hospitalizationLimit
    const companyName = decodeURIComponent(queryParams.get("companyName") || "");
    const contactPerson = queryParams.get("contactPerson") || "";
    let contactNumber = queryParams.get("contactNumber") || "";
    const email = queryParams.get("email") || "";

    // Decode the query parameters and replace space with '+'
  contactNumber = decodeURIComponent(contactNumber).replace(/ /g, '+');

    reset({
      companyName,
      contactPerson,
      contactNumber,
      email,
    });
  }, [location.search, reset]);

  const watchLivesCount = watch("noOfLives", 0);

  useEffect(() => {
    setTotal(watchLivesCount * indivisual);
    setTemp(watchLivesCount);
  }, [watchLivesCount]);

  const onSubmit = async (data: any) => {
    const { noOfLives } = data;

    if (noOfLives < 5) {
      setErrorMessage("Number of lives must be at least 5");
      return;
    }

    setErrorMessage(null);
    console.log("CoverageLives", data); 
  
    // Construct payload
    const payload = {
      name: data.contactPerson,
      contactNumber: data.contactNumber,
      email: data.email?.trim(),
      additionalInformation: {
        coveredMembers: selectedOption,
        noOfLifes: data.noOfLives,
        companyName: data.companyName,
        estimatedContribution: total,
        hospitalizationLimit: hospitalizationLimit
      },
    };
  
    // Log the payload to ensure it is structured correctly
    console.log("Payload for API:", payload);
  
    // If email is empty, remove it from the payload
    if (!payload.email) {
      delete payload.email;
    }
  
    // Start loading state to indicate API request is in progress
    setLoading(true);
  
    try {
      // Make the API call
      const response = await Api.post("web-leads/sme", payload);
      console.log("API Response:", response);
  
      // Handle success response
      if (response.status === 201) {
        console.log("Navigating");
        onNext(); // Move to the next step after successful submission
      } else {
        // Handle non-201 responses
        setErrorMessage(t("common_error_message"));
      }
    } catch (error) {
      // Handle error during the API call
      console.error("Error during API call:", error);
      setErrorMessage(t("submission_error_message"));
    } finally {
      // Set loading state to false once the API request completes
      setLoading(false);
    }
  };
  

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="heroBanner-pa">
        <section className="my-health-takaful-bnr">
          <Container className="text-center px-4 px-md-0 justify-content-center">
            <div className="d-flex justify-content-center">
              <a className="hwb-back mt-1 me-4" data-testid="lead-details-form-back" onClick={onBack}>
                <ArrowLeft />
              </a>
              <h1>Whom would you like to cover?</h1>
            </div>
            <Row className="justify-content-center mt-2 mt-md-5 widthCustomCss">
              {coverageOptions.map(option => (
                <Col
                  key={option.id}
                  xs={6}
                  md={3}
                  className="d-md-flex flex-column bgCustomCss mx-2 pt-2 mb-1 mb-md-0"
                >
                  <div
                    className={`radio-box d-flex justify-content-between ${selectedOption === option.id ? "active" : ""}`}
                    onClick={() => handleOptionChange(option.id)}
                  >
                    <div className="imgLabel">
                      <img src={option.imgSrc} alt={option.label} />
                      <label htmlFor={option.id}>
                        {option.label}
                        <span className="radio-circle" />
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id={option.id}
                        name="coverageOption"
                        value={option.id}
                        checked={selectedOption === option.id}
                        onChange={() => handleOptionChange(option.id)}
                        className="radio-input"
                      />
                    </div>
                  </div>
                </Col>
              ))}

              <Col md={10} className="my-4">
                <Form.Group className="floating-input mb-4">
                  <Form.Control id="lives" type="number" placeholder="" {...register("noOfLives")} />
                  <Form.Label htmlFor="lives">
                    Number of Lives <span>*</span>
                  </Form.Label>
                  {errorMessage && <p className="text-start text-primary fs-12px hide-empty">{errorMessage}</p>}
                </Form.Group>
                
                <h6 className="ecPricing">Estimated Contribution</h6>
                <h5 className="pricingCoverage">{isNaN(total) ? 0 : total} Rs.*</h5>
              </Col>
              <Col md={6}>
                <button type="submit" className="btn btn-primary mt-4 btnWidthCustom" disabled={!selectedOption || !temp}>
                  Next
                </button>
              </Col>
              <div style={{ height: "20px" }} />
              <p className="tipestimatedCon">*The estimated contribution is calculated based on the plan you have selected.</p>
            </Row>
          </Container>
        </section>
      </div>
    </form>
  );
}
