import "react-datepicker/dist/react-datepicker.css";
import "../../../../styles/styles.scss";
import React, { FC, MouseEventHandler, SyntheticEvent } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Calendar } from "../../icons/calendar";
import CustomDatePickerHeader from "./CustomDatePickerHeader";

type IDateFunction = (date: Date | null, event: SyntheticEvent<Event> | undefined) => void;
interface IProps {
  onChange: IDateFunction;
  value: Date | null | undefined;
  maxDate?: string;
  minDate?: string;
  dateFormat?: string;
  showIcon?: boolean;
  label?: string;
  disabled?: boolean;
  defaultValue?: string;
  dataTestid?: string;
  isOptionsAsc?: boolean;
}

const DateInput = ({
  label,
  defaultValue,
  showIcon,
  disabled,
  ...formProps
}: {
  label?: string;
  defaultValue?: string;
  showIcon: boolean;
  disabled?: boolean;
}) => {
  const { onClick, value, ...inputProps } = formProps as { onClick: MouseEventHandler<HTMLElement>; value: string };

  return (
    <Form.Group
      // onClick is responsible for opening calendar, passing it to group to make whole component clickable and all the other props to form.control(input)
      onClick={onClick}
      className="floating-input position-relative d-flex align-items-center"
    >
      <Form.Control
        {...inputProps}
        value={value || defaultValue || ""}
        disabled={disabled}
      />
      {showIcon && <Calendar className={"position-absolute end-0 mx-3"} />}
      <Form.Label>
        {label}
        <span>*</span>
      </Form.Label>
    </Form.Group>
  );
};

const CustomDatePicker: FC<IProps> = ({
  onChange,
  value,
  maxDate,
  minDate,
  defaultValue,
  showIcon,
  dateFormat = "dd/MM/yyyy",
  isOptionsAsc,
  label,
  dataTestid,
  disabled
}) => {
  return (
    <DatePicker
      customInput={
        <DateInput
          label={label}
          defaultValue={defaultValue}
          showIcon={!!showIcon}
          data-testid={dataTestid}
          disabled={disabled}
        />
      }
      renderCustomHeader={props => (
        <CustomDatePickerHeader
          {...props}
          minDate={minDate}
          maxDate={maxDate}
          isOptionsAsc={isOptionsAsc}
        />
      )}
      onChange={onChange}
      selected={value}
      maxDate={maxDate ? new Date(maxDate) : null}
      minDate={minDate ? new Date(minDate) : null}
      dateFormat={dateFormat}
      placeholderText={""}
      disabled={disabled}
    />
  );
};

export default CustomDatePicker;
