import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const EditBox = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M0 212.214c1.898-7.303 3.578-14.022 5.696-20.96 16.468-52.676 64.818-90.237 121.941-90.237 1.188 0 2.372 0.016 3.553 0.049l-0.174-0.004c72.665-0.511 145.33 0 217.922 0 0.828-0.086 1.788-0.134 2.76-0.134 10.983 0 20.507 6.249 25.21 15.385l0.075 0.159c1.669 3.107 2.65 6.799 2.65 10.721 0 5.944-2.254 11.361-5.954 15.443l0.017-0.019c-5.113 6.154-12.769 10.043-21.333 10.043-0.871 0-1.733-0.040-2.585-0.12l0.11 0.008q-107.939 0-215.95 0c-1.358-0.084-2.946-0.131-4.545-0.131-25.082 0-47.428 11.72-61.859 29.98l-0.126 0.165c-9.111 11.262-14.882 25.559-15.623 41.174l-0.006 0.16c0 4.309-0.366 8.691-0.366 12.999q0 324.4 0 648.873c-0.117 1.619-0.182 3.509-0.182 5.413 0 25.429 11.815 48.099 30.255 62.818l0.161 0.124c13.108 9.967 29.704 15.966 47.701 15.966 0.946 0 1.888-0.017 2.827-0.050l-0.136 0.004q353.393 0 706.859 0c1.234 0.072 2.677 0.113 4.13 0.113 35.488 0 65.263-24.456 73.393-57.434l0.109-0.519c1.913-7.434 3.012-15.967 3.012-24.758 0-0.667-0.006-1.334-0.019-1.998l0.001 0.1c0-71.643 0-143.285 0-214.928-0.142-1.014-0.224-2.185-0.224-3.375 0-14.076 11.411-25.488 25.488-25.488 1.394 0 2.762 0.112 4.095 0.328l-0.146-0.020c10.85 1.65 19.372 9.927 21.372 20.509l0.025 0.159c0.42 2.783 0.66 5.993 0.66 9.259 0 0.236-0.001 0.472-0.004 0.708v-0.036c0 72.3 0 144.6 0 216.973 0.055 1.393 0.087 3.030 0.087 4.673 0 55.936-36.414 103.368-86.831 119.882l-0.891 0.253c-10.548 2.989-23.695 5.744-37.107 7.681l-1.746 0.207h-715.696c-2.626-0.668-5.924-1.283-9.28-1.708l-0.432-0.045c-53.87-3.896-98.174-40.78-113.038-90.387l-0.232-0.901c-1.826-6.281-3.359-12.634-4.966-18.914z"></path>
      <path d="M1023.883 147.875c-0.625 33.255-14.945 63.046-37.539 84.064l-0.072 0.067q-147.887 147.594-295.48 295.407c-49.733 49.733-99.248 99.613-149.347 148.908-6.875 6.599-15.306 11.632-24.685 14.491l-0.437 0.115c-66.749 19.133-133.718 37.391-200.614 55.941l-3.87 0.876c-24.319 6.353-40.678-9.567-34.032-33.667 18.331-66.969 37.172-133.792 55.211-200.833 3.529-12.967 10.271-24.063 19.33-32.768l0.024-0.022q220.77-220.332 441.102-441.030c21.522-23.251 52.201-37.759 86.269-37.759 2.074 0 4.134 0.054 6.181 0.16l-0.287-0.012c22.828 0.179 43.746 8.219 60.214 21.541l-0.182-0.143c19.928 17.136 37.969 35.013 54.681 54.084l0.529 0.617c14.397 17.592 23.12 40.311 23.12 65.067 0 1.722-0.042 3.434-0.125 5.135l0.009-0.24zM771.272 137.577l-369.751 369.751 117.432 117.359 369.751-369.679zM926.315 215.647c11.48-9.575 21.841-19.242 31.645-29.448l0.123-0.129c9.031-10.531 14.528-24.323 14.528-39.399s-5.498-28.868-14.597-39.481l0.069 0.082c-12.421-14.576-25.557-27.69-39.674-39.685l-0.492-0.408c-9.94-9.159-23.268-14.775-37.906-14.775-10.743 0-20.78 3.025-29.305 8.268l0.243-0.138c-16.336 11.304-30.65 23.16-43.87 36.128l0.052-0.051zM374.206 557.355l-35.858 130.067 129.629-36.004z"></path>
    </svg>
  );
};
