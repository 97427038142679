import { Container, Row, Col, Button } from "react-bootstrap";


export default function Thankyou(): ReactElement {
  return (
      <div className="heroBanner-pa">
        <section className="my-health-takaful-bnr mt-4">
          <Container className="text-center mt-5">
            <Row className="d-flex justify-content-center">
                <div className="col-12 col-md-6 thanksBox">
                      <img className="thankyouImg" src="../assets/img/thankyouImg.svg" alt="Agent" />
                      <h3 className='thankshead mt-3'>Thank <span> You!</span></h3>
                      <p className="thanksParas px-4">Your request has been scheduled successfully!</p>
                      <p className="thanksParas px-4">Our Takaful Specialist will contact you within 48 Hours or Call us now at <span> UAN: 111-832-682</span> </p>
                      <Button className="thankyouBtn mb-5">
                        <a className="thankyouAnchor" href="https://takafulbazaar.pk/">Back to Homepage</a>
                      </Button>
                </div>
            </Row>
          </Container>
        </section>
      </div>
  );
}
