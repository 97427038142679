import React, { ReactElement } from "react";
export const DiscountCenter = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.848"
    height="18.694"
    viewBox="0 0 21.848 18.694"
  >
    <g
      id="Group_44670"
      data-name="Group 44670"
      transform="translate(-299.665 -400.673)"
    >
      <g
        id="Group_44669"
        data-name="Group 44669"
        transform="translate(299.665 400.673)"
      >
        <path
          id="Path_47043"
          data-name="Path 47043"
          d="M779.577,858.711c-.4.545-.792,1.091-1.19,1.634a.452.452,0,0,1-.742.115l-.231-.168v.29q0,3.967,0,7.935c0,.49-.115.607-.6.607H760.5c-.485,0-.6-.116-.6-.605q0-3.957,0-7.914v-.3c-.122.084-.211.146-.3.206a.419.419,0,0,1-.62-.1q-.582-.776-1.148-1.564c-.177-.247-.119-.463.162-.67q1.63-1.2,3.263-2.4c.16-.118.319-.238.481-.353a.431.431,0,0,1,.64.059.426.426,0,0,1-.138.628q-1.646,1.217-3.3,2.428c-.057.042-.112.085-.182.138l.662.9,2.11-1.55,6.749-4.965c.317-.233.438-.233.759,0l8.569,6.3.283.206.658-.9c-.054-.044-.1-.086-.151-.123q-4.783-3.519-9.562-7.042a.252.252,0,0,0-.364.005q-1.677,1.246-3.364,2.479a.439.439,0,0,1-.7-.084.429.429,0,0,1,.173-.589q1.089-.8,2.18-1.606.781-.575,1.563-1.149a.446.446,0,0,1,.654,0q5.162,3.8,10.32,7.595a2.243,2.243,0,0,1,.274.288ZM760.75,868.26h15.81v-.239q0-4.062,0-8.124a.419.419,0,0,0-.2-.383q-3.224-2.362-6.441-4.733l-1.265-.93c-.061.043-.108.074-.154.107q-3.768,2.773-7.539,5.542a.473.473,0,0,0-.22.436q.01,4.019,0,8.039Z"
          transform="translate(-757.728 -850.43)"
          fill="#515151"
        />
        <path
          id="Path_47044"
          data-name="Path 47044"
          d="M881.065,941.826a.421.421,0,1,1,.415-.427A.423.423,0,0,1,881.065,941.826Z"
          transform="translate(-875.375 -937.112)"
          fill="#515151"
        />
        <path
          id="Path_47045"
          data-name="Path 47045"
          d="M969,1065.925a.417.417,0,0,1-.383-.556,1.28,1.28,0,0,1,.1-.209q1.421-2.462,2.847-4.92a.629.629,0,0,1,.311-.288.48.48,0,0,1,.4.1.7.7,0,0,1,.122.4c.008.065-.056.142-.095.209q-1.431,2.481-2.861,4.962A.478.478,0,0,1,969,1065.925Z"
          transform="translate(-959.576 -1050.985)"
          fill="#515151"
        />
        <path
          id="Path_47046"
          data-name="Path 47046"
          d="M941.76,1058.833a1.279,1.279,0,0,1,.007-2.558,1.279,1.279,0,1,1-.007,2.558Zm0-.854a.423.423,0,0,0,.436-.422.425.425,0,1,0-.85,0A.421.421,0,0,0,941.759,1057.978Z"
          transform="translate(-932.679 -1047.476)"
          fill="#515151"
        />
        <path
          id="Path_47047"
          data-name="Path 47047"
          d="M1027.636,1145.707a1.278,1.278,0,1,1,1.281-1.267A1.277,1.277,0,0,1,1027.636,1145.707Zm.013-.854a.419.419,0,0,0,.414-.421.432.432,0,0,0-.435-.427.436.436,0,0,0-.415.427A.422.422,0,0,0,1027.649,1144.853Z"
          transform="translate(-1014.876 -1130.637)"
          fill="#515151"
        />
        <path
          id="Path_47048"
          data-name="Path 47048"
          d="M1100.253,1228.7c-.405,0-.811,0-1.216,0a.428.428,0,1,1,0-.851q1.237-.006,2.474,0a.426.426,0,1,1,0,.85C1101.092,1228.7,1100.673,1228.7,1100.253,1228.7Z"
          transform="translate(-1083.993 -1211.709)"
          fill="#515151"
        />
        <path
          id="Path_47049"
          data-name="Path 47049"
          d="M1059.5,1228.36a.416.416,0,0,1-.425.413.427.427,0,0,1-.421-.422.434.434,0,0,1,.441-.427A.422.422,0,0,1,1059.5,1228.36Z"
          transform="translate(-1045.787 -1211.787)"
          fill="#515151"
        />
      </g>
    </g>
  </svg>
);
