import { ILifeComparePlan } from "../../../../modules/life/utils/interfaces";
import { RootState } from "../../../store";
import { createSlice } from "@reduxjs/toolkit";

interface ICompare {
  plans: ILifeComparePlan[];
}

const initialState: ICompare = {
  plans: []
};

// eslint-disable-next-line import/no-unused-modules
export const comparePlans = createSlice({
  name: "compare",
  initialState,
  reducers: {
    AddCompare: (state, payload) => {
      state.plans = payload.payload;
    },
    // eslint-disable-next-line no-param-reassign, no-unused-vars, @typescript-eslint/no-unused-vars
    ResetCompare: state => (state = initialState)
  }
});

// Action creators are generated for each case reducer function
export const { AddCompare, ResetCompare } = comparePlans.actions;

export default comparePlans.reducer;

export const getComparePlans = (state: RootState): ICompare => state.comparePlans;
