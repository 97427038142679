import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import i18n from "./i18n/i18n";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";

const root = createRoot( document.getElementById( "root" ) as HTMLElement );
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={ i18n }>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
