import React, { ReactElement, ReactNode } from "react";
import { Modal } from "react-bootstrap";

interface IProps {
  show: boolean;
  centered?: boolean;
  onHide: () => void;
  body: ReactNode;
  title: string | ReactElement;
  size?: "lg" | "sm" | "xl" | "md";
  dialogClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  modalClassName?: string;
  footer?: ReactNode;
  titleClassName?: string;
  dataTestId?: string;
}
export default function CustomModal({
  show,
  onHide,
  body,
  title,
  dialogClassName,
  headerClassName,
  bodyClassName,
  modalClassName,
  titleClassName,
  footer,
  size = "lg",
  centered = true,
  dataTestId
}: IProps): ReactElement {
  return (
    <Modal
      className={modalClassName}
      data-testid={dataTestId}
      dialogClassName={dialogClassName}
      centered={centered}
      size={size as "lg" | "sm" | "xl"}
      show={show}
      onHide={() => onHide()}
    >
      <Modal.Header
        closeButton
        className={headerClassName}
      >
        <Modal.Title className={titleClassName}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={bodyClassName}>{body}</Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
}
