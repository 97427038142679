import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Hamburger = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M960.762 329.771h-771.087c-34.912 0-63.216-28.291-63.238-63.198v-0.002c0-34.925 28.313-63.238 63.238-63.238h771.087c34.925 0 63.238 28.313 63.238 63.238v0c-0.022 34.909-28.326 63.199-63.238 63.199 0 0 0 0 0 0v0z"></path>
      <path d="M960.723 575.199h-897.485c-34.912 0-63.216-28.291-63.238-63.198v-0.002c0-34.925 28.313-63.238 63.238-63.238h897.485c34.925 0 63.238 28.313 63.238 63.238v0c-0.022 34.909-28.326 63.199-63.238 63.199 0 0 0 0 0 0v0z"></path>
      <path d="M960.762 820.666h-531.214c-34.912 0-63.216-28.291-63.238-63.198v-0.002c0-34.925 28.313-63.238 63.238-63.238h531.214c34.909 0.022 63.199 28.326 63.199 63.238 0 0 0 0 0 0v0c-0.022 34.895-28.304 63.178-63.197 63.199h-0.002z"></path>
    </svg>
  );
};
