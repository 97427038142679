import { ArrowLeft } from "../../../../../shared/components/icons";
import { Button, Form, Container, Row } from "react-bootstrap";
import InputPhone from "../../../../../shared/components/form-elements/input-phone";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { emailPattern, noSpecialCharacter, phoneAndEmailPattern } from "../../../../../shared/regex";
import Login from "../../../../authentication/container/login";
import Signup from "../../../../authentication/container/signup";
import Api from "../../../../../shared/network/axiosClients";
import { useSearchParams, useNavigate } from "react-router-dom";

const NewLeadDetails = ({ onNext, onBack }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams(); // Use this to get query params
  const {
    control,
    register,
    formState: { errors },
    handleSubmit
  } = useForm();
  // const [show, setShow] = useState<boolean>(false);
  // const [isLoginScreen, setIsLoginScreen] = useState<boolean>(true);
  const form = useForm();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Extract the `annualHospitalizationLimit` from query params
  const hospitalizationLimit = searchParams.get("hospitalizationLimit");
  const noOfLives = searchParams.get("noOfLives");
  const coverage = searchParams.get("coverage");
  const estimatedContribution = searchParams.get("estimatedContribution");
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    navigate(`/sme?companyName=${data.companyName}&contactNumber=${data.contactNumber}&contactPerson=${data.name}&email=${data.email}&hospitalizationLimit=${hospitalizationLimit}`);
    setLoading(true); // Set loading to true before starting the API call
    // Create the object to send to the API
    const payload = {
      name: data.name,
      contactNumber: data.contactNumber,
      email: data.email?.trim(), // Include email if not empty or whitespace
      additionalInformation: {
        coveredMembers: coverage,
        noOfLifes: noOfLives,
        companyName: data.companyName,
        estimatedContribution: estimatedContribution,
        hospitalizationLimit: hospitalizationLimit
      }
    };

    // Remove the email key if it is empty or whitespace
    if (!payload.email) {
      delete payload.email;
    }

    try {
      const response = await Api.post("web-leads/sme", payload);
      if (response.status === 201) {
        onNext?.();
      } else {
        setErrorMessage(t("common_error_message"));
      }
    } catch (error) {
      setErrorMessage(t("submission_error_message"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="heroBanner-pa pt-4">
      <section className="my-health-takaful-bnr mt-4">
        <Container className="text-center px-4 px-md-0 justify-content-center">
          <Row className="d-grid justify-content-center">
            <div className="d-flex justify-content-center mb-md-4">
              <a
                className="hwb-back mt-1 me-4"
                data-testid="lead-details-form-back"
                onClick={onBack}
              >
                <ArrowLeft />
              </a>
              <h1 className="mb-4 mb-md-3">Please Provide Details</h1>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group
                className="floating-input mb-4"
                data-testid="lead_details_name"
              >
                <Form.Control
                  id="companyName"
                  // className={!errors?.name && dirtyFields?.name ? "is-valid" : ""}
                  type="text"
                  placeholder=""
                  {...register("companyName", {
                    required: t("health_landing_user_details_lead_form_name_required_error"),
                    maxLength: {
                      value: 35,
                      message: t("health_landing_user_details_lead_form_name_max_error")
                    },
                    pattern: {
                      value: noSpecialCharacter,
                      message: t("health_landing_user_details_lead_form_name_special_character_error")
                    }
                  })}
                />
                <Form.Label htmlFor="companyName">
                  Company Name
                  <span>*</span>
                </Form.Label>
                <div className={"text-start text-primary fs-12px hide-empty mt-1"}>{errors?.companyName && <span>{t("sme_lead-details_company_name_validation")}</span>}</div>
              </Form.Group>

              <Form.Group
                className="floating-input mb-4"
                data-testid="lead_details_name"
              >
                <Form.Control
                  id="name"
                  // className={!errors?.name && dirtyFields?.name ? "is-valid" : ""}
                  type="text"
                  placeholder=""
                  {...register("name", {
                    required: t("health_landing_user_details_lead_form_name_required_error"),
                    maxLength: {
                      value: 35,
                      message: t("health_landing_user_details_lead_form_name_max_error")
                    },
                    pattern: {
                      value: noSpecialCharacter,
                      message: t("health_landing_user_details_lead_form_name_special_character_error")
                    }
                  })}
                />
                <Form.Label htmlFor="name">
                  Contact Person Name
                  <span>*</span>
                </Form.Label>
                <div className={"text-start text-primary fs-12px hide-empty mt-1"}>{errors?.name && <span>{t("sme_lead-details_contact_person_name_validation")}</span>}</div>
              </Form.Group>
              <Form.Group className="floating-input mb-4">
                <Controller
                  name="contactNumber"
                  control={control}
                  render={({ field }) => (
                    <InputPhone
                      placeholder={t("contact_number_placeholder")}
                      {...field}
                    />
                  )}
                  rules={{
                    required: "Number is required",
                    pattern: {
                      value: phoneAndEmailPattern,
                      message: "Invalid contact number"
                    }
                  }}
                />
                {errors?.contactNumber && (
                  <div className="text-start text-primary fs-12px hide-empty mt-1">{t("sme_lead-details_contact_number_validation")}</div>
                )}
              </Form.Group>

              <Form.Group
                className="floating-input mb-3"
                // data-testid="lead_details_email"
              >
                <Form.Control
                  // className={!errors?.email && dirtyFields?.email ? "is-valid" : ""}
                  id="email"
                  type="text"
                  placeholder=""
                  {...register("email", {
                    // required: t("auth_signup_email_required_error"),
                    pattern: {
                      value: emailPattern,
                      message: t("auth_signup_email_valid_error")
                    }
                  })}
                />
                <Form.Label htmlFor="email">
                  {t("health_landing_user_details_form_lead_email_label")}
                  {/* <span>*</span> */}
                </Form.Label>
                <div className={"text-primary fs-12px hide-empty mt-1"}>{errors?.email && <span>{errors.email.message}</span>}</div>
              </Form.Group>

              <Form.Check
                className="hwb-accept-terms"
                data-testid="lead_details_agree"
                type={"checkbox"}
                {...register("termAndCondition", {
                  required: t("health_landing_user_details_lead_form_terms_required_error")
                })}
                label={
                  <>
                    {t("health_landing_user_details_form_terms_agree")}
                    <a href=" https://takafulbazaar.pk/terms-and-conditions">{t("health_landing_user_details_form_terms_and_condition_agree")}</a>
                  </>
                }
              />
              <div className={"text-start text-primary fs-12px hide-empty mt-1"}>
                {errors?.termAndCondition && <span>{errors.termAndCondition.message}</span>}
              </div>

              {errorMessage && <div className="error-message text-danger mt-3">{errorMessage}</div>}
              <Button
                className="hwb-btn"
                size="lg"
                type="submit"
                disabled={loading} // Disable the button when loading
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </form>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default NewLeadDetails;
