import React, { ReactElement } from "react";

export default function StaticPageWrapper(): ReactElement {
  {
    /*
    These anchor tags below are defined just for static pages, on build time react-snap scan all the files and check if it found any anchor tag with specified route then it will create a static file agains that specific route,. though it doesn't necessary to show the anchor tag to user
  */
  }
  return (
    <div style={{ display: "none" }}>
      <a href={"/health"}>home</a>
    </div>
  );
}
