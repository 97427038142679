import { Container } from "react-bootstrap";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function Exclusions(): ReactElement {
  const { t } = useTranslation();

  const coverageDetails = [
    {
      title: "Cosmetic Treatments",
      captions: ["Treatment/operation deemed ‘medically unnecessary’ such as plastic surgery and spa treatment."]
    },
    {
      title: "Dental Treatment",
      captions: ["Treatment for teeth or gums such as scaling, dental implants, cavity treatment, etc."]
    },
    {
      title: "Self-Inflicted Injury",
      captions: ["Injuries caused intentionally or as a form of self-harm."]
    },
    {
      title: "Psychiatric Treatment",
      captions: ["Treatment of mental and nervous disorders."]
    }
  ];

  return (
    <div className="landing-page-wrapper mt-5">
      <section className="exclusion-section exclusion-pa my-health-takaful-bnr">
        <Container>
          <h1 className="mhtb-title text-center">Exclusions</h1>
          <p className="mhtb-caption text-center">Please note that benefits won’t be payable in the following cases</p>

          <div className="sehatzamin-packages">
            {coverageDetails.map((detail, index) => (
              <div
                key={index}
                className={`col-md-12 d-flex justify-content-between ${
                  index !== coverageDetails.length - 1 ? "border-bottom-ruppees pt-4 pb-4" : "pt-4 pb-4"
                }`}
              >
                <div className="text-start">
                  <h4 className="candb-title crossmark-title">{detail.title}</h4>
                  {/* Map through captions array */}
                  {detail.captions.map((caption, captionIndex) => (
                    <p
                      key={captionIndex}
                      className="candb-caption ms-4 mb-0"
                    >
                      {caption}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
}
