import { Container } from "react-bootstrap";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export default function KeyFeatures(): ReactElement {
  const { t } = useTranslation();
  const keyFeaturesData = [
    {
      imgSrc: "/assets/img/cashlessHospitalizationHealth.svg",
      title: "Cashless Hospitalization",
      description: "Get treatment without having to pay upfront."
    },
    {
      imgSrc: "/assets/img/temporaryDisability-pa.svg",
      title: "Medical Emergencies",
      description: "Unforeseen emergency medical treatments are covered."
    },
    {
      imgSrc: "/assets/img/medicalExamHealth.svg",
      title: "Pre-existing Conditions",
      description: "A pre-defined percentage of prior illnesses will be covered."
    },
    {
      imgSrc: "/assets/img/accidentalMed-pa.svg",
      title: "Medical Examination",
      description: "Medical examinations and special investigations are also covered."
    }
  ];
  return (
    <div className="landing-page-wrapper mt-5">
      <section className="exclusion-section keyFeatures-pa my-health-takaful-bnr">
        <Container>
          <h1 className="mhtb-title text-center mb-5">
            Key <span className="text-primary">Features</span>
          </h1>
          <div className="row">
            {keyFeaturesData.map((feature, index) => (
              <div
                className="col-md-6 mb-2 mb-md-4"
                key={index}
              >
                <div className="row pa-kf-box mx-0 align-items-center">
                  <div className="col-3 text-center">
                    <img
                      src={feature.imgSrc}
                      alt={feature.title}
                    />
                  </div>
                  <div className="col-9">
                    <h4 className="pa-kf-heading mb-1">{feature.title}</h4>
                    <p className="pa-kf-subheading mb-0">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
}
