import React, { lazy } from "react";

const NotFound = lazy(() => import("../../modules/notFound"));

export const notFoundRoute = [
  {
    path: "*",
    element: <NotFound />
  }
];
