import { range } from "lodash";
import React, { MouseEventHandler, ReactElement } from "react";
import { ArrowUp } from "../../icons/arrow-up";
import { ArrowDown } from "../../icons/arrow-down";
import Select, { ActionMeta, SingleValue } from "react-select";
import { Months } from "../../../../shared/constant";

interface IProps {
  decreaseMonth: MouseEventHandler<HTMLDivElement> | undefined;
  increaseMonth: MouseEventHandler<HTMLDivElement> | undefined;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  changeYear: Function;
  changeMonth: Function;
  date: Date;
  minDate?: string;
  maxDate?: string;
  isOptionsAsc?: boolean;
}

const CustomDatePickerHeader = ({
  decreaseMonth,
  prevMonthButtonDisabled,
  increaseMonth,
  nextMonthButtonDisabled,
  changeYear,
  changeMonth,
  maxDate,
  minDate,
  isOptionsAsc = true,
  date
}: IProps): ReactElement => {
  const currentYear = new Date().getFullYear();
  const minYear = new Date(minDate || "").getFullYear();
  const maxYear = new Date(maxDate || "").getFullYear() + 1;
  let years = range(minDate ? minYear : currentYear - 100, maxDate ? maxYear : currentYear + 100, 1);
  if (isOptionsAsc) {
    years = years.reverse();
  }

  const currentSelectedYear = new Date(date).getFullYear();
  const currentSelectedMonth = Months[new Date(date).getMonth()];

  const onChangeDate: (
    newValue: SingleValue<{ value: number; label: number }>,
    actionMeta: ActionMeta<{ value: number; label: number }>
  ) => void = value => {
    changeYear(value?.value);
  };

  const onChangeMonth: (
    newValue: SingleValue<{ value: string; label: string }>,
    actionMeta: ActionMeta<{ value: string; label: string }>
  ) => void = value => {
    changeMonth(Months.indexOf(value?.value as string));
  };
  return (
    <div className={"d-flex w-full align-items-center justify-content-around"}>
      <Select
        value={{ value: currentSelectedYear, label: currentSelectedYear }}
        isSearchable
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        options={years.map((year: number) => ({ value: year, label: year }))}
        onChange={onChangeDate}
        placeholder={"YYYY"}
        className={"h-25 m-0 mx-2 w-25 p-0 react-select-container"}
        classNamePrefix={"react-select"}
      />
      <Select
        value={{ value: currentSelectedMonth, label: currentSelectedMonth }}
        isSearchable
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        options={Months.map((month: string) => ({ value: month, label: month }))}
        onChange={onChangeMonth}
        placeholder={"MM"}
        className={"m-0 p-0 react-select-container"}
        classNamePrefix={"react-select-month"}
      />

      <div
        onClick={!prevMonthButtonDisabled ? decreaseMonth : undefined}
        className={"mx-2"}
      >
        <ArrowUp />
      </div>
      <div onClick={!nextMonthButtonDisabled ? increaseMonth : undefined}>
        <ArrowDown />
      </div>
    </div>
  );
};

export default CustomDatePickerHeader;
