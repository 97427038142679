import { AutoRoutes } from "./auto";
import { cartRoutes } from "./cart";
import { healthRoutes } from "./health";
import { notFoundRoute } from "./notFound";
import { paymentRoutes } from "./payment";
import { personalAccidentRoutes } from "./personalAccident";
import { lifeRoutes } from "./life";
import { houseHoldRoutes } from "./houseHold";

export const PublicRoutes = [
  ...healthRoutes,
  ...cartRoutes,
  ...paymentRoutes,
  ...notFoundRoute,
  ...AutoRoutes,
  ...personalAccidentRoutes,
  ...houseHoldRoutes,
  ...lifeRoutes
];
