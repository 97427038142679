import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Calendar = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      className={className}
    >
      <title></title>
      <g id="icomoon-ignore"></g>
      <path d="M665.616 146.198h80.465c0 21.945 0 36.575 0 58.52 0 7.315 0 21.945 7.315 29.26 14.63 29.26 51.205 36.575 80.465 21.945 14.63-7.315 21.945-21.945 29.26-29.26 0-7.315 0-14.63 0-14.63 0-21.945 0-36.575 0-58.52h73.15c21.945 0 29.26 7.315 29.26 29.26v519.367c0 95.096 0 197.506 0 292.6 0 7.315 0 14.63 0 14.63 0 7.315-14.63 14.63-21.945 14.63h-855.858c-14.63 0-29.26-7.315-29.26-21.945 0 0 0-7.315 0-7.315 0-270.656 0-548.627 0-819.283 0-14.63 7.315-29.26 21.945-29.26 0 0 7.315 0 7.315 0h73.15c0 14.63 0 36.575 0 51.205 0 7.315 0 7.315 0 14.63 7.315 29.26 29.26 43.89 51.205 43.89 29.26 0 51.205-14.63 58.52-36.575 0-7.315 0-14.63 0-14.63 7.315-21.945 7.315-36.575 7.315-58.52h73.15c0 7.315 0 14.63 0 21.945s0 21.945 0 29.26c0 29.26 21.945 51.205 51.205 58.52 21.945 0 43.89-7.315 51.205-21.945 7.315-7.315 7.315-21.945 7.315-29.26 0-21.945 0-36.575 0-58.52h80.465v7.315c0 14.63 0 29.26 0 43.89 0 29.26 21.945 58.52 51.205 65.836 0 0 7.315 0 7.315 0 21.945 0 43.89-14.63 51.205-29.26 0-7.315 7.315-14.63 7.315-21.945 7.315-29.26 7.315-43.89 7.315-65.836zM512 358.333h-358.436c-21.945 0-29.26 7.315-29.26 29.26 0 182.875 0 358.436 0 541.312 0 21.945 7.315 36.575 29.26 36.575h709.558c21.945 0 29.26-7.315 29.26-36.575 0-182.875 0-358.436 0-541.312 0-21.945-7.315-36.575-36.575-36.575h-343.805z"></path>
      <path d="M259.485 118.986c0 21.945 0 43.89 0 65.836s-14.63 43.89-29.26 43.89c-21.945 7.315-43.89-7.315-51.205-29.26 0-7.315 0-7.315 0-14.63 0-51.205 0-95.096 0-146.301 0-14.63 14.63-36.575 36.575-36.575 0 0 0 0 7.315 0 21.945 0 36.575 21.945 36.575 43.89v73.15z"></path>
      <path d="M758.15 118.986c0-21.945 0-51.205 0-73.15s14.63-36.575 29.26-43.89c21.945-7.315 36.575 7.315 43.89 21.945 0 7.315 7.315 14.63 7.315 21.945 0 43.89 0 95.096 0 138.985 0 29.26-21.945 51.205-51.205 43.89-21.945 0-29.26-21.945-29.26-36.575 0-21.945 0-51.205 0-73.15v0z"></path>
      <path d="M567.375 119.205c0-21.945 0-43.89 0-73.15s14.63-43.89 36.575-43.89c21.945 0 43.89 21.945 36.575 43.89 0 0 0 0 0 0 0 43.89 0 95.096 0 138.985 0 21.945-7.315 36.575-29.26 43.89-14.63 7.315-36.575 0-43.89-14.63 0-7.315 0-14.63 0-21.945 0-29.26 0-51.205 0-73.15z"></path>
      <path d="M451.139 118.986c0 21.945 0 43.89 0 73.15s-21.945 51.205-51.205 43.89c-21.945-7.315-29.26-29.26-29.26-43.89 0-51.205 0-95.096 0-146.301 0-21.945 14.63-43.89 36.575-43.89 0 0 0 0 7.315 0 21.945 0 36.575 21.945 36.575 43.89 0 0 0 0 0 0s0 0 0 0c0 29.26 0 51.205 0 73.15z"></path>
      <path d="M314.202 727.083h-95.096v-95.096h95.096v95.096z"></path>
      <path d="M476.961 631.696v95.096h-95.096v-95.096h95.096z"></path>
      <path d="M636.355 631.038v95.096h-95.096v-95.096h95.096z"></path>
      <path d="M707.677 726.791v-95.096h95.096v95.096h-95.096z"></path>
      <path d="M381.939 475.008h95.096v95.096h-95.096v-95.096z"></path>
      <path d="M544.845 570.396v-95.096h95.096v95.096h-95.096z"></path>
      <path d="M707.604 570.396v-95.096h95.096v95.096h-95.096z"></path>
      <path d="M313.983 890.062h-95.096v-95.096h95.096v95.096z"></path>
      <path d="M381.939 794.82h95.096v95.096h-95.096v-95.096z"></path>
      <path d="M544.625 890.209v-95.096h95.096v95.096h-95.096z"></path>
      <path d="M802.919 795.040v95.096h-95.096v-95.096h95.096z"></path>
    </svg>
  );
};
