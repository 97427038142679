import Api from "../../../../shared/network/axiosClients";
import { getAPIUrl } from "../../../../shared/helper";
import { IAlphaNumeric } from "../../../../shared/interface";
import { AxiosResponse } from "axios";

export const getAllPlans = async (body: { quotationId: string }): Promise<AxiosResponse> => {
  // return Api.post(getAPIUrl("web/personalAccident/shelf-plans?take=100"), body, {});
  const { contactNumber, email , name , ...filteredBody } = body;
  return Api.post(getAPIUrl("web/personalAccident/shelf-plans?take=100"), filteredBody, {});
};

export const getQuoteDetailByQuoteId = async (id: IAlphaNumeric): Promise<AxiosResponse> => {
  return Api.get(`web/quotation/personalAccident/${id}`);
};

export const getMonthlyAgreeablePriceByAnnualContibution = (
  quotationId: string,
  planId: number,
  contribution: number,
  currencyCode: string
): Promise<AxiosResponse> => {
  return Api.get(
    getAPIUrl("cart/calculate-monthly-contribution", {
      quotationId,
      planId,
      contribution,
      currencyCode
    })
  );
};
