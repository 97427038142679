/* eslint-disable import/no-unused-modules */
import { RootState } from "../../../store";
import { createSlice } from "@reduxjs/toolkit";

interface IDocumentDetails {
  [key: string]: string;
}

interface IDetails {
  documentDetails: IDocumentDetails;
}

const initialState: IDetails = {
  documentDetails: {}
};

export const PersonalAccidentOrderDetails = createSlice({
  name: "PersonalAccidentOrderDetails",
  initialState,
  reducers: {
    AddDocuments: (state, payload) => {
      state.documentDetails = payload.payload;
    },
    ResetDocuments: state => {
      // eslint-disable-next-line no-param-reassign, no-unused-vars, @typescript-eslint/no-unused-vars
      state = initialState;
    }
  }
});

export const { AddDocuments, ResetDocuments } = PersonalAccidentOrderDetails.actions;

export default PersonalAccidentOrderDetails.reducer;

export const getDocumentDetails = (state: RootState): {} => state.PersonalAccidentOrderDetails.documentDetails;
