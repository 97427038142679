import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Cart = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M1023.999 428.787c-5.083 25.416-10.167 50.833-20.333 76.249-30.5 127.082-60.999 254.164-86.416 381.247-5.083 35.583-35.583 55.916-71.166 55.916-223.665 0-442.246 0-665.91 0-35.583 0-60.999-20.333-71.166-55.916-35.583-152.499-71.166-304.997-106.749-457.496-10.167-35.583 15.25-71.166 50.833-81.333 5.083 0 10.167 0 15.25 0 60.999 0 121.999 0 188.082 0 10.167 0 20.333-5.083 20.333-15.25 30.5-76.249 66.083-152.499 96.582-228.748 5.083-15.25 20.333-25.416 35.583-20.333s25.416 20.333 25.416 35.583c0 5.083 0 5.083-5.083 10.167-20.333 50.833-45.75 101.666-66.083 157.582-10.167 20.333-15.25 40.666-25.416 60.999h350.747c0-5.083-5.083-10.167-5.083-10.167-30.5-66.083-60.999-137.249-91.499-203.332-10.167-15.25-5.083-30.5 10.167-40.666s30.5-5.083 40.666 10.167c0 0 0 5.083 0 5.083 20.333 40.666 35.583 86.416 55.916 127.082 15.25 35.583 30.5 66.083 45.75 101.666 0 10.167 10.167 15.25 20.333 10.167 55.916 0 116.916 0 172.832 0 35.583-5.083 71.166 20.333 76.249 55.916 0 0 0 0 0 0l10.167 25.416zM246.257 403.371c-5.083 0-5.083 0 0 0-55.916 0-111.832 0-167.749 0-10.167 0-15.25 5.083-10.167 15.25 35.583 147.415 66.083 294.831 101.666 442.246 5.083 20.333 5.083 20.333 25.416 20.333h599.827c15.25 0 35.583 0 50.833 0 10.167 0 15.25-5.083 15.25-10.167 0 0 0 0 0 0 35.583-147.415 66.083-294.831 101.666-447.329 5.083-15.25 0-15.25-15.25-15.25h-152.499c-5.083 0-5.083 0-10.167 0 5.083 5.083 5.083 10.167 5.083 15.25 5.083 15.25 0 35.583-15.25 40.666s-30.5 0-40.666-15.25c0 0 0 0 0 0-5.083-10.167-10.167-20.333-15.25-35.583 0-5.083-5.083-5.083-10.167-5.083-132.166 0-259.248 0-391.413 0-5.083 0-10.167 5.083-10.167 5.083-5.083 10.167-10.167 25.416-15.25 35.583-5.083 15.25-25.416 20.333-40.666 15.25s-20.333-25.416-15.25-40.666c5.083-5.083 5.083-10.167 10.167-20.333v0z"></path>
      <path d="M358.090 682.951c0 30.5 0 60.999 0 91.499 0 15.25-10.167 25.416-25.416 25.416s-25.416-10.167-25.416-25.416c0-60.999 0-121.999 0-182.998 0-15.25 10.167-25.416 25.416-25.416s25.416 10.167 25.416 25.416c0 30.5 0 60.999 0 91.499z"></path>
      <path d="M490.255 682.951c0-30.5 0-60.999 0-91.499 0-15.25 10.167-25.416 25.416-25.416s25.416 10.167 25.416 25.416c0 60.999 0 121.999 0 182.998 0 15.25-10.167 25.416-25.416 25.416s-25.416-10.167-25.416-25.416c0-30.5 0-60.999 0-91.499z"></path>
      <path d="M719.003 682.951c0 30.5 0 60.999 0 91.499 0 15.25-10.167 25.416-25.416 25.416s-25.416-10.167-25.416-25.416c0-60.999 0-121.999 0-182.998 0-15.25 10.167-25.416 25.416-25.416s25.416 10.167 25.416 25.416c0 30.5 0 60.999 0 91.499z"></path>
    </svg>
  );
};
