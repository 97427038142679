import React, { lazy } from "react";

const Auto = lazy(() => import("../../modules/auto/container/home"));
const AutoPlansDetail = lazy(() => import("../../modules/auto/container/planDetails"));
const AutoPlansCompare = lazy(() => import("../../modules/auto/container/compare"));
const AutoPlans = lazy(() => import("../../modules/auto/container/planListing"));

export const AutoRoutes = [
  {
    path: "/auto",
    element: <Auto />
  },
  {
    path: "auto/plans/:quotationID",
    element: <AutoPlans />
  },
  {
    path: "/auto/plans/:quotationID/detail/:id",
    element: <AutoPlansDetail />
  },
  {
    path: "/auto/plans/:quotationID/compare/:id",
    element: <AutoPlansCompare />
  }
];
