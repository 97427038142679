import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Container from "./modules/layout/container";

function App(): React.JSX.Element {
  const head = document.querySelector("head");
  const script = document.createElement("script");

  script.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "${process.env.REACT_APP_WEBSITE_PUBLIC_GOOGLE_ANALYTICS_CODE}");`;
  head?.appendChild(script);

  return (
    <Provider store={store}>
      <main>
        <Container />
      </main>
    </Provider>
  );
}

export default App;
