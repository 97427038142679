import { AddPlanInCart, ResetLifeCart, clearCartPlans } from "../../../../redux/features/life/cart";
import { AddLeadDetails } from "../../../../redux/features/life/userDetails";
import { createCart, getCartPlansByID, removeCart } from "../../../../shared/services/life/cart";
import { CurrenyCode, Members, PlanTypes, StatusCode } from "../../../../shared/constant";
import { ICartPlanService, ICartPlanWithType, ICartPlan, IFetchedCart, ILifeCreateCartPlan } from "../../utils/interfaces/cart";
import { ILifePlanCardService, ILifePlanListingCard } from "../../utils/interfaces";
import { NavigateFunction } from "react-router-dom";
import { getQuoteDetailByQuoteId } from "../../services/planListing";

export const getCartPlanServicesDTO = (services: [] | string): {}[] | [] => {
  if (!services?.length) return [];
  const isSingleServicesPlan = typeof services === "string";
  if (isSingleServicesPlan) return [{ planServiceId: Number(services) }];
  let isIDs = true;
  const data = services?.map((item: { id: string } | string) => {
    isIDs = typeof item === "string";
    let planServiceId = Number((item as { id: string }).id);
    if (isIDs) {
      planServiceId = Number(item);
    }
    const serviceData = { planServiceId };
    return serviceData;
  });
  return data;
};
export const getAddedCartPlanServicesDTO = (services?: ICartPlanService[]): {}[] => {
  if (!services?.length) return [];

  const data = services?.map(({ planServiceId, agreedPrice }) => {
    const serviceData: { planServiceId: number; agreedPrice?: number } = {
      planServiceId
    };
    if (agreedPrice) serviceData.agreedPrice = agreedPrice;
    return serviceData;
  });

  return data;
};
export const checkIfOtherPlanSelected = (currentPlanType: string, storedPlanType: string): boolean => {
  // if current plan type is parent so other than parent any plan should be added in payload of PostCart
  // else if current plan type is not parent means is either self or family so only if stored plan is parent, should be added in payload other wise neither self nor family should be added in PostCart
  if (currentPlanType === PlanTypes.PARENT) {
    return currentPlanType !== storedPlanType;
  } else if (currentPlanType !== PlanTypes.PARENT) {
    return storedPlanType === PlanTypes.PARENT;
  }
  return true;
};
export const getCartService = (planID: number, allPlansServices: [], servicesWithPrice?: { planServiceId: number; agreedPrice?: number }[]): {} => {
  if (servicesWithPrice?.length) {
    return servicesWithPrice;
  }
  return allPlansServices?.[planID] ? getCartPlanServicesDTO(allPlansServices?.[planID]) : [];
};
export const getAllCartPlans = (
  planID: number,
  cartPlanServiceDTO: ICartPlanService[],
  cartPlans: ICartPlanWithType,
  planType: string,
  allPlansServices: [],
  agreedPrice?: number
): {
  planId: number;
  cartPlanServices: {
    planServiceId: number;
  }[];
}[] => {
  const currentPlanTypePlan: ICartPlan = {
    planId: planID,
    cartPlanServices: cartPlanServiceDTO
  };
  if (agreedPrice) {
    currentPlanTypePlan.agreedPrice = Number(agreedPrice);
  }
  const allCartPlans: {
    planId: number;
    cartPlanServices: { planServiceId: number }[];
  }[] = [currentPlanTypePlan];
  Object.keys(cartPlans).forEach((cartPlanType: string) => {
    const isOtherPlanType = checkIfOtherPlanSelected(planType, cartPlanType);
    if (isOtherPlanType) {
      const otherPlanTypePlan = cartPlans[cartPlanType as keyof typeof cartPlans];
      const newData: ICartPlan = {
        planId: otherPlanTypePlan?.plan.id,
        cartPlanServices: getAddedCartPlanServicesDTO(otherPlanTypePlan?.cartPlanServices)
      };

      if (agreedPrice) {
        newData.agreedPrice = Number(otherPlanTypePlan?.plan?.pricing?.listingPriceValue);
      }
      allCartPlans.push(newData);
    }
  });
  return allCartPlans;
};

export const getCartPlanDetails = async (cartID: string, navigate: NavigateFunction): Promise<void> => {
  try {
    const {
      data: { data: cartData, meta }
    } = await getCartPlansByID(cartID);
    if (meta?.statusCode === StatusCode.SUCCESS) {
      return cartData;
    } else if (meta?.statusCode === StatusCode.NOT_FOUND) {
      navigate("/life");
    }
  } catch (err) {
    console.warn(err);
  }
};
const getQuoteDetail = async (quotationID: string, navigate: NavigateFunction, dispatch: Function) => {
  try {
    const {
      data: { data, meta }
    } = await getQuoteDetailByQuoteId(quotationID);
    if (meta?.statusCode === StatusCode.SUCCESS) {
      dispatch(AddLeadDetails(data));
      return data;
    } else if (meta?.statusCode === StatusCode.NOT_FOUND) {
      navigate("/life");
    }
  } catch (err) {
    console.warn(err);
  }
};
export const updateCart = async ({
  quotationID,
  dispatch,
  navigate
}: {
  isCustomer: boolean;
  quotationID: string;
  dispatch: Function;
  navigate: NavigateFunction;
}): Promise<void> => {
  const quoteDetail = await getQuoteDetail(quotationID, navigate, dispatch);
  if (quoteDetail?.cartId) {
    const cartDetails = await getCartPlanDetails(quoteDetail.cartId, navigate);
    updateLocalCart(cartDetails, dispatch);
  }
};
export const getSelectedServicesData = (selectedServiceIDs: string[], plan: ILifePlanListingCard): ILifePlanCardService[] => {
  if (!selectedServiceIDs?.length) return [];
  // getting data from IDs to save in store to show the user complete details
  const selectedServicesData = plan?.services?.filter(service => {
    return selectedServiceIDs?.includes(String(service.id));
  });
  return selectedServicesData || [];
};
export const createCartInDB = async ({
  quotationID,
  rateModeType,
  planID,
  planType,
  allPlansServices,
  cartPlans,
  agreedPrice,
  servicesWithPrice
}: ILifeCreateCartPlan): Promise<{ id: number } | undefined> => {
  try {
    const cartPlanServiceDTO = getCartService(planID, allPlansServices, servicesWithPrice);
    const currentPlanTypePlan: ICartPlan = {
      planId: planID,
      cartPlanServices: cartPlanServiceDTO
    };
    if (agreedPrice) {
      currentPlanTypePlan.agreedPrice = Number(agreedPrice);
    }
    const allCartPlans = getAllCartPlans(planID, cartPlanServiceDTO, cartPlans as ICartPlanWithType, planType, allPlansServices, agreedPrice);
    const body = {
      quotationId: quotationID,
      currencyCode: CurrenyCode.PKR,
      rateModeType,
      cartPlans: allCartPlans
    };
    const {
      data: { data: cartData }
    } = await createCart(body);
    return cartData;
  } catch (err) {
    console.error(err);
  }
};

export const updateLocalCart = (cartData: IFetchedCart, dispatch: Function): void => {
  if (!cartData?.cartPlans) {
    dispatch(clearCartPlans());
    return;
  }
  const { cartPlans, id, totalContribution, currentPayable, rateModeType: cartRateModeType } = cartData;
  cartPlans?.map(({ planType, services, takafulProvider, id: planId, relations, pricing, name }) => {
    const plan = {
      takafulProvider,
      pricing: pricing,
      planName: name,
      id: planId
    };
    const memberDetails = {} as { [key: string]: boolean | unknown[] };
    relations?.map(item => {
      if (item?.key === Members.CHILD) {
        memberDetails[item?.key as keyof typeof memberDetails] = new Array(item.allowedCount);
      }
      memberDetails[item?.key as keyof typeof memberDetails] = true;
    });
    if (planType?.key !== PlanTypes.PARENT) {
      memberDetails[Members.SELF] = true;
    }
    const planTypeKey = planType.key;
    const newCartPlan = {
      cartID: id,
      cartPlanServices: services,
      plan,
      memberDetails: memberDetails ?? { [Members.SELF]: true }
    };
    const localCartDetails = {
      planType: planTypeKey,
      totalContribution: totalContribution,
      currentPayable: currentPayable,
      planDetails: newCartPlan,
      rateModeType: cartRateModeType?.key || ""
    };

    dispatch(AddPlanInCart(localCartDetails));
  });
};
export const resetCart = async ({
  cartPlans,
  dispatch
}: {
  cartPlans: {
    [key: string]: {
      plan: ILifePlanListingCard;
      cartPlanServices: ILifePlanCardService[];
      cartID: number;
      memberDetails: {};
    };
  };
  dispatch: Function;
}): Promise<void> => {
  try {
    const cartItemDetails = Object.values(cartPlans);
    const currentCartID = String(cartItemDetails[0]?.cartID);
    const cartPlanIDs = cartItemDetails.map(item => item.plan.id);
    const {
      data: { data: cartResponse }
    } = await removeCart({ planIds: cartPlanIDs as number[], cartId: currentCartID });
    if (cartResponse?.success) {
      dispatch(ResetLifeCart());
    }
    return cartResponse;
  } catch (err) {
    console.error(err);
  }
};
