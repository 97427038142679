import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function WhyChooseUs(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="landing-page-wrapper">
      <section className="why-choose-us-section">
        <Container>
          <h1 className="wcus-title">
            What Does Group Health
            <span className="text-primary"> Takaful Cover?</span>
            {/* {"?"} */}
          </h1>
          <div className="row gy-4">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="wcus-item">
                <img
                  src="/assets/img/hospitalStayHealth.svg"
                  alt="cashless-repairs"
                />
                <h2 className="wcus-label">Hospital Stay</h2>
                <p className="wcus-desc">Covers hospital expenses, including room, surgery, and medication</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="wcus-item">
                <img
                  src="/assets/img/daycareHealth.png"
                  alt="better-rates"
                />
                <h2 className="wcus-label">Daycare Procedures</h2>
                <p className="wcus-desc">Covers procedures that don’t require an overnight hospital stay, such as minor surgeries.</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="wcus-item">
                <img
                  src="/assets/img/hospitalExpenseHealth.svg"
                  alt="assistance"
                />
                <h2 className="wcus-label">Pre and Post Hospitalization Expenses </h2>
                <p className="wcus-desc">Covers Consultation, Medicines, lab tests leading to admission to the hospital and after discharge.</p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}
