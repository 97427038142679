import React from 'react'
import { Container } from "react-bootstrap";

const AboutTakaful = () => {
  return (
    <div>
      <Container className="smeBottomBox">
        <div className="row">
          <div className='col-md-6'>
            <p className="aboutPlatform me-1 me-xxl-5 px-2 py-3 py-md-5 mb-0">
              Takaful Bazaar <span> is one of Pakistan's leading Shariah-Compliant</span>  digital insurance platform
            </p>
          </div>
          <div className='col-md-6 align-content-center'>
            <div className="row py-3 py-md-5 text-center">
              <div className="col-6 borderRight">
                <h5 className="pricingSME">300+</h5>
                <h6 className="SMEcoverages">Corporates Covered</h6>
              </div>
              <div className="col-6">
                <h5 className="pricingSME">400,000+</h5>
                <h6 className="SMEcoverages">Lives Covered</h6>  
              </div>
            </div>
          </div>
        </div>
    </Container>
    </div>
  )
}

export default AboutTakaful