import React, { ReactElement } from "react";

export const BacPolicy = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.257"
      height="20"
      viewBox="0 0 19.257 20"
    >
      <g
        id="Group_37930"
        data-name="Group 37930"
        transform="translate(230.066 -49.144)"
      >
        <path
          id="Path_38440"
          data-name="Path 38440"
          d="M-220.739,144.1a.31.31,0,0,0-.31-.31H-228.4a.923.923,0,0,1-1.055-1.063q0-4.946,0-9.891a.307.307,0,0,0-.307-.307h0a.307.307,0,0,0-.307.307q0,5,0,10a1.491,1.491,0,0,0,1.567,1.57q3.724,0,7.449,0a.31.31,0,0,0,.31-.31Z"
          transform="translate(0 -75.269)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38441"
          data-name="Path 38441"
          d="M-211.4,54.391q-.723-.3-1.447-.6a.838.838,0,0,0-1.219.5q-.3.712-.6,1.425l-.043-.009c0-.736.007-1.473-.007-2.209a.59.59,0,0,0-.146-.372q-1.9-1.92-3.814-3.818a.61.61,0,0,0-.371-.153q-4.732-.012-9.465,0a1.488,1.488,0,0,0-1.549,1.567q0,2.8,0,5.6a.307.307,0,0,0,.307.307h0a.307.307,0,0,0,.307-.307V50.824c0-.726.334-1.063,1.055-1.063h9.125c0,.575,0,1.118,0,1.662,0,.4,0,.792,0,1.188a1.068,1.068,0,0,0,1.1,1.093c.866,0,1.732,0,2.6,0h.242v.234c0,1.055,0,2.109,0,3.163a1.025,1.025,0,0,1-.078.387c-.91,2.191-1.827,4.379-2.734,6.572a1.331,1.331,0,0,0-.1.563c.041.979.1,1.956.153,2.935a.344.344,0,0,0,.2.342.339.339,0,0,0,.382-.1q.986-.877,1.973-1.751c.058-.052.118-.1.214-.185,0,.449,0,.848,0,1.247,0,.175,0,.349,0,.524a.9.9,0,0,1-.9.891c-.054,0-.107,0-.161,0h-3.513a.31.31,0,0,0-.31.31h0a.31.31,0,0,0,.31.31l3.633,0a1.491,1.491,0,0,0,1.559-1.578c0-.867,0-1.734,0-2.6a1.287,1.287,0,0,1,.1-.479q1.845-4.437,3.7-8.869A.841.841,0,0,0-211.4,54.391ZM-216,53.09q-1.035,0-2.071,0c-.408,0-.581-.169-.582-.569,0-.7,0-1.407,0-2.111a1.122,1.122,0,0,1,.009-.119l2.807,2.79C-215.883,53.084-215.94,53.09-216,53.09Zm1.937,2.781.721.3-3.486,8.345-.722-.3Zm-3.445,11.084-.115-2.089,1.682.7Zm1.974-1.9-.722-.3,3.487-8.35.721.3Zm4.083-9.786c-.112.285-.232.567-.356.868l-2.018-.842c.126-.3.24-.579.359-.855a.2.2,0,0,1,.3-.113q.789.327,1.576.657C-211.459,55.041-211.393,55.134-211.448,55.274Z"
          transform="translate(-0.002 0)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38442"
          data-name="Path 38442"
          d="M-199.266,67.669h-2.4a.316.316,0,0,0-.316.317h0a.317.317,0,0,0,.316.317h2.208v1.67h-6.189V68.3h2.453a.317.317,0,0,0,.317-.317h0a.316.316,0,0,0-.317-.317h-2.656c-.345,0-.432.087-.433.427q0,1.027,0,2.054c0,.375.085.459.466.459h3.262q1.651,0,3.3,0c.327,0,.421-.1.422-.421q0-1.047,0-2.094C-198.832,67.754-198.92,67.669-199.266,67.669Z"
          transform="translate(-21.465 -16.721)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38443"
          data-name="Path 38443"
          d="M-210.842,159.383c-.476,0-.952,0-1.428,0-.3,0-.4-.107-.4-.4q0-1.237,0-2.475c0-.282.107-.392.388-.393q1.448,0,2.9,0c.295,0,.4.1.4.4q0,1.237,0,2.475c0,.288-.1.39-.388.392C-209.863,159.385-210.352,159.383-210.842,159.383Zm-1.2-.635h2.423v-2h-2.423Z"
          transform="translate(-15.703 -96.551)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38444"
          data-name="Path 38444"
          d="M-209.378,113.177c-.476,0-.952,0-1.428,0s-.979,0-1.469,0c-.284,0-.388.107-.389.389,0,.09,0,.18,0,.27q.317,0,.633.017v-.04h2.414v2h-2.414v-1.5q-.317-.011-.633-.017,0,.869,0,1.739c0,.3.1.4.4.4q1.448,0,2.9,0c.282,0,.388-.108.389-.393q0-1.237,0-2.475C-208.981,113.276-209.079,113.178-209.378,113.177Z"
          transform="translate(-15.707 -57.797)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38445"
          data-name="Path 38445"
          d="M-208.985,199.487c0-.352-.156-.43-.61-.385a2.016,2.016,0,0,1-.382-.01.323.323,0,0,0-.354.322h0a.323.323,0,0,0,.323.323h.394v1.993h-2.422v-1.993h.71a.323.323,0,0,0,.323-.323h0a.323.323,0,0,0-.323-.323h-.941a.4.4,0,0,0-.4.4q0,1.237,0,2.475c0,.285.1.389.386.391.483,0,.966,0,1.449,0s.966,0,1.448,0a.4.4,0,0,0,.4-.4Q-208.981,200.722-208.985,199.487Z"
          transform="translate(-15.703 -135.347)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38446"
          data-name="Path 38446"
          d="M-163.413,119.9q-1.711,0-3.421,0c-.086,0-.2.011-.251-.036-.086-.075-.189-.2-.183-.294s.126-.2.217-.271c.048-.038.142-.023.215-.023h6.883c.073,0,.167-.015.215.023a.606.606,0,0,1,.223.271.252.252,0,0,1-.2.3.835.835,0,0,1-.238.026Q-161.682,119.9-163.413,119.9Z"
          transform="translate(-56.683 -63.297)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38447"
          data-name="Path 38447"
          d="M-164.258,162.237q1.349,0,2.7,0c.086,0,.2-.011.251.036a.477.477,0,0,1,.179.3c0,.09-.11.2-.2.262a.48.48,0,0,1-.255.03H-167c-.28,0-.43-.113-.429-.315s.152-.311.431-.311Z"
          transform="translate(-56.54 -102.082)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38448"
          data-name="Path 38448"
          d="M-164.46,134.9q-1.258,0-2.517,0a.722.722,0,0,1-.218-.022.282.282,0,0,1-.208-.308.279.279,0,0,1,.247-.277,1.255,1.255,0,0,1,.2-.012h5.034c.243.008.387.13.379.32s-.147.3-.38.3Q-163.192,134.9-164.46,134.9Z"
          transform="translate(-56.56 -76.849)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38449"
          data-name="Path 38449"
          d="M-164.985,205.854h-1.89a1.385,1.385,0,0,1-.22-.012.254.254,0,0,1-.239-.275.653.653,0,0,1,.185-.3c.03-.035.113-.032.172-.032q2.021,0,4.042,0c.065,0,.155,0,.189.043.072.085.169.2.155.293s-.133.195-.227.264c-.05.037-.143.018-.216.018Z"
          transform="translate(-56.622 -140.893)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38450"
          data-name="Path 38450"
          d="M-165.3,177.259h1.589c.054,0,.108,0,.161,0a.306.306,0,0,1,.305.309.3.3,0,0,1-.31.306c-.295.01-.59,0-.885,0h-2.514a.858.858,0,0,1-.2-.012.29.29,0,0,1-.244-.3.286.286,0,0,1,.268-.3,1.43,1.43,0,0,1,.2-.008Z"
          transform="translate(-56.566 -115.641)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38451"
          data-name="Path 38451"
          d="M-165.871,220.212c.382,0,.764,0,1.146,0,.247,0,.386.118.384.314s-.139.308-.389.309q-1.156,0-2.311,0c-.242,0-.388-.124-.386-.314s.146-.307.391-.308C-166.648,220.21-166.26,220.212-165.871,220.212Z"
          transform="translate(-56.539 -154.411)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38452"
          data-name="Path 38452"
          d="M-201.187,167.065c.123-.133.241-.27.37-.4a.3.3,0,0,1,.447-.015.3.3,0,0,1-.009.446q-.3.313-.61.609a.309.309,0,0,1-.463-.007c-.074-.067-.145-.139-.213-.212a.309.309,0,0,1-.043-.448.311.311,0,0,1,.432-.034Z"
          transform="translate(-25.526 -105.987)"
          fill="#5a5a5a"
        />
        <path
          id="Path_38453"
          data-name="Path 38453"
          d="M-201.186,123c.119-.13.232-.263.355-.384a.3.3,0,0,1,.46-.028.3.3,0,0,1-.025.461q-.285.3-.581.581a.308.308,0,0,1-.49-.007c-.053-.05-.1-.1-.156-.156-.186-.188-.213-.341-.086-.489S-201.442,122.834-201.186,123Z"
          transform="translate(-25.525 -66.205)"
          fill="#5a5a5a"
        />
      </g>
    </svg>
  );
};
