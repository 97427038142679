import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Help = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M475.433 1023.847c-17.558-2.847-35.59-4.745-53.148-8.067-70.706-11.863-138.090-38.912-196.933-79.722-6.169-4.745-14.236-6.169-21.829-3.322-38.912 10.915-78.299 20.879-117.211 31.319-10.44 4.271-22.303 0-26.574-10.44-2.373-5.22-2.373-10.915 0-16.134 10.915-40.81 21.829-81.621 32.269-122.431 0.949-4.271 0-8.542-1.898-12.338-126.702-184.122-119.109-428.983 18.507-605.037 73.079-95.857 178.427-161.818 296.587-185.545 136.192-30.371 278.555-2.373 392.918 76.876 9.965 4.745 14.236 17.083 9.491 27.049s-17.083 14.236-27.049 9.491c-1.423-0.949-3.322-1.898-4.271-2.847-55.046-37.489-117.686-62.639-183.172-74.028-256.726-44.132-500.639 128.6-544.771 385.326-20.879 121.956 7.118 247.71 77.824 349.261 8.542 10.915 11.389 25.626 6.643 38.437-9.491 30.845-16.609 62.165-25.151 93.959 2.373 0 5.22-0.475 7.593-0.949 31.319-8.542 63.114-16.609 94.433-25.151 9.491-2.847 19.456-0.949 27.049 4.745 55.046 40.81 119.109 68.334 186.968 79.722 163.241 32.744 331.703-24.676 441.321-150.428 59.792-65.486 98.705-147.582 111.991-235.372 19.456-114.364-4.745-232.050-67.384-329.33-2.847-4.271-5.695-8.542-8.067-12.338-7.118-8.542-5.22-21.354 3.322-28.472s21.354-5.22 28.472 3.322c0.475 0.949 1.423 1.898 1.898 2.847 27.998 40.81 49.352 84.942 64.538 131.922 11.863 36.539 19.456 74.028 22.303 111.991 0.475 3.322 0.949 6.643 1.898 9.491v67.859c-2.373 16.609-3.796 32.744-6.643 49.352-36.539 218.762-209.271 389.122-428.509 422.34-13.288 1.898-26.574 3.796-40.335 5.695l-73.079 0.949z"></path>
      <path d="M508.176 184.862c100.128 1.423 184.122 75.452 198.832 174.156 9.965 81.621-30.371 161.343-102.5 200.73-16.134 6.643-28.472 20.879-32.269 37.963-1.423 6.643-0.949 13.288-2.373 19.931-6.169 34.167-38.912 56.945-72.604 50.775-30.371-5.695-52.2-32.269-51.725-63.114-0.475-63.114 34.167-121.482 90.162-150.428 20.879-9.965 37.014-27.998 44.132-50.301 9.491-38.437-12.338-77.824-49.827-90.162-36.065-11.389-74.977 6.643-90.162 41.285-4.271 10.915-9.965 20.879-17.558 29.896-24.201 24.676-63.588 25.151-88.264 0.949-17.083-16.609-23.252-40.81-16.134-63.588 22.303-72.129 83.994-124.804 158.971-136.192 3.796-0.949 8.067-1.423 11.863-1.898 6.643 0 12.813 0 19.456 0zM667.146 384.643c0.475-6.169 0.475-12.813 0-18.981-11.863-88.739-93.484-150.903-182.223-139.040-4.745 0.475-9.016 1.423-13.761 2.373-53.148 11.863-96.332 50.775-113.415 102.5-5.695 10.915-0.949 24.201 9.965 29.896 1.898 0.949 3.796 1.423 5.695 1.898 12.813 2.847 21.829-3.322 27.998-18.507 15.66-44.607 58.368-74.502 105.823-74.502 64.063 0 115.788 52.2 115.788 115.788 0 7.118-0.475 14.711-1.898 21.829-8.067 36.065-32.269 65.961-65.486 82.095-43.183 22.303-69.757 66.91-69.283 115.788 0 12.338 9.491 22.778 21.829 22.778 11.389 0 21.354-8.067 22.778-19.456 0.949-6.643 0.949-13.288 2.373-19.931 6.169-27.998 25.151-51.725 50.775-64.063 51.725-27.049 84.468-81.621 83.044-140.463v0z"></path>
      <path d="M508.176 840.674c-34.642 0-63.114-27.998-63.588-62.639 0-34.642 27.998-63.114 62.639-63.588s63.114 27.998 63.588 62.639c0.475 35.116-27.998 63.588-62.639 63.588zM507.702 800.339c12.338 0.475 22.778-9.491 22.778-21.354 0-0.475 0-0.475 0-0.949 0.475-12.338-9.491-22.303-21.354-22.303 0 0 0 0 0 0-12.338-0.949-22.778 8.542-23.727 20.879s8.542 22.778 20.879 23.727c0.475 0 0.949 0 1.423 0z"></path>
      <path d="M858.86 184.862c-10.44 0-19.456-8.067-19.456-18.981 0-0.475 0-0.949 0-1.423 0-10.915 8.542-19.456 19.456-19.456s20.406 9.016 20.406 19.931c0 10.915-8.542 19.931-19.456 19.931-0.475 0-0.949 0-0.949 0z"></path>
    </svg>
  );
};
