import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const ArrowRight = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M238.815 895.82l368.514-383.392-367.37-383.392c-14.878-14.878-21.745-33.189-21.745-53.789s8.011-38.911 21.745-53.789c28.611-28.611 78.967-28.611 107.579 0l436.037 437.181c3.433 3.433 6.867 6.867 9.156 11.445l3.433 5.722c1.144 2.289 2.289 4.578 4.578 8.011 1.144 2.289 2.289 5.722 2.289 8.011l1.144 5.722c2.289 10.3 2.289 19.456 0 29.756l-1.144 5.722c-1.144 2.289-1.144 5.722-3.433 9.156-1.144 2.289-2.289 4.578-3.433 6.867l-2.289 4.578c-2.289 4.578-5.722 8.011-9.156 11.445l-437.181 437.181c-14.878 14.878-34.334 21.745-53.789 21.745s-38.911-6.867-53.789-21.745c-14.878-14.878-22.889-33.189-21.745-53.789-1.144-19.456 6.867-38.911 20.6-52.645z"></path>
    </svg>
  );
};
