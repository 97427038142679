import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { ISignup } from "../../utils/interface";
import { getSignupOtp, getUserInfo, login, signUp } from "../../services";
import { AddUserDetails, Authenticate } from "../../../../redux/features/auth";
import { getCartPlans } from "../../../../redux/features/health/cart";
import { Environment, Routes } from "../../../../shared/constant";
import { Config } from "../../../../config";
import { isUserCustomer } from "../../../../shared/helper";
import { resetCart } from "../../../../modules/health/helpers/cart";
import SignupComponent from "../../component/signup";
import { useLocation, useNavigate } from "react-router-dom";
interface IProps {
  onCloseModal: Function;
}

export default function Signup({ onCloseModal }: IProps): ReactElement {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const cartPlans = useSelector(getCartPlans);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<ISignup>({ mode: "onChange" });

  const onClickSendOtp = async () => {
    const { email, contactNumber } = form.getValues();
    if (email && contactNumber) {
      const data = { username: contactNumber, email };
      try {
        setLoading(true);
        form.setError("contactNumber", {});
        const res = await getSignupOtp(data);
        if (res) {
          setLoading(false);
        }
      } catch (error) {
        console.warn(error);
      } finally {
        setLoading(false);
      }
    } else {
      form.trigger("contactNumber");
      form.trigger("email");
    }
  };
  const onSubmit = async (payload: ISignup) => {
    const isLandingPage = pathname === Routes.LANDING_PAGE;
    try {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { termAndCondition, ...rest } = payload;
      const {
        data: { data }
      } = await signUp(rest);
      if (data) {
        const loginPaylod = {
          username: payload.contactNumber,
          otpCode: data.data
        };
        const { data: loginRes } = await login(loginPaylod);
        if (loginRes.success) {
          dispatch(Authenticate(loginRes.data));
          Config.environment === Environment.Local && Cookies.set("authorization", loginRes.data.token);
          const { data: userDetails } = await getUserInfo();
          const isCustomer = isUserCustomer(userDetails.data.userType?.key);

          if (!isLandingPage) navigate(pathname, { replace: true });
          dispatch(AddUserDetails(userDetails.data));

          if (!isCustomer) await resetCart({ cartPlans, dispatch });

          onCloseModal();
        }
        onCloseModal();
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <SignupComponent
        onSubmit={onSubmit}
        onClickSendOtp={onClickSendOtp}
        loading={loading}
        form={form}
      />
    </div>
  );
}
