import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Link = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M160.17 474.699c33.003-33.003 64.165-68.123 99.377-98.548 91.099-74.848 217.694-82.41 315.353-23.421l1.604 1.051c16.486 10.33 34.058 23.895 50.488 39.367l0.374 0.356c0.795 0.692 1.693 1.53 2.567 2.404 21.063 21.063 21.1 55.192 0.109 76.3l-0.006 0.006c-0.307 0.326-0.675 0.704-1.050 1.078-21.659 21.659-56.775 21.659-78.433 0-0.216-0.216-0.429-0.432-0.639-0.65l0.032 0.033c-33.818-33.786-78.738-49.684-123.005-47.695l-0.169 0.009c-38.839 0.603-77.552 15.644-107.354 45.123l-0.032 0.032c-54.821 53.624-108.952 107.755-162.437 162.529-62.362 64.996-61.546 168.045 2.325 231.917 56.898 56.898 144.887 63.757 209.281 20.577l0.865-0.621c13.936-10.594 29.066-23.816 43.464-38.215 3.314-3.314 6.566-6.667 9.755-10.057l-0.47 0.494c16.892-16.156 33.049-33.049 49.574-49.574 0.289-0.305 0.634-0.659 0.985-1.010 20.256-20.256 52.351-21.456 74.011-3.601l0.17 0.146c1.766 1.318 3.674 2.969 5.485 4.779 19.919 19.919 20.688 51.738 2.304 72.575l-0.102 0.113c-12.113 14.472-26.905 30.61-42.526 46.231-29.182 29.182-60.162 55.467-92.585 78.857l3.467-2.65c-108.196 66.281-249.368 52.763-341.621-39.49-98.086-98.086-107.165-251.474-27.237-359.797l0.946-1.214c18.708-22.562 41.596-47.648 65.841-71.893 8.318-8.318 16.733-16.475 25.245-24.473l-1.237 1.177c4.603-4.603 9.206-9.206 13.808-13.808z"></path>
      <path d="M863.082 550.417c-32.681 32.681-63.52 67.571-98.503 97.674-90.953 75.34-217.73 83.247-315.622 24.433l-1.612-1.050c-16.151-10.111-33.373-23.391-49.477-38.539l-0.372-0.355c-0.928-0.794-1.969-1.759-2.981-2.77-21.194-21.194-21.649-55.275-1.364-77.021l0.065-0.069c0.055-0.055 0.121-0.121 0.186-0.187 22.295-22.295 58.441-22.295 80.735 0 0.162 0.162 0.323 0.325 0.483 0.489l-0.024-0.024c38.863 38.505 92.302 53.343 142.401 44.542l0.612-0.124c32.027-4.658 63.367-19.546 88.403-44.583 0.104-0.104 0.208-0.208 0.311-0.312l-0.016 0.016c53.255-52.611 106.42-105.315 158.709-158.709 63.618-64.899 63.223-168.985-1.127-233.336-56.577-56.577-143.869-63.716-208.199-21.42l-0.889 0.627c-12.352 9.022-25.63 20.396-38.178 32.946-2.256 2.256-4.474 4.537-6.656 6.839l0.323-0.337c-19.976 19.148-39.125 39.125-58.734 58.734-0.22 0.23-0.484 0.498-0.751 0.765-20.699 20.699-53.777 21.476-75.406 2.331l-0.114-0.104c-1.197-0.975-2.521-2.174-3.797-3.449-20.343-20.343-20.882-52.988-1.618-73.984l0.076-0.081c9.591-10.449 21.104-22.449 32.918-34.262 29.101-29.101 59.336-56.375 90.566-81.82l-3.853 3.265c93.447-66.031 216.896-67.195 310.155-4.472l1.417 1.020c13.797 9.049 28.284 20.817 41.729 34.262 87.11 87.11 103.837 217.948 50.181 321.853l-1.002 1.762c-12.696 23.146-30.064 47.15-50.768 69.16l-0.371 0.389c-24.027 27.157-51 51.737-76.684 77.422z"></path>
    </svg>
  );
};
