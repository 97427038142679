// ScheduleCall.tsx
import React, { ReactElement, useState } from "react";
import Sidebar from "../sidebar";

export default function ScheduleCall(): ReactElement {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toggle the sidebar open/close state
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="scheduleCall">
        <button
          className="scheduleCallButton"
          onClick={toggleSidebar}
        >
          <img
            className="scheduleCallbtnImg"
            src="/assets/img/scheduleaCallIcon.svg"
            alt="scheduleaCallIcon"
          />
          <p className="schedulepara-btn mb-0">Schedule a Call</p>
        </button>
      </div>

      {/* Sidebar component */}
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
    </>
  );
}
