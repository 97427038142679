import React, { ReactElement } from "react";

export const Plus = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15 17"
    >
      <g
        id="Group_44401"
        data-name="Group 44401"
        transform="translate(-1191 -252.875)"
      >
        <g
          id="Ellipse_12679"
          data-name="Ellipse 12679"
          transform="translate(1191 253.875)"
          fill="#fff"
          stroke="#e91431"
          strokeWidth="1"
        >
          <circle
            cx="7.5"
            cy="7.5"
            r="7.5"
            stroke="none"
          />
          <circle
            cx="7.5"
            cy="7.5"
            r="7"
            fill="none"
          />
        </g>
        <text
          id="_"
          data-name="+"
          transform="translate(1195 265.875)"
          fill="#e91431"
          fontSize="12"
          fontFamily="Poppins-Regular, Poppins"
          letterSpacing="-0.02em"
        >
          <tspan
            x="0"
            y="0"
          >
            +
          </tspan>
        </text>
      </g>
    </svg>
  );
};
