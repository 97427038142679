import { useForm } from "react-hook-form";
import React, { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Environment, Routes } from "../../../../shared/constant";
import { Config } from "../../../../config";
import { resetCart } from "../../../health/helpers/cart";
import { getCartPlans } from "../../../../redux/features/health/cart";
import { isUserCustomer } from "../../../../shared/helper";
import { Authenticate, AddUserDetails } from "../../../../redux/features/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { getLoginOtp, getUserInfo, login } from "../../services";
import { ILogin } from "../../utils/interface";
import { phoneAndEmailPattern } from "../../../../shared/regex";
import LoginComponent from "../../component/login";
interface IProps {
  onCloseModal: Function;
}

export default function Login({ onCloseModal }: IProps): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cartPlans = useSelector(getCartPlans);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const form = useForm<ILogin>();

  const [loading, setLoading] = useState<boolean>(false);

  const onClickSendOtp = async () => {
    const emailAndContactNumber = form.getValues("username");
    if (emailAndContactNumber && emailAndContactNumber.match(phoneAndEmailPattern)) {
      const data = { username: emailAndContactNumber };
      try {
        form.setError("username", {});
        setLoading(true);
        const res = await getLoginOtp(data);
        if (res) {
          setLoading(false);
        }
      } catch (error) {
        console.warn(error);
      } finally {
        setLoading(false);
      }
    } else {
      form.setError("username", {
        type: "manual",
        message: t("health_landing_user_details_contact_number_valid_or_required_error")
      });
    }
  };

  const onSubmit = async (payload: ILogin) => {
    try {
      const isLandingPage = pathname === Routes.LANDING_PAGE;
      setLoading(true);
      const { data: res } = await login(payload);
      if (res.success) {
        dispatch(Authenticate(res.data));
        Config.environment === Environment.Local && Cookies.set("authorization", res.data.token);
        const { data: userDetails } = await getUserInfo();
        const isCustomer = isUserCustomer(userDetails.data.userType?.key);

        if (!isLandingPage) navigate(`${pathname}${search}`, { replace: true });
        dispatch(AddUserDetails(userDetails.data));

        if (!isCustomer) await resetCart({ cartPlans, dispatch });
        onCloseModal();
      } else {
        const { meta } = res;
        form.setError("otpCode", {
          type: "manual",
          message: meta.message
        });
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <LoginComponent
        onSubmit={onSubmit}
        onClickSendOtp={onClickSendOtp}
        form={form}
        loading={loading}
      />
    </div>
  );
}
