import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Cross = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M953.891 836.965l-305.388-328.881 305.388-328.881c39.152-39.152 39.152-109.626 0-148.779-31.322-39.152-93.965-39.152-133.119-7.829 0 0-7.829 7.829-7.829 7.829l-305.388 328.881-297.557-321.048c-39.152-39.152-93.965-46.983-133.119-7.829 0 0-7.829 7.829-7.829 7.829-39.152 39.152-39.152 101.797 0 148.779l305.388 328.881-305.388 328.881c-39.152 39.152-39.152 109.626 0 148.779 31.322 39.152 93.965 39.152 133.119 7.829 0 0 7.829-7.829 7.829-7.829l305.388-328.881 305.388 328.881c31.322 39.152 93.965 39.152 133.119 7.829 0 0 7.829-7.829 7.829-7.829 31.322-46.983 31.322-117.457-7.829-156.609z"></path>
    </svg>
  );
};
