import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Logout = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M183.665 997.754c20.886 18.688 47.634 27.848 75.848 26.016h367.518c13.558-1.465 25.282-12.825 26.749-27.115s-7.328-27.848-21.252-31.878c-4.764-1.099-9.527-1.832-15.023-1.465h-359.457c-2.931 0.366-6.596 0.366-10.259 0-11.726-1.465-22.351-7.328-29.68-16.855s-10.626-20.886-9.16-32.611v-803.922c-0.366-3.297-0.366-7.328 0-11.359 1.832-11.726 7.695-22.351 17.221-29.313s21.252-10.259 32.977-8.427h361.288c2.931 0.366 4.764 0.366 6.962 0 8.061-1.099 15.389-5.13 20.52-11.359s7.328-14.29 6.229-22.351c-1.099-8.061-4.764-15.389-11.359-20.153-6.596-5.13-14.657-7.695-22.351-6.596h-360.922c-2.565 0-5.13-0.366-7.328-0.366-2.565 0-4.764 0-7.328 0.366-27.115 1.832-52.764 14.657-71.085 35.543s-27.482 48.001-25.649 76.215v799.891c-0.366 4.764-0.366 9.16 0 13.191 1.832 28.214 14.29 53.863 35.543 72.551z"></path>
      <path d="M720.101 680.436c48.734-48.368 97.101-96.734 145.102-145.102 1.465-1.465 2.931-2.565 4.031-4.031 10.626-12.825 8.794-31.878-4.031-42.138-48.368-48.368-96.368-96.368-144.369-144.369-1.465-1.832-2.931-3.297-4.764-4.397-12.825-10.626-32.244-8.427-42.505 4.397-5.13 6.229-7.695 14.29-6.596 22.351 0.733 8.061 4.764 15.389 10.992 20.52l74.383 74.383c1.465 1.099 2.931 2.931 4.764 5.13l13.924 15.389h-368.25c-13.191-0.733-24.916 6.596-29.68 18.32-1.832 4.397-2.565 9.16-2.565 13.924 0.366 8.061 4.031 15.389 10.259 20.886 5.863 5.13 13.924 7.695 21.985 7.328h368.25l-12.459 13.191c-3.664 3.664-5.863 6.229-8.794 8.794l-22.351 22.351c-16.855 16.855-33.345 33.345-50.199 50.199-1.465 1.465-2.565 2.198-3.664 3.297-10.992 12.459-9.893 31.878 2.565 42.871 13.558 10.259 32.977 9.16 43.97-3.297z"></path>
    </svg>
  );
};
