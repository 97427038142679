import { Config } from "../../config";
import { showLoader } from "../../redux/features/loader";
import { store } from "../../redux/store";
import { Environment, Status } from "../../shared/constant";
import axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_WEBSITE_API_PROXY_URL}${process.env.REACT_APP_WEBSITE_API_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

const Api = setupCache(axiosInstance, { methods: [] });

Api.interceptors.request.use(
  async config => {
    showAppLoader(true);

    config.headers["access-key"] = Config.apiAccessKey;
    if (Config.environment === Environment.Local && typeof document !== "undefined") {
      const token = Cookies.get("authorization");
      if (token?.length) {
        config.headers["Authorization"] = token;
      }
    }
    return config;
  },
  async error => {
    showAppLoader(false);
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  async response => {
    showAppLoader(false);

    const { meta } = response.data;
    if (meta?.statusCode == Status.CREATED || meta?.statusCode == Status.SUCCESS) {
      response.data.success = true;
    }
    return response;
  },
  async error => {
    showAppLoader(false);
    const res = error?.response;
    const NOTIFICATION_DISABLED_ENDPOINTS = ["users/me", "/web/health/shelf-plans"];
    let message = res.data.message || res.data.meta?.message || res.meta?.error;
    res.data = { ...res.data, success: false };
    if (typeof window !== "undefined") {
      if (Array.isArray(res.data.meta?.message)) {
        message = res.data.meta?.message[0];
      }
      if (!NOTIFICATION_DISABLED_ENDPOINTS.some(endpoint => res.request.responseURL.includes(endpoint))) {
        toast.error(message);
        return res;
      }
    }
    return res;
  }
);

const showAppLoader = (isLoading: boolean) => {
  store?.dispatch(showLoader({ isLoading }));
};

export default Api;
