import { Button, Form } from "react-bootstrap";
import { otpPattern, phoneAndEmailPattern } from "../../../../shared/regex";
import React, { MouseEventHandler, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { ILogin } from "../../utils/interface";
interface IProps {
  onSubmit: SubmitHandler<ILogin>;
  onClickSendOtp: MouseEventHandler<HTMLButtonElement>;
  form: UseFormReturn<ILogin>;
  loading: boolean;
}

export default function LoginComponent({ onSubmit, onClickSendOtp, form, loading }: IProps): ReactElement {
  const { t } = useTranslation();
  const [isOTPPatternValid, setIsOTPPatternValid] = useState<boolean>(false);

  return (
    <div>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Form.Group className="floating-input mb-4">
          <Form.Control
            data-testid="contact_number"
            placeholder={""}
            id="contact_number"
            type="text"
            {...form.register("username", {
              required: t("health_landing_user_details_contact_number_required_error"),
              pattern: {
                value: phoneAndEmailPattern,
                message: t("health_landing_user_details_contact_number_valid_error")
              }
            })}
          />
          <div className={"text-primary fs-12px hide-empty mt-1"}>
            {form.formState.errors.username && <span>{form.formState.errors.username.message}</span>}
          </div>
          <Form.Label
            className="auth-placeholder"
            htmlFor="contact_number"
          >
            {t("auth_login_mobile_and_email_label")}
            <span>*</span>
          </Form.Label>
          <Button
            variant="light"
            className="otp-btn"
            onClick={onClickSendOtp}
            disabled={loading}
          >
            {t("auth_login_send_otp_button")}
          </Button>
        </Form.Group>
        <Form.Group className="floating-input">
          <Form.Control
            type="text"
            className={!form.formState.errors.otpCode && isOTPPatternValid ? "is-valid" : ""}
            placeholder=""
            data-testid="opt_code"
            id="opt_code"
            {...form.register("otpCode", {
              required: t("auth_login_otp_required_error"),
              maxLength: {
                value: 4,
                message: t("auth_login_otp_max_length_error")
              },
              minLength: {
                value: 4,
                message: t("auth_login_otp_min_length_error")
              },
              pattern: {
                value: otpPattern,
                message: t("auth_login_otp_format_error")
              }
            })}
            onChange={e => {
              form.setValue("otpCode", e.target.value);
              setIsOTPPatternValid(e.target.value.length === 4);
            }}
          />
          <div className={"text-primary fs-12px hide-empty mt-1"}>
            {form.formState.errors.otpCode && <span>{form.formState.errors.otpCode.message}</span>}
          </div>
          <Form.Label
            className="auth-placeholder"
            htmlFor="opt_code"
          >
            {t("auth_login_enter_otp_label")}
            <span>*</span>
          </Form.Label>
        </Form.Group>
        <Button
          size="lg"
          type={"submit"}
          className="w-100 d-flex mt-4"
          data-testid="login-button"
        >
          {t("auth_login_btn_text")}
        </Button>
      </form>
    </div>
  );
}
