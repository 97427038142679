import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const ArrowLeft = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M785.185 895.82l-368.514-383.392 367.37-383.392c14.878-14.878 21.745-33.189 21.745-53.789s-8.011-38.911-21.745-53.789c-28.611-28.611-78.967-28.611-107.579 0l-436.037 437.181c-3.433 3.433-6.867 6.867-9.156 11.445l-3.433 5.722c-1.144 2.289-2.289 4.578-4.578 8.011-1.144 2.289-2.289 5.722-2.289 8.011l-1.144 5.722c-2.289 10.3-2.289 19.456 0 29.756l1.144 5.722c1.144 2.289 1.144 5.722 3.433 9.156 1.144 2.289 2.289 4.578 3.433 6.867l2.289 4.578c2.289 4.578 5.722 8.011 9.156 11.445l437.181 437.181c14.878 14.878 34.334 21.745 53.789 21.745s38.911-6.867 53.789-21.745c14.878-14.878 22.889-33.189 21.745-53.789 1.144-19.456-6.867-38.911-20.6-52.645z"></path>
    </svg>
  );
};
