import React, { ReactElement } from "react";

export const Edit = (): ReactElement => {
  return (
    <svg
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M752.64 327.68c-20.48-20.48-40.96-40.96-61.44-61.44-10.24-10.24-25.6-10.24-35.84-5.12 0 0 0 0 0 0-15.36 15.36-35.84 30.72-51.2 46.080v0l107.52 107.52c15.36-15.36 30.72-30.72 46.080-46.080 10.24-10.24 10.24-25.6-5.12-40.96 5.12 5.12 5.12 5.12 0 0z"></path>
      <path d="M512 0c-281.6 0-512 230.4-512 512s230.4 512 512 512 512-230.4 512-512-230.4-512-512-512zM512 967.68c0 0 0 0 0 0-256 0-460.8-209.92-455.68-460.8 0-250.88 204.8-455.68 460.8-455.68 0 0 0 0 0 0 250.88 0 455.68 204.8 455.68 455.68 0 256-204.8 460.8-460.8 460.8z"></path>
      <path d="M563.2 353.28c-97.28 97.28-194.56 194.56-291.84 291.84-5.12 5.12-5.12 10.24-10.24 15.36 0 30.72 0 61.44 0 97.28v0c35.84 0 66.56 0 97.28 0 5.12 0 10.24-5.12 15.36-10.24 97.28-97.28 189.44-189.44 286.72-286.72 5.12-5.12 5.12-10.24 10.24-10.24l-107.52-97.28c5.12-5.12 5.12-5.12 0 0z"></path>
    </svg>
  );
};
