import React, { ReactElement } from "react";
import { IconProps } from "../types";

export const Filter = ({ className }: IconProps): ReactElement => {
  return (
    <svg
      className={`svg-icon ${className}`}
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
    >
      <path d="M977.591 152.082h-616.428c-16.071-37.826-52.494-64.073-95.161-65.064l-0.124-0.002c-42.81 0.974-79.256 27.226-95.071 64.374l-0.261 0.692h-124.089c-25.875 1.173-46.409 22.434-46.409 48.493 0 0.697 0.015 1.391 0.044 2.082l-0.003-0.099c-0.026 0.592-0.041 1.286-0.041 1.983 0 26.058 20.534 47.32 46.303 48.49l0.105 0.004h124.089c16.077 37.84 52.522 64.093 95.211 65.064l0.121 0.002c42.792-0.993 79.215-27.24 95.024-64.374l0.261-0.692h616.428c25.875-1.173 46.409-22.434 46.409-48.493 0-0.697-0.015-1.391-0.044-2.082l0.003 0.099c0.026-0.592 0.041-1.286 0.041-1.983 0-26.058-20.534-47.32-46.303-48.49l-0.105-0.004zM166.107 163.414l-0.283 0.803zM162.896 174.982c0 0.614-0.33 1.228-0.425 1.842 0.142-0.567 0.283-1.18 0.425-1.795zM160.771 187.118c0 0.85-0.283 1.7-0.378 2.597 0.142-0.755 0.283-1.7 0.425-2.55zM165.824 240.899l0.283 0.755zM160.393 215.401c0 0.897 0.236 1.7 0.378 2.597-0.095-0.708-0.236-1.7-0.33-2.597zM162.424 228.244c0 0.614 0.283 1.228 0.425 1.842-0.095-0.614-0.236-1.228-0.378-1.842zM265.831 263.752c-31.361-1.423-56.247-27.193-56.247-58.776 0-0.916 0.021-1.829 0.062-2.735l-0.005 0.129c-0.037-0.778-0.058-1.69-0.058-2.606 0-31.583 24.886-57.354 56.119-58.772l0.128-0.005c31.361 1.423 56.247 27.193 56.247 58.776 0 0.916-0.021 1.829-0.062 2.735l0.005-0.129c0.037 0.78 0.058 1.694 0.058 2.613 0 31.533-24.808 57.272-55.972 58.765l-0.134 0.005zM365.555 241.701l0.283-0.755zM368.766 230.133c0-0.614 0.283-1.228 0.425-1.888-0.047 0.614-0.236 1.228-0.378 1.888zM370.891 217.998c0-0.85 0.283-1.7 0.378-2.597-0.095 0.897-0.236 1.888-0.33 2.597zM365.555 163.461l0.283 0.803zM368.766 175.030c0 0.614 0.283 1.228 0.425 1.842-0.047-0.614-0.236-1.228-0.378-1.842zM370.891 187.165c0 0.85 0.283 1.7 0.378 2.597-0.047-0.803-0.236-1.747-0.33-2.597z"></path>
      <path d="M977.591 461.547h-124.089c-16.090-37.82-52.532-64.054-95.212-65.017l-0.12-0.002c-42.781 0.984-79.201 27.212-95.024 64.328l-0.262 0.691h-616.428c-25.875 1.173-46.409 22.434-46.409 48.493 0 0.697 0.015 1.391 0.044 2.082l-0.003-0.099c-0.026 0.592-0.041 1.286-0.041 1.983 0 26.058 20.534 47.32 46.303 48.49l0.105 0.004h616.428c16.084 37.807 52.504 64.035 95.163 65.017l0.123 0.002c42.8-0.965 79.242-27.198 95.071-64.328l0.262-0.691h124.089c25.875-1.173 46.409-22.434 46.409-48.493 0-0.697-0.015-1.391-0.044-2.082l0.003 0.099c0.026-0.592 0.041-1.286 0.041-1.983 0-26.058-20.534-47.32-46.303-48.49l-0.105-0.004zM658.447 472.926l-0.236 0.755zM655.236 484.495c0 0.614-0.283 1.228-0.425 1.842 0.142-0.614 0.283-1.228 0.425-1.842zM653.111 496.63c0 0.85-0.283 1.7-0.378 2.597 0.142-0.897 0.425-1.747 0.425-2.597zM658.163 550.411l0.236 0.755zM652.733 524.913c0 0.897 0.236 1.747 0.378 2.597 0.047-0.85-0.236-1.747-0.33-2.645zM654.764 537.756c0 0.614 0.283 1.228 0.425 1.888-0.095-0.708-0.236-1.322-0.378-1.936zM758.17 573.264c-31.361-1.423-56.247-27.193-56.247-58.776 0-0.916 0.021-1.829 0.062-2.735l-0.005 0.129c-0.037-0.778-0.058-1.69-0.058-2.606 0-31.583 24.886-57.354 56.119-58.772l0.128-0.005c31.361 1.423 56.247 27.193 56.247 58.776 0 0.916-0.021 1.829-0.062 2.735l0.005-0.129c0.034 0.75 0.054 1.629 0.054 2.513 0 31.582-24.884 57.351-56.115 58.771l-0.128 0.005zM857.894 551.214l0.236-0.755zM861.105 539.646c0-0.614 0.33-1.228 0.425-1.888-0.047 0.567-0.236 1.18-0.614 1.795zM863.23 527.51c0-0.85 0.283-1.7 0.378-2.597-0.095 0.85-0.236 1.747-0.33 2.55zM857.894 472.974l0.236 0.755zM861.105 484.542c0 0.614 0.33 1.228 0.425 1.888-0.047-0.708-0.236-1.322-0.614-1.936zM863.23 496.677c0 0.85 0.283 1.7 0.378 2.597-0.047-0.945-0.236-1.795-0.33-2.645z"></path>
      <path d="M977.591 771.012h-450.552c-16.090-37.82-52.532-64.054-95.212-65.017l-0.12-0.002c-42.781 0.984-79.201 27.212-95.024 64.328l-0.262 0.691h-290.011c-25.875 1.173-46.409 22.434-46.409 48.493 0 0.697 0.015 1.391 0.044 2.082l-0.003-0.099c-0.026 0.592-0.041 1.286-0.041 1.983 0 26.058 20.534 47.32 46.303 48.49l0.105 0.004h290.011c16.084 37.807 52.504 64.035 95.163 65.017l0.123 0.002c42.8-0.965 79.242-27.198 95.071-64.328l0.262-0.691h450.552c25.875-1.173 46.409-22.434 46.409-48.493 0-0.697-0.015-1.391-0.044-2.082l0.003 0.099c0.026-0.592 0.041-1.286 0.041-1.983 0-26.058-20.534-47.32-46.303-48.49l-0.105-0.004zM332.030 782.392l-0.283 0.755zM328.819 793.96c0 0.614-0.33 1.228-0.425 1.842 0.095-0.614 0.283-1.228 0.425-1.842zM326.695 806.095c0 0.85-0.283 1.7-0.378 2.597 0.095-0.945 0.283-1.747 0.378-2.597zM331.746 859.829l0.283 0.803zM326.317 834.332c0 0.85 0.236 1.7 0.378 2.597-0.095-0.85-0.283-1.7-0.378-2.597zM328.347 847.174c0 0.614 0.283 1.228 0.425 1.842-0.095-0.614-0.283-1.228-0.425-1.842zM431.754 882.635c-31.361-1.423-56.247-27.193-56.247-58.776 0-0.916 0.021-1.829 0.062-2.735l-0.005 0.129c-0.037-0.778-0.058-1.69-0.058-2.606 0-31.583 24.886-57.354 56.119-58.772l0.128-0.005c31.361 1.423 56.247 27.193 56.247 58.776 0 0.916-0.021 1.829-0.062 2.735l0.005-0.129c0.037 0.778 0.058 1.69 0.058 2.606 0 31.583-24.886 57.354-56.119 58.772l-0.128 0.005zM531.478 860.584l0.283-0.803zM534.688 849.016c0-0.614 0.33-1.228 0.425-1.842 0 0.614-0.283 1.228-0.425 1.842zM536.813 836.928c0-0.85 0.283-1.7 0.378-2.597-0.095 0.897-0.283 1.747-0.378 2.597zM531.478 782.392l0.283 0.755zM534.688 793.96c0 0.614 0.33 1.228 0.425 1.842 0-0.614-0.283-1.228-0.425-1.842zM536.813 806.095c0 0.85 0.283 1.7 0.378 2.597-0.095-0.945-0.283-1.747-0.378-2.597z"></path>
    </svg>
  );
};
