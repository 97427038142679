import { useTranslation } from "react-i18next";
import React, { ReactElement } from "react";
import { Spinner } from "react-bootstrap";

const SpinnerLoader = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={"spinner-wrapper d-flex"}>
      <div className={"d-flex spinner"}>
        <Spinner
          animation="border"
          size={"sm"}
          variant="danger"
        />
        <div>{t("loading")}</div>
      </div>
    </div>
  );
};

export default SpinnerLoader;
